.plan-details-page {
  padding: 0 50px;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 180px);
}
.plan-details-page.trial-banner-visible {
  height: calc(100vh - 350px);
}
.plan-details-first-section,
.plan-details-second-section {
  display: flex;
  gap: 10px;
}
.plan-details-first-section {
  padding-right: 30px;
}
.plan-details-second-section {
  padding-left: 30px;
}
.plan-details-sections {
  display: flex;
  margin: 0 0 60px 0;
  justify-content: space-between;
}
.plan-details-page-info-boxes {
  margin: 30px 0 50px 0;
}
.plan-details-page-info-box {
  flex-basis: 33%;
  max-width: 340px;
}
.plan-details-page-info-box-combo {
  display: flex;
  margin: 30px 0 40px 0;
  gap: 40px;
  flex-wrap: nowrap;
}
.plan-details-page-section-title {
  font-family: 'Gilroy-SemiBold';
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}
.plan-and-billing-contacts-icon path {
  fill: #000;
}
@media screen and (max-width: 950px) {
  .plan-details-page {
    height: calc(100vh - 180px);
    height: calc(100dvh - 180px);
  }
  .plan-details-page.trial-banner-visible {
    height: calc(100vh - 328px);
    height: calc(100dvh - 328px);
  }
  .plan-details-page {
    padding: 0 20px;
  }
  .plan-details-sections {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: 20px;
  }
  .plan-details-second-section {
    flex-direction: column;
    padding-left: 0;
  }
  .plan-details-second-section button {
    width: 100%;
  }
  .plan-details-page-info-box-combo {
    flex-wrap: wrap;
    gap: 15px;
    margin: 15px 0;
  }
  .plan-details-page-info-box {
    flex-basis: 100%;
    max-width: 100%;
  }
  .plan-details-box-title {
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .plan-details-box-info {
    font-size: 16px !important;
  }
}
