.button-component-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 18px;
  background: var(--project-green);
  padding: 20px 5px;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Gilroy-Bold';
  color: #000;
  white-space: nowrap;
}
.button-component-main:disabled {
  background: var(--project-dark-disabled) !important;
  color: var(--bs-white);
  opacity: 1;
  border: 1px solid transparent !important;
}
.button-component-main.fullWidth {
  width: 100%;
}
/* PRIMARY */
.button-component-main.primary {
  background: var(--project-green);
}
.button-component-main.primary:hover {
  background: #1ac673;
}
.button-component-main.primary:active {
  background: #fff;
  border: 1px solid #000;
}
/* SECONDARY */
.button-component-main.secondary {
  background: #23262e;
  color: #fff;
}
.button-component-main.secondary:hover {
  background: #373942;
  color: #fff;
}
/* DARK */
.button-component-main.dark {
  background: #000;
  color: #fff;
}
.button-component-main.dark:hover {
  background: #363942;
}
/* DELETE */
.button-component-main.delete {
  background: #f74151;
  color: #fff;
}
.button-component-main.delete:hover {
  background: #dc3241;
}
/* NEUTRAL */
.button-component-main.neutral {
  background: rgba(2, 13, 28, 0.05);
  color: #000;
}
.button-component-main.neutral:hover {
  background: rgba(2, 13, 28, 0.15);
}
.button-component-main.neutral:disabled {
  background: rgba(35, 38, 46, 0.05) !important;
  color: rgba(35, 38, 46, 0.2) !important;
}
/* CLOSE */
.button-component-main.close {
  background: rgba(35, 38, 46, 0.05);
  color: #000;
}
.button-component-main.close:hover {
  background: rgba(35, 38, 46, 0.1);
}
.button-component-main.sm {
  padding: 10px 30px;
  max-height: 40px;
  font-size: 14px;
}
.button-component-main.md {
  padding: 15px 30px;
  max-height: 59px;
  font-size: 16px;
}
.button-component-main.lg {
  padding: 17.5px 30px;
  max-height: 64px;
}
@media screen and (max-width: 950px) {
  .button-component-main {
    padding: 12px 20px !important;
  }
}
