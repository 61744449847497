.tabs-component {
  display: flex;
  width: 100%;
  color: rgba(2, 13, 28, 0.5);
  font-family: 'Gilroy-Bold';
}

.tabs-component .tab {
  position: relative;
}
.tabs-component .tab.selected {
  color: #020d1c;
  position: relative;
}
.tabs-component .tab {
  cursor: pointer;
}

.tabs-component .tab ~ .tab {
  margin-left: 30px;
}
.tabs-component .tab ~ .tab {
  margin-left: 20px;
}
.tab.selected::after {
  border: 1px solid #020d1c;
}
.tabs-component .tab::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
}
