.select-custom-new {
  height: 60px;
  border: 1px solid #b1b4b9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
  padding: 0px 45px 0 15px;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
}
.radio-filter-main-wrapper-new {
  position: relative;
  width: 250px;
  min-width: 250px;
}
.radio-filter-main-wrapper-new.fullWidth {
  max-width: unset;
  min-width: 250px;
  width: 100%;
}
.radio-new {
  border: 1px solid #b1b4b9;
  border-radius: 10px;
  border-top: none;
  width: 100%;
  max-width: 250px;
  position: absolute;
  /* top: 53px; */
  border-radius: 10px;
  background-color: #fafafa;
}
.radio-new.fullWidth {
  max-width: unset;
}

.radio-select-custom-new.open {
  border: none;
  border-radius: 0;
  z-index: 1000;
  box-shadow: 0 6.5px 10px 0px rgba(0, 0, 0, 0.05);
}
.radio-select-custom-new.open::after {
  transform: rotate3d(0, 0, 1, 180deg);
}
.radio-select-custom-new ~ .radio-new {
  display: none;
}
.radio-select-custom-new.open ~ .radio-new {
  border-radius: 0px 0px 10px 10px;
  display: flex;
  z-index: 999;
  flex-direction: column;
  max-height: 350px;
  overflow-x: hidden;
}

/* Check box */
.radio-value-new {
  display: flex;
  align-items: center;
  font-family: 'Gilroy-Medium';
  flex-basis: 84%;
  max-width: 84%;
}
.radio-value-new.reversed {
  order: 2;
  margin-left: 10px;
}
.radio-group-new,
.radio-group-new label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.radio-group-new {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}
.radio-group-new:hover {
  background-color: rgba(35, 38, 46, 0.05);
  z-index: 1 !important;
}
.radio-group-new.disabledHover:hover {
  background-color: transparent;
}
.radio-group-new.checked {
  background-color: #23262e0d;
}

/* Hide the browser's default radio */
.radio-group-new input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio */
.radio-checkmark-new {
  position: relative;
  height: 28px;
  width: 28px;
  border: 1px solid #23262e;
  border-radius: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* When the radio is checked, add a blue background */
.radio-group-new.checked .radio-checkmark-new {
  background-color: #555d67;
}

.radio-checkmark-icon {
  z-index: 1;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.radio-label-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  font-size: 15px;
}

.radio-filter-selected-item {
  color: #b1b4b9;
  display: inline;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

.radio-filter-carousel-icon {
  transform: rotate3d(0, 0, 1, 180deg);
  position: absolute;
  right: 20px;
  transition: 0.5s all;
}

.radio-filter-carousel-icon.open {
  transform: rotate3d(0, 0, 1, 0deg);
}

.radio-text-field-wrapper {
  width: 100%;
}

.radio-select-custom-new-border-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #b1b4b9;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
}

.radio-indicator {
  color: #b1b4b9;
  display: inline;
  min-width: 24px;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  padding-left: 3px;
}
.radio-button-wrapper {
  min-width: 28px;
  display: flex;
  justify-content: flex-end;
  flex-basis: 16%;
  max-width: 16%;
}
