.load-more {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

.load-more-button {
  max-width: 164px;
  cursor: pointer;
  width: 100%;
  font-family: 'Gilroy-Bold';
  background-color: #2ce28b !important;
  display: flex;
  color: #23262e;
  height: 60px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
}

.load-more-button:hover {
  background-color: #1ac673 !important;
}
