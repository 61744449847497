.content-page .title-box.edit-mode .title {
  margin-left: 10px;
}

.edit-wallet {
  display: flex;
  align-items: center;
  margin-left: 50px;
  width: 100%;
}

.wallet-edit-edit-icon {
  margin-left: 10px;
  background: #e6e7e8;
  padding: 7px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.edit-wallet .icon-close {
  background: #e6e7e8;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

.edit-wallet-check-icon {
  background: #2ce28b;
  padding: 7px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  margin-left: 10px;
  cursor: pointer;
}
.edit-wallet-check-icon.disabled {
  background: #cccccc;
  pointer-events: none;
}
.edit-wallet-check-icon path {
  stroke: #000;
}

.edit-wallet .wallet-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.8px 6px 6px;
  gap: 6px;
  width: 24px;
  height: 24px;
  background: #9e00ff;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.edit-wallet .title[contentEditable='true'] {
  padding: 10px 15px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}

.edit-wallet .return {
  width: 40px;
  height: 40px;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-wallet .return:hover {
  border-color: rgba(35, 38, 46, 0.1);
}

.edit-wallet .return i {
  transform: rotateY(180deg);
}

.input-group-change {
  display: flex;
  align-items: center;
}

.edit-wallet .approve,
.edit-wallet .reject {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  margin-left: 10px;
  cursor: pointer;
}

.edit-wallet .approve {
  width: 30px;
  height: 30px;

  background: #2ce28b;
  border-radius: 34px;
}

.edit-wallet .approve:hover {
  background: #1ac673;
}

.edit-wallet .reject:hover {
  background: rgba(35, 38, 46, 0.1);
}

.edit-wallet .reject {
  width: 30px;
  height: 30px;

  background: rgba(2, 13, 28, 0.05);
  border-radius: 34px;
}

.edit-wallet .edit {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(2, 13, 28, 0.05);
  border-radius: 34px;
  cursor: pointer;
  margin-left: 10px;
}
.edit-wallet .color-changer.edit {
  border-color: #d3d3d3;
}
.edit-wallet .color-changer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 20px;
  position: relative;
  padding: 4px;
  gap: 10px;
  border: 1px solid #f3f3f3;

  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.edit-wallet .color-menu {
  top: 35px;
  left: 0;
}

@media screen and (max-width: 950px) {
  .edit-wallet {
    padding: 0;
  }
  .edit-wallet .color-changer {
    margin-left: 0;
  }
  .edit-wallet .return {
    width: unset;
    height: unset;
    margin-right: 10px;
  }
}
