.table.login-history {
  height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin-top: 0;
  padding-bottom: 20px;
}
.table.login-history.trial-banner-visible {
  height: calc(100vh - 365px);
}
.media-user {
  width: 25px;
  height: 18px;
  display: flex;
  margin-right: 5px;
  background-image: url('../../assets/icons/Browser.png');
}

.media-user.normal {
  background-position: 0 0;
}
.media-user.ie {
  background-position: 0 -35px;
}
.media-user.firefox {
  background-position: 0 -70px;
}
.media-user.tor {
  background-position: 0 -105px;
}
.media-user.opera {
  background-position: 0 -140px;
}
.media-user.brawe {
  background-position: 0 -175px;
}
.media-user.edge {
  background-position: 0 -210px;
}
.media-user.safari {
  background-position: 0 -245px;
}
.media-user.tor-new {
  background-position: 0 -280px;
}
.media-user.chrome {
  background-position: 0 -315px;
}
.media-user.apple {
  background-position: 0 -350px;
}
.media-user.blackberry {
  background-position: 0 -385px;
}
.media-user.android {
  background-position: 0 -420px;
}
.media-user.macos {
  background-position: 0 -455px;
}
.media-user.windows {
  background-position: 0 -490px;
}
.media-user.linux {
  background-position: 0 -525px;
}
.media-user.mobile {
  background-position: 0 -560px;
}
.login-table-data:not(:first-child) {
  padding-left: 10px;
}
.login-table-data.status {
  flex-basis: 15%;
}
.login-table-data.created-at {
  flex-basis: 15%;
}
.login-table-data.device {
  flex-basis: 15%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.login-table-data.browser {
  flex-basis: 15%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
}
.login-table-data.system {
  flex-basis: 15%;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.login-table-data.ipaddress {
  flex-basis: 25%;
}
.login-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.wallets-address-text {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.status-image {
  width: 28px;
  height: 28px;
}
.login-status-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 950px) {
  .table.login-history {
    height: calc(100vh - 180px);
    height: calc(100dvh - 180px);
  }
  .table.login-history.trial-banner-visible {
    height: calc(100vh - 328px);
    height: calc(100dvh - 328px);
  }
  .table.login-history .table-row.caption {
    display: none;
  }
  .table.login-history .table-row.data {
    padding: 10px 20px;
    flex-direction: column;
    display: block;
  }
  .table.login-history .table-row.data {
    padding: 10px 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d5d5d5;
  }
  .table.login-history .table-row.data > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 10px 0;
  }
  .sign-in-history-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}
