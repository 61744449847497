.asset-title-accordion-details-wrapper {
  width: 100%;
}
.asset-title-accordion-details-wrapper > .asset-title-accordion-details ~ .asset-title-accordion-details {
  border-top: 0.5px solid #e6e7e8;
}
.asset-title-accordion-details-wrapper > .asset-title-accordion-details {
  border-top: none;
}

.asset-title-accordion-details,
.asset-title-accourdion-details-no-balance,
.asset-title-accordion-details .balance {
  display: flex;
  align-items: center;
}

.asset-title-accourdion-details-no-balance {
  justify-content: center;
  font-size: 14px;
  font-family: 'Gilroy-Semibold';
  font-weight: bold;
}

.asset-title-accordion-details .settings {
  flex-basis: 10%;
  gap: 35px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
}

.asset-title-accordion-details .balance {
  flex-basis: 16%;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  flex-direction: column;
  align-items: flex-start;
}

.asset-title-accordion-details .first-column {
  flex-basis: 21%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  padding-right: 5px;
  white-space: nowrap;
  font-family: 'Gilroy-Semibold';
}

.asset-title-accordion-header {
  font-family: 'Gilroy-SemiBold';
  color: #555d67;
}

.asset-title-accordion-details .settings .cancel-button {
  max-width: 164px;
  width: 100%;
  background: rgba(35, 38, 46, 0.05);
  display: flex;
  color: #23262e;
  height: 38px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px 10px;
  gap: 5px;
  border-radius: 10px;
}
.asset-title-accordion-details .settings .cancel-button:hover {
  background: rgba(35, 38, 46, 0.1);
  cursor: pointer;
}
.asset-title-accordion-details .settings .cancel-button i {
  font-size: 24px;
}
.asset-title-accordion-details .first-column .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.asset-title-accordion-details .first-column .wallet-icon {
  color: #fff;
  width: 28px;
  height: 28px;
  align-items: center;
  display: flex;
  padding: 7px 5px 5px 5px;
  justify-content: center;
  font-family: 'Gilroy-Bold';
  border-radius: 5px;
  margin-right: 10px;
  user-select: none;
  text-transform: capitalize;
}

.asset-title-accordion-details .text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #020d1c;
}
.asset-title-accordion-details .sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}
.address-copy {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
  overflow: hidden;
}
.address-copy-wrapper {
  display: flex;
  align-items: center;
}
.asset-title-accordion-symbol-wrapper {
  position: relative;
  margin-right: 8px;
}
.main-symbol-icon {
  width: 40px;
  height: 40px;
}
.secondary-symbol-icon {
  position: absolute;
  top: 0;
  border-radius: 50%;
  transform: translate(20%, -20%);
  border: 1px solid #000;
  right: 0;
  width: 16px;
  height: 16px;
  background: #e7e7e7;
}
.first-column-asset {
  display: flex;
  align-items: center;
}
.mobile-cell-title {
  display: none;
  padding: 0 !important;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 950px) {
  .main-symbol-icon {
    width: 25px;
    height: 25px;
  }
  .secondary-symbol-icon {
    width: 12px;
    height: 12px;
  }
  .asset-title-accordion-details .first-column {
    max-width: 100%;
    flex-basis: 100%;
    width: 100%;
    justify-content: space-between;
    min-height: 32px;
    align-items: flex-start;
  }
  .asset-title-accordion-details .balance {
    flex-basis: 100%;
    max-width: 100%;
    width: 100% !important;
    justify-content: space-between;
    flex-direction: row;
  }
  .asset-title-accordion-details {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-cell-title {
    display: block;
    padding: 0 !important;
    margin-bottom: 0 !important;
    font-family: 'Gilroy-Medium';
  }
  .asset-title-accordion-details .text {
    text-align: end;
  }
  .asset-title-accordion-details .sub-text {
    text-align: end;
  }
}
