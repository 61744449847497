.main {
  width: 100%;
}
.main-dashboard {
  display: flex;
  height: calc(100vh - 70px);
}
.content-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content-page .title-box {
  min-height: 70px;
  position: relative;
  color: #020d1c;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  width: 100%;
  align-items: center;
}

.content-page .title-box:not(.edit-mode) .title {
  display: flex;
  width: 100%;
}
.content-page .title-box .title {
  font-size: 24px;
  font-family: 'Gilroy-Semibold';
  margin-left: 50px;
}
.content-page .content {
  margin-top: 40px;
  height: calc(100vh - 180px);
}
.dashboard-layout-tabs {
  padding-left: 50px;
}
.mobile-trx-filter-icon {
  display: none;
}
@media screen and (max-width: 950px) {
  .content-page .title-box .title {
    margin-left: 0px !important;
    font-size: 20px !important;
  }
  .content-page .content {
    margin-top: 20px !important;
    height: calc(100vh - 150px) !important;
    height: calc(100dvh - 150px) !important;
  }
  .main-dashboard {
    display: flex;
    height: calc(100vh - 60px) !important;
    height: calc(100dvh - 60px) !important;
  }
  .content-page .title-box {
    min-height: 90px !important;
    padding: 0 20px;
    gap: 10px;
  }
  .dashboard-layout-tabs .tabs-component {
    padding: 0 20px 0 20px;
    overflow-x: auto;
    padding-bottom: 10px;
  }
  .dashboard-layout-tabs .tabs-component .tab {
    white-space: nowrap;
  }
  .dashboard-layout-tabs {
    padding: 0;
  }
  .dashboard-layout-tabs > * {
    text-wrap: nowrap;
  }
  .v-modal-content {
    min-height: 100vh !important;
    padding: 20px;
    max-width: unset !important;
  }
  .mobile-trx-filter-icon {
    display: block;
  }
}
