.developers .tabs {
  padding-left: 50px;
}
.button-remove.webhooks::after {
  width: 100px;
}
.developers .governance-layer-table .settings {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
}

.developers .titles {
  padding: 0 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.developers .titles .title {
  font-family: 'Gilroy-Bold';
  font-size: 20px;
}

.developers .titles .sub-title {
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  margin-top: 5px;
}

.permissions-input {
  margin-top: 20px;
  height: 752px;
  overflow-y: scroll;
}
.wallet-icon.plus {
  color: #4b4e51;
  display: flex;
  flex-direction: row;
  text-wrap: nowrap;
  align-items: center;
}
.permissions-input .checkbox-group {
  padding-left: 0;
  height: auto;
}

.permissions-input .checkbox-group ~ .checkbox-group {
  margin-top: 10px;
}

.permissions-input .checkbox-group:hover {
  background-color: inherit;
}

.permissions-input .value-checkbox {
  padding-left: 35px;
}

.permissions-input .checkmark {
  left: 0;
}

.permissions-input div {
  color: #666d7f;
  font-family: 'Gilroy-Medium';
  font-size: 16px;
}

.title-permissions {
  font-size: 20px;
  display: flex;
  font-family: 'Gilroy-SemiBold';
}
.create-api-key-permissions-wrapper {
  margin: 20px 0;
}
.wallet-premissions {
  padding-top: 20px;
}
.create-api-modal .invite-information .user-input .checkmark {
  left: 10px;
}
.wallet-premissions.inactive div,
.wallet-premissions.inactive span,
.wallet-premissions.inactive input {
  color: rgba(35, 38, 46, 0.2);
  border-color: rgba(35, 38, 46, 0.2);
  pointer-events: none;
}

.button.add-button.create-api-key {
  max-width: 182px;
  margin-right: 50px;
}

.sussess-create-key {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-api-modal.mini-api-key-modal .v-modal-content {
  max-width: 530px;
}

.sussess-create-key-api-key .team-icon-api-key-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sussess-create-key-api-key .team-icon-api-key {
  width: 110px;
  height: 110px;
}

.sussess-create-key-api-key .sussess-msg-api-key .sussess-title-api-key {
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 40px;
}

.sussess-create-key-api-key {
  width: 100%;
}

.success-api-key-info-wrapper {
  border: 1px solid #e6e7e8;
  padding: 0 20px 20px 20px;
  border-radius: 10px;
  width: 450px;
}

.success-api-key-info-sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.success-api-key-checkbox-wrapper {
  margin: 20px 0 20px 0px;
  text-align: start;
}

.success-api-key-checkbox-label {
  cursor: pointer;
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.success-api-key-info-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}

.success-api-key-info-row-copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sussess-secret-msg-api-key {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #f74151;
}

.success-api-key-info-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
  padding: 20px 0;
  border-bottom: 1px solid #e6e7e8;
}

.success-api-key-info-row:last-child {
  border: none;
}

.remove-api-key-info-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid #e6e7e8;
}

.remove-api-key-info-row:last-child {
  border: none;
}

.remove-api-key-info-wrapper {
  border: 1px solid #e6e7e8;
  padding: 0 20px;
  border-radius: 10px;
  width: 450px;
  display: flex;
  flex-direction: column;
}

.remove-api-vaults-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.remove-api-key-info-sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.remove-api-key-info-sub-text-break-all {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  word-break: break-all;
}

.remove-api-key-info-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}

.success-api-key-info-copy-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 40px;
}

.sussess-create-key .team-icon {
  width: 120px;
  height: 75px;
}

.sussess-create-key .sussess-msg {
  width: 440px;
  text-align: center;
}

.sussess-create-key .sussess-msg.secret {
  text-align: left;
  border-top: 1px solid #f3f3f3;
}

.sussess-create-key .sussess-msg .sussess-title {
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #666d7f;
  margin-top: 30px;
}

.sussess-create-key .sussess-msg .sussess-name {
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  line-height: 100%;
  margin-top: 10px;
}

.sussess-msg .address-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 30px;
  justify-content: center;
}

.sussess-msg .address-data .address {
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  color: #666d7f;
}

.sussess-create-key .sussess-msg.secret .address-data {
  justify-content: inherit;
  margin-top: 22px;
}

.sussess-create-key .sussess-msg.secret .address-data .address {
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  line-height: 150%;
  color: #23262e;
}

.sussess-create-key .sussess-msg.secret .sussess-secret {
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  line-height: 21px;
  margin-top: 30px;
  color: #23262e;
}

.sussess-create-key .sussess-msg.secret .sussess-secret-msg {
  font-family: 'Gilroy-Medium';
  font-size: 16px;
  line-height: 100%;
  color: #f74151;
  margin-top: 10px;
}

.sussess-create-key .checkbox-group {
  display: flex;
  align-items: center;
  height: auto;
  padding-left: 35px;
  font-family: 'Gilroy-Medium';
  font-size: 16px;
}

.sussess-create-key .checkbox-group:hover {
  background: inherit;
}

.sussess-create-key .button-holder,
.sussess-create-key .button-holder .button {
  max-width: 100%;
  width: 100%;
}

.sussess-create-key .sussess-msg.secret .address-data .address {
  max-width: 390px;
  overflow-wrap: break-word;
  padding-bottom: 0;
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  line-height: 150%;
  color: #23262e;
}

.titles-info {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.webhooks .button-remove {
  width: 38px;
  height: 38px;
  background: rgba(2, 13, 28, 0.05);
  display: flex;
  font-size: 14px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  justify-content: center;
}
.webhooks .button-remove:hover {
  background: rgba(2, 13, 28, 0.1);
}
.webhooks .permissions-input {
  height: calc(100% - 20px);
  overflow-y: auto;
}
.webhooks .select-custom.open ~ .checkboxes {
  padding-left: 10px;
}
.table-data.url {
  word-break: break-all;
  padding: 0 20px 0 0;
}
.webhooks-callbacks {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  height: calc(100vh - 244px);
}
.governance-layer-table.developers-table.webhooks-callbacks {
  display: flex;
  gap: 40px;
}
.webhooks .table-row.data.activeHover:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}
.webhooks-endpoints {
  display: flex;
  flex-basis: 55%;
  flex-direction: column;
  overflow-y: scroll;
}
.governance-layer-table.developers-table.webhooks-callbacks .table-content {
  width: 50%;
  height: 100vh;
  overflow-y: scroll;
}
.governance-layer-table.developers-table.webhooks-callbacks .jsonInfo {
  width: 50%;
}
.webhooks-callbacks .table-data {
  padding: 20px;
}
.webhooks-callbacks .table-data.icon {
  flex-direction: row;
}
.webhooks-callbacks .table-data.icon .icon-holder {
  padding-right: 10px;
}
.governance-layer-table.developers-table.webhooks-callbacks .table-data,
.governance-layer-table.developers-table.webhooks-callbacks .webhooks-table-row {
  padding-left: 20px;
}
.webhooks-table-row.caption {
  display: flex;
  justify-content: space-between;
}
.webhooks-callbacks .webhooks-table-row.caption:hover {
  cursor: pointer;
}
.webhooks-callbacks .webhooks-table-row.caption.active {
  background: rgba(35, 38, 46, 0.04);
}
.webhooks-callbacks .table-data.date {
  align-items: flex-end;
}
.jsonInfo {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
  word-break: break-all;
  overflow-y: scroll;
}
.webhooks-callbacks .jsonInfo .title {
  color: #020d1c;
  font-family: Gilroy-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  margin-top: 38px;
}
.title.request ~ div {
  margin-top: 20px;
}
.webhooks-callbacks .jsonInfo .title-webhooks {
  color: #020d1c;
  font-family: Gilroy-Bold;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 22px */
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
}
.httpcode {
  border-radius: 5px;
  padding: 8px 10px;
  width: 110px;
  margin-top: 20px;
  text-align: center;
}
.httpcode.ok {
  background-color: rgba(51, 191, 100, 0.1);
  color: #33bf64;
}
.httpcode.error {
  background-color: rgba(247, 65, 80, 0.05);
  color: #f74151;
}

.exit-webhook {
  position: absolute;
  left: 20px;
  cursor: pointer;
}
.exit-webhook .icon::before {
  transform: rotate(-180deg);
}
.no-data {
  padding-left: 50px;
}

.table-data.level.wallets-webhooks {
  flex-direction: row;
}
.webhooks-table-data:not(:first-child) {
  padding-left: 10px;
}
.webhooks-table-data.url {
  flex-basis: 30%;
}
.webhooks-table-data.wallets {
  flex-basis: 15%;
  display: flex;
  align-items: center;
}
.webhooks-table-data.subscribed {
  flex-basis: 40%;
}
.webhooks-table-data.status {
  flex-basis: 10%;
}
.webhooks-table-data.settings {
  flex-basis: 5%;
}
.webhooks-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.status-icon {
  width: 28px;
  height: 28px;
}
.url-text {
  word-break: break-all;
}
.webhooks-table-row {
  padding: 20px;
}
.webhooks-table-row,
.webhooks-table-data.icon {
  display: flex;
  align-items: center;
}
.webhooks-table-data.icon {
  gap: 10px;
}
.icon-add-contact {
  display: flex;
}
.create-api-textfield-wrapper {
  margin-top: 30px;
}
.create-api-textfield-wrapper label {
  margin-bottom: 10px;
}
.generate-address-filter-template {
  display: flex;
  align-items: center;
  gap: 7px;
}
.generate-address-blockchain-name {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
}
.generate-address-blockchain-currency {
  font-family: 'Gilroy-Medium';
  font-size: 12px;
}
.create-api-api-version-select-wrapper label {
  margin-bottom: 10px;
}
.api-key-alert-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 30px 0 10px 0;
  gap: 15px;
}
.remove-webhook-info-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid #e6e7e8;
}

.remove-webhook-info-row:last-child {
  border: none;
}

.remove-webhook-vaults-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.remove-webhook-info-wrapper {
  border: 1px solid #e6e7e8;
  padding: 0 20px;
  border-radius: 10px;
  width: 450px;
  display: flex;
  flex-direction: column;
}

.remove-webhook-info-sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.remove-webhook-info-sub-text-break-all {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  word-break: break-all;
}

.remove-webhook-info-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}

.remove-device-info-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid #e6e7e8;
}

.remove-device-info-row:last-child {
  border: none;
}

.remove-device-info-wrapper {
  border: 1px solid #e6e7e8;
  padding: 0 20px;
  border-radius: 10px;
  width: 450px;
  display: flex;
  flex-direction: column;
}
.remove-device-info-sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.remove-device-info-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}
.create-api-api-version-row-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.create-api-api-version-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 52%;
  width: 52%;
}
.create-api-api-version-name {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.create-api-api-version-label {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
.create-api-api-version-default {
  padding: 5px 10px;
  border-radius: 4px;
  background: #e6e7e8;
}
.create-api-api-version-latest {
  padding: 5px 10px;
  border-radius: 4px;
  background: #2ce28b;
}
.create-api-api-version-select-wrapper {
  padding-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.api-table-api-version-button-wrapper {
  display: flex;
  gap: 20px;
}
.api-table-title-wrapper {
  padding: 0 50px;
  margin-top: 50px;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.api-table-title-wrapper .title {
  font-family: 'Gilroy-Bold';
  font-size: 20px;
}
.api-table-title-wrapper .sub-title {
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  margin-top: 5px;
}
.api-table-api-version-wrapper {
  padding: 0 14px 0 20px;
  border-radius: 10px;
  border: 1.5px solid #e6e7e8;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  max-width: 360px;
}
.api-table-api-version-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-wrap: nowrap;
}
.api-table-api-version-trans-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-wrap: nowrap;
}
.api-table-api-version-icon {
  margin-bottom: 1px;
  width: 14px;
  height: 14px;
  font-size: 20px;
  transform: scale(1.15);
  cursor: pointer;
}
.api-table-api-version-icon.disabled path {
  fill: #a7a7a7;
}
.api-table-api-version-icon-wrapper {
  min-width: 28px;
  min-height: 28px;
  background: #ebeced;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.create-api-key-summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-end;
  width: 100%;
}
.create-api-key-summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  text-align: left;
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  gap: 20px;
}
.create-api-key-summary-many {
  display: flex;
  align-items: center;
  gap: 10px;
  word-break: break-all;
  text-align: right;
  justify-content: flex-end;
  flex-basis: 50%;
  max-width: 50%;
  text-wrap: nowrap;
}
.create-api-key-summary-many span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
}
.api-keys-table-data-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.table.webhooks-table {
  height: calc(100vh - 350px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 40px;
  padding-bottom: 20px;
}
.table.webhooks-table.trial-banner-visible {
  height: calc(100vh - 538px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 40px;
  padding-bottom: 20px;
}
.webhooks-wallets-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.resend-button-loading-icon {
  margin: 0 4px 8px 4px;
}
.callback-logs-loader {
  display: flex;
  justify-content: center;
  flex-basis: 45%;
  margin-top: 20px;
}
.webhook-event-dropdown-template {
  display: flex;
  align-items: center;
}
.webhook-event-dropdown-template .react-tooltip {
  width: 98%;
}
.webhook-event-dropdown-template .question {
  position: relative;
  cursor: pointer;
}
.webhook-event-dropdown-template .question .icon:hover + .tooltip-dashboard {
  opacity: 1;
}
.webhook-event-dropdown-template .question .icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #23262e0a;
  border-radius: 18px;
  margin-left: 8px;
  color: #23262e;
}
.loading-full-height-developers {
  height: calc(100% - 40px);
}
@media screen and (max-width: 950px) {
  .webhooks-callbacks .jsonInfo .title-webhooks {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .api-table-title-wrapper {
    padding: 0 20px;
    margin: 30px 0 30px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
  }
  .api-table-title-wrapper > * {
    width: 100%;
  }
  .api-table-api-version-button-wrapper {
    flex-direction: column;
  }
  .api-table-api-version-button-wrapper .button-holder > * {
    width: 100%;
  }
  .api-table-api-version-wrapper {
    height: 52px;
  }
  .api-table.team-page .table-row.caption {
    display: none;
  }
  .api-table.team-page .table-row.data {
    padding: 10px 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d5d5d5;
  }
  .api-table.team-page .table-row.data .actions.icon-dots {
    width: 32px;
    height: 32px;
    font-size: 15px;
  }
  .api-table.team-page .table-row.data > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 0;
  }
  .table.governance-layer-table {
    padding-bottom: 0 !important;
    height: calc(100vh - 440px) !important;
  }
  .developers .titles {
    padding: 0 20px;
    margin-top: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .developers .titles > * {
    width: 100%;
  }
  .developers .titles .titles-info .title {
    text-align: center;
    display: block;
  }
  .developers .titles .button-holder button {
    width: 100%;
  }
  .table.webhooks-table {
    height: calc(100vh - 330px);
    height: calc(100dvh - 330px);
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 40px;
  }
  .table.webhooks-table.trial-banner-visible {
    height: calc(100vh - 488px);
    height: calc(100dvh - 488px);
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 40px;
  }
  .remove-device-info-wrapper {
    width: 100%;
  }
  .webhooks-callbacks {
    flex-direction: column;
    display: block;
    overflow-y: auto;
    margin-top: 20px;
    height: calc(100vh - 225px) !important;
    height: calc(100dvh - 225px) !important;
  }
  .webhooks-callbacks.trial-banner-visible {
    flex-direction: column;
    display: block;
    overflow-y: auto;
    margin-top: 20px;
    height: calc(100vh - 383px) !important;
    height: calc(100dvh - 383px) !important;
  }
  .webhooks-endpoints {
    flex-basis: 100%;
    height: calc(100vh - 225px) !important;
    height: calc(100dvh - 225px) !important;
  }
  .jsonInfo {
    padding: 20px;
  }
  .webhooks-table-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .webhooks-table-data.date {
    padding-left: 38px;
  }
  .exit-webhook {
    position: relative;
    left: unset;
  }
  .success-api-key-info-wrapper {
    width: 100%;
    margin-top: 20px;
  }
  .success-api-key-info-sub-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px);
  }
  .create-api-key-summary-row span {
    text-align: end;
    word-break: break-all;
  }
  .create-api-key-summary-row div {
    text-align: end;
  }
  .create-api-key-summary-many {
    display: flex;
    align-items: center;
    gap: 10px;
    word-break: break-all;
    text-align: left;
    justify-content: flex-end;
    flex-basis: 50%;
    max-width: 50%;
    text-wrap: wrap;
  }
  .remove-webhook-info-wrapper {
    width: 100%;
  }
  .remove-api-key-info-wrapper {
    width: 100%;
  }
  .api-table-api-version-trans-text {
    font-size: 14px;
  }
  .loading-full-height-developers {
    height: 100%;
  }
}
