.gas-tanker-icon-generate-vault {
  margin-right: 5px;
}
.gas-tanker-icon-generate-vault.disabled path {
  fill: #fff;
}
.gas-tanker-wallet-button-filters-wrapper {
  display: flex;
  padding: 0 50px 0 0;
  margin-top: 40px;
}
.gas-tanker-accordion-table-parent-wallets {
  height: calc(100vh - 344px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  margin-top: 40px;
}
.gas-tanker-accordion-table-parent-wallets.trial-banner-visible {
  height: calc(100vh - 500px);
  width: 100%;
  margin-top: 40px;
}
.gas-tanker-accordion-table-parent-wallets .table-content .addresses-tab-table-row.caption {
  display: flex;
  padding-bottom: 10px;
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 2;
  padding: 0 50px 10px 50px;
}
.gas-tanker-accordion-table-parent-wallets .table-row.caption {
  padding-bottom: 10px;
}
.gas-tanker-table-content-wallet-addresses-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  height: 100%;
}
.gas-tanker-table-content-wallet-addresses-table .accordion-component,
.gas-tanker-table-content-wallet-addresses-table .accordion-component .accordion-title-wrapper,
.gas-tanker-table-content-wallet-addresses-table .accordion-component .accordion-details-wrapper {
  height: unset;
}
.gas-tanker-addresses-table-data:not(:first-child) {
  padding-left: 10px;
}
.gas-tanker-addresses-table-data.name {
  flex-basis: 25%;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.gas-tanker-addresses-table-data.blockchain {
  flex-basis: 20%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Gilroy-Semibold';
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}
.gas-tanker-addresses-table-data.trx-address {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.gas-tanker-addresses-table-data.created-at {
  flex-basis: 20%;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.gas-tanker-addresses-table-data.settings {
  flex-basis: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.gas-tanker-addresses-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
  font-family: 'Gilroy-Semibold';
  font-size: 14px !important;
  font-weight: 400;
  line-height: 15px;
}
.gas-tanker-wallet-addresses-table-header {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px !important;
  color: #555d67;
}
.gas-tanker-empty-addresses-label {
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f3f3f3;
}
