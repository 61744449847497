.contact-row-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  padding: 25px 0;
}
.contact-row-blockchain,
.contact-row-address,
.contact-row-status {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contact-row-address-name {
  flex-basis: 20%;
}
.contact-row-address {
  flex-basis: 40%;
}
.contact-row-status {
  flex-basis: 15%;
}
.contact-row-blockchain {
  flex-basis: 20%;
}
.contact-row-settings {
  flex-basis: 5%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.contact-row-settings-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e7e8;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.contact-row-settings-icon:hover {
  background: #dddddd;
}
.contact-row-settings-icon-svg {
  transform: scale(0.9);
}
@media screen and (max-width: 950px) {
  .contact-row-wrapper {
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d5d5d5;
  }
  .contact-row-wrapper > * {
    padding: 10px 0px;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}
