.vault-icon-component {
  width: 28px;
  height: 28px;
  min-width: 28px;
  display: flex;
  font-size: 14px;
  color: #fff;
  align-items: center;
  display: flex;
  padding: 7px 5px 5px 5px;
  justify-content: center;
  font-family: 'Gilroy-Bold';
  border-radius: 5px;
  margin-right: 10px;
  user-select: none;
  text-transform: capitalize;
  position: relative;
}
.vault-icon-component-secondary-icon {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
}
@media screen and (max-width: 950px) {
  .vault-icon-component {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
}
