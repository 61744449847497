.wallet-icons {
  display: flex;
}

.governance-layer .wallet-filter {
  margin-top: 30px;
  margin-left: 50px;
  width: 100%;
  font-family: 'Gilroy-Medium';
}

.governance-layer-table {
  width: 100%;
  margin-top: 40px;
}

.governance-layer-table .gov-table-data,
.governance-layer-table .table-row {
  display: flex;
  width: 100%;
}
.table.governance-layer-table.team-table .team-table-data.name {
  min-width: 250px;
}

.governance-layer-table .settings {
  width: 20%;
}
.governance-layer-table .level,
.governance-layer-table .wallet-table {
  width: 50%;
}

.governance-layer-table .gov-table-data {
  flex-direction: column;
}

.name-data {
  display: flex;
  align-items: center;
}

.name-data .creator {
  font-size: 12px;
  color: #666d7f;
  font-family: 'Gilroy-Medium';
}

.name-data .icon-wrapper {
  width: 28px;
  height: 28px;
  border: 1px solid #e6e7e8;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.name-data .icon-wrapper .sub-icon {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -45%);
  background: #fff;
  z-index: 2;
}

.active .name-data .name-icon::before {
  content: '\e810';
  color: #33bf64;
}

.pending .name-data .name-icon::before {
  content: '\e823';
  color: #f7931a;
}

.name-right {
  margin-left: 10px;
}
.name-right .name,
.name-wallet {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vault-table-data .name-right {
  max-width: calc(100% - 80px);
}

/* Tabs */
.tabs {
  display: flex;
  width: 100%;
  color: rgba(2, 13, 28, 0.5);
  font-family: 'Gilroy-Bold';
}

.tabs .tab {
  text-decoration: none;
  cursor: pointer;
}
.tabs .tab.hide,
.tabs .tab.hide:hover {
  color: #fff;
  cursor: default;
  width: 5px;
}
.tabs .tab:hover {
  color: #020d1c;
}

.tabs .tab.active {
  color: #020d1c;
  position: relative;
}

.tabs .tab::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
}

.tabs .tab ~ .tab {
  margin-left: 20px;
}
.tab.selected {
  color: #020d1c;
  position: relative;
}
.tab.selected::after {
  border: 1px solid #020d1c;
}
.active .tabs .tab[data-type='active'],
.pending .tabs .tab[data-type='pending'] {
  color: #020d1c;
  position: relative;
}

.active .tabs .tab[data-type='active']::after,
.pending .tabs .tab[data-type='pending']::after {
  border: 1px solid #020d1c;
}

.tabs .tab {
  position: relative;
}

.tabs .tab[data-type='pending'] .info-pending {
  background-color: #f7931a;
  min-width: 23px;
  height: 23px;
  font-size: 12px;
  color: #fff;
  border-radius: 50%;
  top: -25px;
  position: absolute;
  padding: 0 2px;
  display: flex;
  right: -15px;
  justify-content: center;
  line-height: 25px;
}
.tabs .tab[data-type='active'] .info-active {
  background-color: #2ce28b;
  min-width: 23px;
  height: 23px;
  font-size: 12px;
  color: #23262e;
  border-radius: 50%;
  top: -25px;
  position: absolute;
  padding: 0 2px;
  display: flex;
  right: -15px;
  justify-content: center;
  line-height: 25px;
}
.table-row {
  padding: 0 50px;
}
.governance-layer-table .no-active.table-row.no-prem {
  border-bottom: 0;
}
.table-row.caption {
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 2;
}

.table-row.data {
  padding: 30px 50px 30px 50px;
  position: relative;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
}

.content ~ .governance-layer {
  position: relative;
  padding: 0;
}

.governance-layer-table .settings .actions {
  width: 38px;
  height: 38px;
  background: rgba(2, 13, 28, 0.05);
  display: flex;
  font-size: 14px;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  font-size: 18px;
  justify-content: center;
}

.settings {
  position: relative;
}

.governance-layer-table .settings .actions:hover {
  background: rgba(35, 38, 46, 0.1);
}

.governance-layer .wallet-icon:hover .tooltip-dashboard {
  opacity: 1;
}

.governance-layer .wallet-icon {
  position: relative;
  cursor: pointer;
  font-family: 'Gilroy-Bold';
  font-size: 10px;
  width: 28px;
  height: 28px;
}

.governance-layer .wallet-icon.plus {
  color: #020d1c;
  white-space: nowrap;
  cursor: inherit;
  width: unset;
  height: unset;
}

.add-button {
  width: 209px;
  height: 60px;
  background-color: #2ce28b;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Gilroy-Bold';
  font-weight: bold;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-button:hover {
  background-color: #1ac673;
}

.governance-layer .governance-header,
.header-table {
  display: flex;
  align-items: center;
  padding: 0 50px;
}

.gl-modal .v-modal-content {
  max-width: 780px;
}
.governance-layer .governance-header ~ .wallet-filter {
  max-width: 264px;
  width: 100%;
}

.button-holder {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  user-select: none;
}

.governance-filters-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 50px 0 50px;
}

.button-holder-policy {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  user-select: none;
}
.governance-layer .governance-header i {
  font-size: 24px;
}

.overlay {
  position: fixed;
  background: #23262ec6;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 9;
  overflow-y: auto;
}

.v-modal-content {
  position: absolute;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
}

.v-modal-content.overflowing {
  position: absolute;
  top: 0;
  transform: translate(-50%, 0%);
}

.v-modal-title {
  color: #020d1c;
  font-size: 32px;
  font-family: 'Gilroy-SemiBold';
}

.v-modal .wallet-filter {
  padding: 0;
  margin: 0;
}
.v-modal .wallet-filter .select-custom {
  overflow: hidden;
}
.v-modal .wallet-filter .select-custom,
.v-modal .wallet-filter .checkboxes {
  max-width: 100%;
  width: 100%;
}

.wallets-big .select-custom .value-checkbox {
  margin: 0;
  padding: 0;
}

.v-modal .select-group .form-control {
  width: 50%;
  display: flex;
}

.v-modal .two-select-group .select-group {
  display: flex;
  flex-direction: row;
}

.v-modal .select-group select ~ select {
  margin-left: 20px;
}

.v-modal .select-group select {
  width: 50%;
}

.v-modal .select-group select ~ select {
  margin-left: 30px;
}

.v-modal label {
  font-family: 'Gilroy-SemiBold';
}

.v-input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}
.v-input-group.inactive {
  pointer-events: none;
  color: rgba(35, 38, 46, 0.3);
}
.v-input-group.inactive {
  background-color: inherit;
  color: rgba(35, 38, 46, 0.3);
}

select.form-control:disabled,
input.form-control:disabled {
  user-select: none;
  background-color: inherit;
  color: rgba(35, 38, 46, 0.3);
}

input.form-control:disabled::placeholder {
  color: rgba(35, 38, 46, 0.3);
}

.two-select-group {
  margin-top: 30px;
}

.level-module {
  display: flex;
  color: #020d1c;
  flex-direction: column;
  margin-top: 32px;
}
.levels.no-active,
.level.no-active {
  user-select: none;
  pointer-events: none;
  color: rgba(35, 38, 46, 0.3);
}
.level.no-active .level-header {
  z-index: 2;
}
.level-check-group.no-active * {
  color: rgba(35, 38, 46, 0.1) !important;
  border-color: rgba(35, 38, 46, 0.1) !important;
  user-select: none;
  pointer-events: none;
}
.level.no-active::before {
  content: '';
  user-select: none;
  pointer-events: none;
  width: 309px;
  height: 290px;
  position: absolute;
  top: -30px;
  border-radius: 0 11px 10px 0;
  left: -10px;
}
.levels.no-active *,
.level.no-active * {
  color: rgba(35, 38, 46, 0.1) !important;
  border-color: rgba(35, 38, 46, 0.1) !important;
}

.levels.no-active .level-content::-webkit-scrollbar-thumb,
.level.no-active .level-content::-webkit-scrollbar-thumb {
  background-color: rgba(35, 38, 46, 0.1);
  outline: 1px solid rgba(35, 38, 46, 0.1);
}
.level-group .level-big-title {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
}

.level-module .levels {
  border: 1px solid #000;
  border-radius: 10px;
  display: flex;
  margin-top: 10px;
}
.level-module .levels .level:first-child {
  border-right: 1px solid #efefef;
}
.level-module .levels .level:first-child ~ .level {
  padding-left: 40px;
}
.level-module .levels .level:first-child::after {
  content: ' ';
  background-image: url('../../../public/images/circle.svg');
  display: flex;
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: 45%;
  border-right: 1px solid #efefef;
  border-radius: 100%;
}
.level-module .levels .level {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding: 30px;
  position: relative;
}

.level-module .level .level-header {
  display: flex;
  width: 100%;
}

.level-module .level .level-title {
  font-family: 'Gilroy-Semibold';
  width: 100%;
}

.level-module .level .level-approves-number {
  font-size: 14px;
  font-family: 'Gilroy-Medium';
  color: #23262e;
  display: flex;
  justify-content: right;
  width: 100%;
}

.level-module .level-check-group {
  font-family: 'Gilroy-Medium';
  color: #23262e;
}

.level-module .level-check-group .checkbox-group {
  height: 24px;
  padding-left: 25px;
}

.level-module .level-check-group .checkbox-group.checked {
  background-color: inherit;
}

.level-module .level-check-group ~ .level-check-group {
  margin-top: 7px;
}

.level-module .level-check-group .checkmark {
  left: 0;
}

.level-module .value-checkbox {
  padding-left: 5px;
}

.level-module .level-content {
  height: 210px;
  overflow-y: scroll;
  margin-top: 15px;
}

.level-content::-webkit-scrollbar {
  width: 3px;
}

.level-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(35, 38, 46, 0.1);
}

.level-content::-webkit-scrollbar-thumb {
  background-color: rgba(35, 38, 46, 1);
  outline: 1px solid rgba(35, 38, 46, 1);
  border-radius: 20px;
}

.v-modal .button-holder {
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
}
.edit.inactive {
  background-color: rgba(35, 38, 46, 0.2);
  color: #fff;
}
.edit.inactive:active {
  pointer-events: none;
}
.add-button.inactive {
  background-color: rgba(35, 38, 46, 0.2);
  color: #fff;
}
.add-button.inactive:hover {
  background-color: rgba(35, 38, 46, 0.2);
  color: #fff;
}
.add-button.inactive:active {
  pointer-events: none;
}
.cancel-button.inactive {
  background: rgba(35, 38, 46, 0.05);
  color: rgba(35, 38, 46, 0.2);
}
.cancel-button.inactive:hover {
  background: rgba(35, 38, 46, 0.05);
  color: rgba(35, 38, 46, 0.2);
}
.cancel-button.inactive:active {
  pointer-events: none;
}
.cancel-button.inactive i::before {
  color: rgba(35, 38, 46, 0.2);
}

.v-modal .button-holder .add-button {
  max-width: 100%;
  width: 100%;
}

.v-modal .button-holder .add-button i {
  font-size: 20px;
}

.v-modal .view-modal {
  color: #020d1c;
}
.v-modal .view-modal .title-modal {
  font-family: 'Gilroy-SemiBold';
  font-size: 32px;
  color: #020d1c;
}
.v-modal .view-modal .range-info {
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  margin-top: 10px;
  gap: 5px;
  display: flex;
}
.v-modal .view-modal .info-view {
  margin-top: 20px;
  display: flex;
}
.v-modal .view-modal .info-view .info-row ~ .info-row {
  margin-left: 40px;
}
.v-modal .view-modal .info-view .info-row .title {
  font-size: 14px;
  font-family: 'Gilroy';
  color: #666d7f;
}
.v-modal .view-modal .info-view .info-row .value {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
}
.v-modal .approves {
  margin-top: 30px;
  padding-top: 32px;
  padding-bottom: 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.v-modal .approves .levels {
  display: flex;
  margin-top: 10px;
}
.v-modal .approves .level {
  border: 1px solid #efefef;
  width: 50%;
  padding: 20px;
}
.v-modal .approves .level2 {
  border-left: 0;
}
.v-modal .approves .level .title {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  margin-bottom: 10px;
}
.v-modal .view-modal .approves .level .values {
  margin-top: 17px;
  font-family: 'Gilroy-Medium';
  font-size: 14px;
}
.v-modal .approves .level.level1::after {
  content: ' ';
  background-image: url('../../../public/images/circle.svg');
  display: flex;
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: 45%;
  border-right: 1px solid #efefef;
  border-radius: 100%;
}
.v-modal .approves .level.level1 {
  position: relative;
  border-radius: 10px 0 0 10px;
}

.v-modal .approves .level.level2 {
  border-radius: 0 10px 10px 0;
}
.v-modal .view-modal .wallets {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.v-modal .view-modal .wallets-content {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 20px;
}
.v-modal .view-modal .wallet {
  display: flex;
  align-items: center;
  font-family: 'Gilroy';
  font-size: 14px;
  color: #020d1c;
}
.v-modal .view-modal .wallet .title {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  color: #020d1c;
}
.view-modal .wallet-icon {
  padding: 0;
}
.view-modal .wallet-icon:hover {
  cursor: inherit;
}
.v-modal-content .icon.icon-close {
  color: #b3b6bb;
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  z-index: 2;
}

.icon-new-rule:before {
  display: flex;
  font-size: 26px;
}
.gov-table-data:not(:first-child) {
  padding-left: 10px;
}
.gov-table-data.name {
  flex-basis: 30%;
}
.gov-table-data.amount-limit {
  flex-basis: 25%;
  flex-direction: row;
  gap: 4px;
}
.gov-table-data.amount-limit .infin {
  font-size: 22px;
  line-height: 22px;
}
.gov-table-data.level {
  flex-basis: 10%;
}
.gov-table-data.level,
.gov-table-data.level.amount {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 10px;
}
.gov-table-data.wallet-table {
  flex-basis: 20%;
}
.gov-table-data.settings,
.gov-table-data.settings-menu-gl,
.gov-table-data.settings-menu-gl-active {
  flex-basis: 5%;
}
.gov-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.trx-rule-details-info-view {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e6e7e8;
  border-radius: 10px;
}
.trx-rule-details-range-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.trx-rule-details-info-view-sub-text,
.trx-rule-details-info-view-section-text {
  font-family: 'Gilroy-Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #666d7f;
}
.trx-rule-details-info-view-section-text {
  color: #000;
}
.trx-rule-details-info-view-heading {
  font-family: 'Gilroy-SemiBold';
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.trx-rule-details-approves {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}
.trx-rule-details-approves .trx-rule-details-levels {
  display: flex;
  margin-top: 10px;
}
.trx-rule-details-approves .trx-rule-details-level {
  border: 1px solid #efefef;
  width: 50%;
  padding: 20px;
}
.trx-rule-details-approves .trx-rule-details-level2 {
  border-left: 0;
}
.trx-rule-details-approves .trx-rule-details-level .trx-rule-details-title {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
  margin-bottom: 10px;
  padding: 8px 15px 8px 15px;
  background: #000;
  color: #fff;
  border-radius: 24px;
}
.trx-rule-details-approves .trx-rule-details-level .trx-rule-details-values {
  margin-top: 17px;
  font-family: 'Gilroy-Medium';
  color: #020d1c;
  font-size: 14px;
}
.trx-rule-details-approves .trx-rule-details-level.trx-rule-details-level1::after {
  content: ' ';
  background-image: url('../../../public/images/circle.svg');
  display: flex;
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: 45%;
  border-right: 1px solid #efefef;
  border-radius: 100%;
}
.trx-rule-details-approves .trx-rule-details-level.trx-rule-details-level1 {
  position: relative;
  border-radius: 10px 0 0 10px;
}

.trx-rule-details-approves .trx-rule-details-level.trx-rule-details-level2 {
  border-radius: 0 10px 10px 0;
}
.trx-rule-details-wallets {
  margin-top: 10px;
  display: flex;
  padding: 20px 20px 10px 20px;
  border: 1px solid #e6e7e8;
  border-radius: 10px;
  flex-wrap: wrap;
}
.trx-rule-details-wallet-icon {
  width: 28px;
  height: 28px;
  color: #fff;
  align-items: center;
  display: flex;
  padding: 7px 5px 5px 5px;
  justify-content: center;
  font-family: 'Gilroy-Bold';
  border-radius: 5px;
  margin-right: 5px;
  user-select: none;
  text-transform: capitalize;
}
.trx-rule-details-wallets-row {
  display: flex;
  align-items: center;
  flex-basis: 50%;
  padding: 0 0 10px 0;
}
.trx-rule-details-wallets-row:nth-child(odd) {
  border-right: 1px solid #e6e7e8;
}
.trx-rule-details-wallets-row:nth-child(even) {
  padding-left: 30px;
}
.trx-rule-details-wallet {
  display: flex;
  align-items: center;
  font-family: 'Gilroy';
  font-size: 14px;
  color: #020d1c;
}
.trx-rule-details-wallet .trx-rule-details-title {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  color: #020d1c;
}
.trx-rule-details-wallet-icon {
  padding: 0;
}
.trx-rule-details-wallet-icon:hover {
  cursor: inherit;
}
.trx-rule-details-info-view-sub-text {
  font-size: 14px;
}
.trx-rule-details-info-view-info-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.trx-rule-details-info-row {
  flex-basis: 33.3%;
  padding-left: 30px;
  border-right: 1px solid #e6e7e8;
}
.trx-rule-details-info-view > *:last-child {
  border: none;
}
.trx-rule-details-info-view > *:nth-child(1) {
  padding: 0;
}
.trx-rule-view-modal-title {
  font-family: 'Gilroy-SemiBold';
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.table.governance-layer-table.transaction-policy .name-right {
  max-width: 250px;
}
.table.governance-layer-table.transaction-policy {
  height: calc(100vh - 335px);
  overflow-x: hidden;
  overflow-y: auto;
}
.table.governance-layer-table.transaction-policy.trial-banner-visible {
  height: calc(100vh - 518px);
}
@media screen and (max-width: 950px) {
  .governance-layer.active .governance-header,
  .governance-layer.pending .governance-header {
    padding: 0 20px;
    margin: 10px 0 20px 0;
  }
  .governance-layer.active .table-row.caption,
  .governance-layer.pending .table-row.caption {
    display: none;
  }
  .governance-filters-wrapper {
    flex-direction: column-reverse;
    padding: 0 20px;
    gap: 20px;
  }
  .governance-filters-wrapper .filter-main-wrapper-new {
    max-width: unset;
    min-width: 250px;
    width: 100%;
  }
  .governance-filters-wrapper .filter-main-wrapper-new .checkboxes-new {
    max-width: unset;
    width: 100%;
  }
  .button-holder-policy > * {
    width: 100%;
  }
  .table.governance-layer-table.transaction-policy .table-row.data {
    padding: 10px 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d5d5d5;
    display: block;
  }
  .table.governance-layer-table.transaction-policy .table-row.data .actions.icon-dots {
    width: 32px;
    height: 32px;
    font-size: 15px;
  }
  .table.governance-layer-table.transaction-policy .table-row.data > * {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .table.governance-layer-table.transaction-policy .name-right .name,
  .table.governance-layer-table.transaction-policy .name-right .creator {
    width: unset;
    max-width: 180px;
    text-align: end;
  }
  .table.governance-layer-table.transaction-policy .gov-table-data.amount-limit div {
    text-align: end;
  }
  .table.governance-layer-table.transaction-policy .vault-icon-component {
    margin-right: 0;
  }
  .table.governance-layer-table.transaction-policy .wallet-icons {
    gap: 10px;
  }
  .table.governance-layer-table.transaction-policy {
    padding-bottom: 0;
    height: calc(100vh - 359px) !important;
    height: calc(100dvh - 359px) !important;
    margin-bottom: 0;
  }
  .table.governance-layer-table.transaction-policy.trial-banner-visible {
    height: calc(100vh - 517px) !important;
    height: calc(100dvh - 517px) !important;
  }
  .table-row.no-active {
    padding: 0 20px !important;
    text-align: center;
  }
  .v-modal-content .icon.icon-close {
    right: 20px;
    top: 20px;
  }
  .overlay {
    min-height: 100dvh;
    margin: 0 !important;
    padding: 0 !important;
  }
  .v-modal-content {
    border-radius: 0 !important;
  }
  .level-module .levels .level:first-child::after {
    display: none;
    border-bottom: 1px solid black !important;
  }
  .level-module .levels .level:first-child {
    border-bottom: 1px solid #cbcbcb;
  }
  .v-modal-title {
    font-size: 28px;
  }
  .trx-rule-details-info-view {
    flex-direction: column;
  }
  .trx-rule-details-info-view > *:nth-child(1) {
    padding: 0 0 10px 0;
  }
  .trx-rule-details-info-view > *:nth-child(3) {
    padding: 10px 0 0 0;
  }
  .trx-rule-details-info-row {
    border-right: none;
    border-bottom: 1px solid #e6e7e8;
    padding: 10px 0;
  }
  .governance-layer-table.transaction-policy .icon-wrapper {
    display: none;
  }
}
