.transaction-requests-page .table-data.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.transaction-history-page .table-data.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.trx-requests-table-wrapper {
  height: calc(100vh - 343px);
  width: 100%;
}
.trx-requests-table-wrapper.trial-banner-visible {
  height: calc(100vh - 528px);
}
.trx-requests-table-wrapper.drafts {
  height: calc(100vh - 445px);
  width: 100%;
}
.trx-requests-table-wrapper.drafts.trial-banner-visible {
  height: calc(100vh - 630px);
}
.trx-history-table-wrapper {
  height: calc(100vh - 340px);
  width: 100%;
}
.trx-history-table-wrapper.trial-banner-visible {
  height: calc(100vh - 525px);
}
.transaction-requests-page .transaction-requests-page .wallet-info-table {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  color: #23262e;
}
.transaction-history-page .transaction-history-page .wallet-info-table {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  color: #23262e;
}
.transaction-requests-page .table-data.wallet .wallet-info-table .address-sender {
  font-size: 12px;
  font-family: 'Gilroy-SemiBold';
  color: #666d7f;
  line-height: 15px;
}
.transaction-history-page .table-data.wallet .wallet-info-table .address-sender {
  font-size: 12px;
  font-family: 'Gilroy-SemiBold';
  color: #666d7f;
  line-height: 15px;
}
.icon-transaction {
  display: flex;
}
.trx-table .no-active {
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f3f3f3;
}
.transaction-requests-page .table-data.wallet .wallet-info-table .name-wallet {
  line-height: 15px;
}
.transaction-history-page .table-data.wallet .wallet-info-table .name-wallet {
  line-height: 15px;
}
.transaction-requests-page .wallet .wallet-icon {
  width: 28px;
  height: 28px;
  min-width: 28px;
  display: flex;
  font-size: 11px;
}
.transaction-history-page .wallet .wallet-icon {
  width: 28px;
  height: 28px;
  min-width: 28px;
  display: flex;
  font-size: 11px;
}
.transaction-requests-page .token {
  display: flex;
  gap: 3px;
}
.transaction-history-page .token {
  display: flex;
  gap: 3px;
}
.transaction-requests-page .token img {
  width: 18px;
  height: 18px;
}
.transaction-history-page .token img {
  width: 18px;
  height: 18px;
}
.transaction-requests-page .amountUsd {
  font-family: 'Gilroy-Medium';
  font-size: 13px;
  line-height: 15px;
  color: #666d7f;
}
.transaction-history-page .amountUsd {
  font-family: 'Gilroy-Medium';
  font-size: 13px;
  line-height: 15px;
  color: #666d7f;
}
.transaction-requests-page .transactions-table .date {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  gap: 10px;
}
.transaction-history-page .transactions-table .date {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  gap: 10px;
}
.timestamp .icon {
  width: 28px;
  height: 28px;
  background: rgba(247, 65, 81, 0.1);
  border-radius: 5px;
  font-size: 20px;
  color: #f74151;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blochain-scanner-logo {
  width: 100px;
  max-height: 22px;
}
.timestamp .icon-receive.icon {
  width: 28px;
  height: 28px;
  background: #33bf641a;
  border-radius: 5px;
  color: #33bf64;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timestamp .even.icon {
  background-color: #008de41a;
  color: #2c3ee2;
}
.timestamp .icon-send.icon,
.timestamp .icon-receive {
  position: relative;
}
.team-page.transaction-requests-page .cancel-button {
  padding: 0px 15px;
  text-wrap: nowrap;
}
.transaction-requests-page .table-row.caption {
  padding-bottom: 10px;
}
.transaction-history-page .table-row.caption {
  padding-bottom: 10px;
}
/* .governance-layer-table .table-data .level.amount, .governance-layer-table .table-row .level.amount{
    width: 100%;
} */
.table-data:not(:first-child) {
  padding-left: 10px;
}
.table-data.timestamp {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 10px;
  max-width: 20%;
  flex-basis: 20%;
  flex-shrink: 0;
}

.table-data.wallet {
  max-width: 20%;
  flex-basis: 20%;
  flex-shrink: 0;
}
.table-data.level.amount {
  max-width: 15%;
  flex-basis: 15%;
  flex-shrink: 0;
  flex-basis: 15%;
}
.table-data.trx-status {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 10px;
  flex-basis: 15%;
  flex-shrink: 0;
}
.table-data.level,
.table-data.level.amount {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 10px;
}
.table-data.settings {
  flex-basis: 10%;
  flex-shrink: 0;
}
.table-data.settings.block-chain {
  flex-basis: 10%;
  display: flex;
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0 !important;
}
.table-data.settings.block-chain .button-holder {
  justify-content: flex-start !important;
}
.table-data {
  width: unset !important;
  flex-wrap: nowrap;
}
.wallet-cell {
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 150px;
}
.address-sender {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 125px;
  overflow: hidden;
}
.name-wallet {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 106px;
  overflow: hidden;
}
.amount-text-sub {
  color: #555d67;
}
.amount-text {
  width: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.token-image,
.status-image {
  width: 28px;
  height: 28px;
}
.draft-image {
  width: 28px;
  height: 28px;
  background: #e6e7e8;
  border-radius: 50%;
  padding: 7px;
}
.amount-info {
  display: flex;
}
.team-page .button-holder .button.create-trasaction-button {
  margin-right: 50px;
  max-width: 293px;
  width: 100%;
  display: flex;
  gap: 10px;
}

/* Modal Create */
.modal-transaction-request .v-modal-content {
  max-width: 900px;
}
.modal-transaction-request .v-modal-content .error {
  color: #f74151;
  position: absolute;
  bottom: -30px;
  white-space: nowrap;
}
.modal-transaction-request .select-value {
  display: flex;
  gap: 5px;
}
.modal-transaction-request .icon-protocol {
  width: 18px;
  height: 18px;
}
.modal-transaction-request .two-select-group,
.modal-transaction-request .inputs-group {
  display: flex;
  gap: 20px;
  align-items: baseline;
}
.modal-transaction-request .two-select-group .select-group-input,
.modal-transaction-request .two-select-group .v-input-group {
  width: 50%;
  position: relative;
  user-select: none;
}
.modal-transaction-request .two-select-group.enter-amount .v-input-group.address-enter {
  width: 79%;
}
.modal-transaction-request .v-input-group {
  margin-top: 0;
  width: 100%;
}
.modal-transaction-request .v-input-group input {
  height: 64px;
  margin-top: 0;
}
.modal-transaction-request .two-select-group .select-group-input.inactive {
  pointer-events: none;
  color: rgba(35, 38, 46, 0.3);
}
.modal-transaction-request .two-select-group .select-group-input.inactive .select-custom,
.modal-transaction-request .two-select-group .select-group-input.inactive .select-custom .select-value {
  background-color: inherit;
  color: rgba(35, 38, 46, 0.3);
}
.modal-transaction-request .two-select-group .select-custom .select-menu {
  max-height: 300px;
  overflow: auto;
}
.modal-transaction-request .two-select-group .select-custom .select-menu .select-value:hover {
  background: rgba(35, 38, 46, 0.05);
}
.modal-transaction-request .two-select-group label {
  font-size: 16px;
  font-family: 'Gilroy-SemiBold';
  margin-bottom: 10px;
}
.select-group-input .error {
  position: absolute;
  bottom: -21px;
  left: 5px;
  font-size: 12px;
  font-family: 'Gilroy-Medium';
  color: #f74151;
}

.select-custom.small-font .select-value {
  font-size: 12px;
}
.modal-transaction-request .v-input-group.amount {
  position: relative;
}
.modal-transaction-request .v-input-group .amount-input {
  position: relative;
}
.modal-transaction-request .v-input-group .amount-input input {
  padding-left: 50px;
  padding-right: 70px;
}
.modal-transaction-request .v-input-group .icon-netwrok {
  position: absolute;
  top: 18px;
  left: 20px;
  width: 20px;
  height: 20px;
}
.modal-transaction-request .v-input-group .icon-netwrok img {
  width: 100%;
  height: 100%;
}
.modal-transaction-request .fiat-currency {
  position: absolute;
  top: 23px;
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  right: 20px;
  color: #666d7f;
}
.team-page .modal-transaction-request .button-holder .button {
  max-width: 100%;
}
.min-max {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666d7f;
  font-family: 'Gilroy-Medium';
  margin-top: 2px;
  position: absolute;
  width: 100%;
}
.min-max div {
  width: 50%;
}
.min-max .max,
.min-max .min {
  cursor: pointer;
}
.min-max .max {
  justify-content: flex-end;
  display: flex;
  white-space: nowrap;
}
.currency {
  font-family: 'Gilroy-Medium';
  font-size: 12px;
  line-height: 14px;
  color: rgba(35, 38, 46, 0.3);
}
.protocols .select-value {
  display: flex;
  align-items: center;
}

.modal-transaction-request .button.black {
  align-items: center;
  display: flex;
  align-self: flex-end;
  margin-top: 5px;
  height: 64px;
}
.two-select-group ~ .v-input-group {
  margin-top: 30px;
}
.input-addresses {
  margin-top: 40px;
}
.address-uxto {
  display: flex;
  background-color: #2ce28b26;
  border-radius: 40px;
  padding: 10px 20px 10px 30px;
}
.address-uxto ~ .address-uxto {
  margin-top: 10px;
}
.address-uxto .address-mount div {
  height: 22px;
}
.address-uxto .address,
.address-uxto .address-mount {
  width: 100%;
}
.address-uxto .address-mount {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Gilroy-SemiBold';
}
.address-uxto img {
  width: 18px;
  height: 18px;
}
.address-uxto .icon-remove {
  width: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.address-uxto .icon-remove::before {
  font-size: 20px;
}
/* Details Modal */
.details-transaction-modal .v-modal-content {
  min-height: 730px;
}

.details-transaction-modal .transaction-info {
  display: flex;
  margin-top: 10px;
}

.details-transaction-modal .transaction-info .row-transaction-info {
  display: flex;
  flex-direction: column;
}

.details-transaction-modal .transaction-info .id,
.details-transaction-modal .transaction-info .type {
  display: flex;
  width: 50%;
  gap: 20px;
}

.details-transaction-modal .transaction-info .id ~ .type {
  justify-content: flex-end;
}

.details-transaction-modal .label {
  font-size: 14px;
  font-family: 'Gilroy-Medium';
  color: #666d7f;
  white-space: nowrap;
}

.details-transaction-modal .transaction-info .row-transaction-info .input {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
}

.details-transaction-modal .transaction-info .row-transaction-info .info {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  min-height: 30px;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  gap: 5px;
}

.details-transaction-modal .amount {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #efefef;
  padding: 20px 0;
  margin-top: 10px;
}

.details-transaction-modal .amount .value-data {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
}

.details-transaction-modal .amount .value-data span {
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  color: #23262e;
}

.details-transaction-modal .amount .value-data .caption,
.details-transaction-modal .amount .value-data .value {
  display: flex;
  align-items: center;
  width: 40%;
  color: #23262e;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
}

.details-transaction-modal .amount .value-data .value {
  justify-content: flex-end;
  width: 100%;
  gap: 4px;
  flex-direction: column;
}

.details-transaction-modal .amount .value-data .value .save {
  gap: 5px;
}

.details-transaction-modal .amount .value-data .value .save,
.details-transaction-modal .amount .value-data .fiat {
  display: flex;
  align-items: center;
  align-self: flex-end;
}

.details-transaction-modal .amount .value-data .fiat {
  font-size: 13px;
  font-family: 'Gilroy-Medium';
  color: #666d7f;
}

.details-transaction-modal .amount .value-data .value img {
  width: 20px;
  height: 20px;
}

.details-transaction-modal .amount .value-data .value .blockchain-secondary-symbol-icon {
  width: 10px;
  height: 10px;
}

.details-transaction-modal .from-to {
  display: flex;
}

.details-transaction-modal .from-to ~ .from-to {
  margin-top: 20px;
}
.details-transaction-modal .from-to-section .note {
  word-wrap: break-word;
}
.details-transaction-modal .from-to-section {
  width: 50%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  word-wrap: break-word;
}

.details-transaction-modal.v-modal .approves {
  margin-top: 10px;
  padding-top: 0;
  padding-bottom: 0;
}

.details-transaction-modal .address-sender-block {
  max-height: 100px;
  overflow-x: hidden;
}

.v-modal-content .rules.no-rules {
  display: flex;
}

.v-modal-content .rules.no-rules div {
  display: flex;
  width: 50%;
}

.v-modal-content .rules.no-rules .no-rules-title {
  color: #f74151;
  justify-content: flex-end;
}

.v-modal-content .rules .title-rule {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
}

.v-modal-content .rules .level .values {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.v-modal-content .rules .level .status-approver {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Gilroy-Medium';
}
.approvers-empty-wrapper {
  border: 1px solid #efefef;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-icon {
  width: 20px;
  height: 20px;
  gap: 9px;
  display: inline-flex;
  border-radius: 10px;
  justify-content: center;
}

.status-icon.approved {
  background: rgba(51, 191, 100, 0.1);
}

.status-icon.pending {
  background: rgba(247, 147, 26, 0.05);
}
.status-icon.warning {
  background: #f7931a1a;
}

.status-icon.rejected {
  background: rgba(247, 65, 80, 0.05);
}
.status-icon.broadcast {
  background: #4b73ff1a;
}

.status-icon::after {
  font-family: 'fontello';
  display: flex;
  align-items: center;
  justify-content: center;
}
.status-icon.broadcast::after {
  content: '\e83a';
  color: #4b73ff;
}
.status-icon.warning::after {
  content: '\e876';
  color: #f7931a;
  font-size: 9px;
}
.status-icon.approved::after {
  content: '\e810';
  color: #33bf64;
}

.status-icon.pending::after {
  content: '\e823';
  color: #f7931a;
}

.status-icon.rejected::after {
  content: '\e811';
  color: #f74151;
}

.address-sender-block-copy-button {
  overflow: visible !important;
}

.transaction-request-id {
  margin-right: 8px;
}

.details-transaction-modal .from-to-section .label {
  margin-top: 0;
}

.details-transaction-modal .wallet-info {
  margin-top: 10px;
}

.details-transaction-modal .blockexplorer-button {
  max-width: 100%;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}
.modal-transaction-request .v-input-group.amount.inactive ::placeholder,
.modal-transaction-request .v-input-group.amount.inactive .min-max,
.modal-transaction-request .v-input-group.amount.inactive .fiat-currency {
  color: rgba(35, 38, 46, 0.3);
}

.modal-transaction-request .select-value {
  font-size: 14px;
}

.modal-transaction-request .account-base .button-holder {
  margin-top: 40px;
}

.loading-spin-assets {
  position: absolute;
  top: 0;
  left: 44px;
}

.v-modal.modal-transaction-request.susess-modal .v-modal-content {
  width: 640px;
  display: flex;
  text-align: center;
}

.v-modal.modal-transaction-request.susess-modal .v-modal-content .title-approve {
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  color: #666d7f;
  margin-top: 40px;
}

.v-modal.modal-transaction-request.susess-modal .v-modal-content .rules .title-rule {
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  margin-top: 40px;
}

.v-modal.modal-transaction-request.susess-modal .v-modal-content .approves {
  margin-top: 0;
  text-align: left;
}
.select-custom.select-menu-holder.inactive.wallets {
  color: rgba(35, 38, 46, 0.3);
  pointer-events: none;
}
.global-error {
  position: relative;
  background: #f74151;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Gilroy-Medium';
  color: #fff;
}

.v-modal.details-transaction-modal .tronscan {
  width: 100px;
}
.select-value.address-tr.hide {
  flex-direction: column;
  align-items: start;
}

.select-value.address-tr.hide .balance-coin {
  position: relative;
  right: 0;
}

.governance-layer-table .table-data.address {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.detail-address {
  display: flex;
  align-items: center;
}

.governance-layer-table.transactions-table.hisroty-table .settings {
  width: 35%;
}

.button-filters-wrapper {
  display: flex;
  padding: 0 50px 0 0;
}

.button-filters-wrapper .filters {
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
}
.filters {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}
.filters .select-wrapper ~ .select-wrapper {
  margin-left: 30px;
}

.transaction-requests-page .button-holder ~ .filters {
  margin-top: 30px;
}
.transaction-history-page .button-holder ~ .filters {
  margin-top: 30px;
}

.hidden {
  display: none !important;
}

.spinner-history {
  width: 100%;
  margin-top: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters {
  gap: 20px;
}

.trx-row-symbol-wrapper {
  position: relative;
}

.trx-row-secondary-symbol-icon {
  position: absolute;
  top: 0;
  border-radius: 50%;
  transform: translate(20%, -20%);
  border: 1px solid #000;
  right: 0;
  width: 14px;
  height: 14px;
  background: #e7e7e7;
}

.trx-details-level-approver {
  font-family: 'Gilroy-SemiBold';
}
.trx-details-modal-recipients-column {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100px;
  gap: 10px;
}
.trx-details-modal-recipients-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.wallet-cell-trx-address-to {
  display: flex;
  flex-basis: 60%;
  gap: 10px;
  align-items: center;
  min-height: 40px;
}
.wallet-cell-trx-wallet-name-to {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: center;
}
.wallet-icon.trx-details {
  display: flex;
  min-width: 28px;
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0;
}
.trx-details-fail-reason-wrapper {
  margin: 20px 0;
}
.address-sender-trx-details-to {
  font-size: 12px;
  font-family: 'Gilroy-SemiBold';
  color: #666d7f;
  line-height: 15px;
}
.trx-row-add-contact {
  margin-right: 1px;
}
.trx-row-add-contact path {
  fill: #000;
}
.trx-row-add-contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  min-height: 28px;
  background: #2ce28b;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}
.trx-row-add-contact-wrapper:hover {
  background: #28d07f;
}
.contact-or-one-time-address-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-or-one-time-address {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  padding-bottom: 10px;
}
.contact-or-one-time-address-text {
  cursor: pointer;
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
  color: #000;
}
.contact-or-one-time-address-text.selected {
  cursor: pointer;
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
  padding: 8px 15px 8px 15px;
  border-radius: 24px;
  background: #000;
  color: #fff;
}
.create-trx-request-modal-filter-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 15px;
}
.create-trx-request-modal-filter-label-sub {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 15px;
  color: #555d67;
}
.create-trx-modal-label {
  margin-bottom: 10px;
}
.trx-requests-tabs {
  display: flex;
  width: 100%;
  color: rgba(2, 13, 28, 0.5);
  font-family: 'Gilroy-Bold';
  padding: 0 50px;
  margin-bottom: 40px;
}

.trx-requests-tabs .trx-requests-tab {
  cursor: pointer;
}
.trx-requests-tabs .trx-requests-tab.hide,
.trx-requests-tabs .trx-requests-tab.hide:hover {
  color: #fff;
  cursor: default;
  width: 5px;
}
.trx-requests-tabs .trx-requests-tab:hover {
  color: #020d1c;
}

.trx-requests-tabs .trx-requests-tab.active {
  color: #020d1c;
  position: relative;
}

.trx-requests-tabs .trx-requests-tab::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
}

.trx-requests-tabs .trx-requests-tab ~ .trx-requests-tab {
  margin-left: 20px;
}
.trx-requests-tab.selected {
  color: #020d1c;
  position: relative;
}
.trx-requests-tab.selected::after {
  border: 1px solid #020d1c;
}
.trx-row-settings-delete-icon {
  background: #e6e7e8;
  min-width: 28px;
  min-height: 28px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
}
.trx-row-settings-delete-icon:hover {
  background: rgba(35, 38, 46, 0.1);
  cursor: pointer;
}
.draft-delete-icon-wrapper,
.transaction-requests-page-draft-selected {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: nowrap;
}
.transaction-requests-page-draft-selected span {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.transaction-requests-page-draft-buttons-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.draft-options-filters-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px 50px;
}
.draft-trx-row-select-label {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
}
.transaction-requests-page-draft-csv-button {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  cursor: pointer;
}
.transaction-requests-page-draft-select-all-button-label {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.transaction-requests-page-draft-selected-count {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.v-modal-content .trx-draft-details-row {
  width: 100%;
}
.convert-drafts-submit-button {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin-bottom: 20px;
}
.trx-requests-no-permission-label {
  text-align: center;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
}
.trx-req-button-filters-wrapper {
  justify-content: space-between;
  display: flex;
  padding: 0 50px 0 50px;
  margin-top: 40px;
}
.trx-req-button-filters-wrapper .trx-req-filters {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  gap: 20px;
}
.tr-row-date {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
.trx-requests-small-screen-draft-button {
  display: none;
}
.trx-requests-small-screen-trx-button {
  display: none;
}
.trx-requests-large-screen-draft-button {
  display: block;
}
.trx-requests-large-screen-trx-button {
  display: block;
}
.trx-table {
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: 40px;
}
.trx-table-header {
  padding: 0 60px 10px 50px !important;
}
.cancel-button.trx-mobile-select-draft {
  display: none;
}
.button-holder.trx-mobile-delete {
  display: none;
}
.transaction-mobile-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}
.transaction-mobile-flex-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.transaction-requests-page-draft-selected .button-holder.submit-drafts-mobile {
  display: none;
}
.trx-req-drawer {
  display: none;
}
.trx-req-drawer-trigger-button {
  display: none;
}
.mobile-createdat-trx-date {
  display: none;
}
.filters-button-mobile {
  display: none;
}
.trx-history-filters {
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
  gap: 20px;
}
.trx-window-list {
  padding: 20px 0;
}
.trx-history-filters-wrapper {
  margin: 40px 50px 40px 50px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.mobile-trx-filters-bar {
  display: none;
}
.trx-filters-bar {
  column-width: 150px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}
.reset-filters-button {
  text-decoration: underline;
  color: #353d49;
  font-family: 'Gilroy-Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-wrap: nowrap;
  cursor: pointer;
}
.trx-filters-bar-indicator {
  font-family: 'Gilroy-Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-wrap: nowrap;
  cursor: pointer;
}
.trx-filters-bar-indicator svg {
  transform: rotate(180deg);
}
.trx-filters-bar-modal-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
.trx-filters-bar-modal-row span:first-of-type {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}
.trx-filters-bar-modal .v-modal-content,
.trx-history-filters-wrapper .v-modal-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.trx-filters-bar-modal h1 {
  font-family: 'Gilroy-SemiBold';
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin-bottom: 40px;
}
.trx-req-filters-drawer-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.trx-req-filters-drawer-header svg path {
  stroke: #000;
}
.trx-filters-bar-modal-fields {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}
.trx-history-filters-export-modal-content {
  padding: 20px;
  border: 1px solid #e6e7e8;
  width: 450px;
  border-radius: 10px;
}
@media screen and (max-width: 1300px) {
  .trx-requests-table-wrapper {
    height: calc(100vh - 403px);
    width: 100%;
  }
  .trx-requests-table-wrapper.trial-banner-visible {
    height: calc(100vh - 588px);
  }
  .trx-req-button-filters-wrapper {
    flex-wrap: wrap;
  }
  .trx-req-button-filters-wrapper .trx-requests-large-screen-trx-button {
    width: 100%;
    margin-right: 55px;
  }
  .trx-req-button-filters-wrapper .trx-requests-large-screen-trx-button .button-holder {
    justify-content: flex-end;
  }
  .trx-req-button-filters-wrapper {
    gap: 20px;
  }
  .trx-requests-small-screen-trx-button {
    display: block;
  }
  .trx-requests-small-screen-draft-button {
    display: block;
  }
  .trx-requests-large-screen-draft-button {
    display: none;
  }
  .trx-requests-large-screen-trx-button {
    display: none;
  }
  .table-data.settings.block-chain {
    gap: 20px;
  }
}
@media screen and (max-width: 1265px) {
  .table-data.timestamp {
    max-width: 9%;
    flex-basis: 9%;
  }
  .tr-row-date {
    max-width: 55px;
  }
  .table-data.timestamp .checkbox-label-text {
    max-width: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .table-data.wallet {
    max-width: 24.5%;
    flex-basis: 24.5%;
  }
  .table-data.settings {
    flex-basis: 17% !important;
    flex-shrink: 0;
  }
  .table-data.settings .button-holder {
    width: 100px;
  }
  .trx-row-add-contact-wrapper {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .button-filters-wrapper {
    padding: 0 100px 0 0;
  }
  .table-data.trx-status.draft {
    flex-basis: 11%;
    max-width: 11%;
  }
  .table-data.wallet.draft {
    flex-basis: 24%;
    max-width: 24%;
  }
}
@media screen and (max-width: 950px) {
  .trx-req-button-filters-wrapper {
    flex-direction: column-reverse;
    padding: 0 20px;
    margin-top: 0px;
  }
  .trx-req-filters {
    display: none !important;
  }
  .trx-history-filters {
    display: none !important;
  }
  .trx-req-drawer {
    display: block;
  }
  .trx-req-drawer-trigger-button {
    display: block;
  }
  .trx-req-filters-drawer {
    flex-direction: column;
    margin-right: 0 !important;
    display: flex;
    gap: 20px;
    padding: 20px;
  }
  .trx-req-filters-drawer > * {
    width: 100%;
  }
  .trx-requests-large-screen-trx-button {
    margin-right: 0 !important;
  }
  .trx-requests-large-screen-trx-button button {
    width: 100%;
  }
  .transaction-requests-page .table-row.caption {
    display: none;
  }
  .transaction-history-page .table-row.caption {
    display: none;
  }
  .transaction-requests-page .button-holder {
    margin-top: 20px;
  }
  .transaction-history-page .button-holder {
    margin-top: 20px;
  }
  .filters-button-mobile {
    margin-top: 20px;
    padding: 0 20px;
  }
  .transaction-requests-page .trx-table {
    margin-top: 20px;
  }
  .transaction-history-page .trx-table {
    margin-top: 0px;
  }
  .transaction-requests-page .trx-req-button-filters-wrapper .drawer.bottom {
    height: 350px;
    overflow: visible;
  }
  .transaction-history-page .drawer.bottom {
    height: 100vh;
    height: 100dvh;
    overflow: auto;
  }
  .trx-req-filters-drawer .title {
    font-size: 22px;
    font-family: 'Gilroy-SemiBold';
    color: #000;
    line-height: 15px;
  }
  .trx-req-filters-drawer .filter-main-wrapper-new .checkboxes-new {
    max-width: unset;
    width: 100%;
  }
  .trx-req-filters-drawer .field-wrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    flex-direction: column;
  }
  .trx-req-filters-drawer .field-wrapper label {
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
  .transaction-requests-page .table-row.data {
    padding: 10px 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d5d5d5;
  }
  .transaction-history-page .table-row.data {
    padding: 10px 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d5d5d5;
  }
  .transaction-requests-page .table-row.data > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 8px 0;
  }
  .transaction-history-page .table-row.data > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 8px 0;
  }
  .transaction-mobile-flex-buttons .button-holder {
    margin-top: 0;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-basis: 50%;
    max-width: 50%;
    margin: 10px 0;
  }
  .transaction-mobile-flex-buttons .button-holder.fullWidth {
    margin-top: 0;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-basis: 100%;
    max-width: 100%;
    margin: 10px 0;
  }
  .transaction-mobile-flex-buttons .button-holder > * {
    width: 100%;
  }
  .transaction-mobile-flex-buttons .trx-row-settings-delete-icon {
    display: none;
  }
  .table-data.settings.block-chain .button-holder,
  .table-data.settings.block-chain .button-holder .cancel-button {
    width: 100%;
    max-width: unset;
  }
  .amount-text,
  .name-wallet,
  .address-sender {
    width: unset;
  }
  .draft-options-filters-wrapper {
    padding: 20px 20px 0 20px;
  }
  .transaction-requests-page-draft-buttons-wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 20px;
  }
  .transaction-requests-page-draft-selected {
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  .trx-requests-small-screen-draft-button .button-holder button,
  .transaction-requests-page-draft-selected .button-holder button,
  .trx-requests-small-screen-draft-button,
  .trx-requests-small-screen-trx-button .button-holder button,
  .transaction-requests-page-trx-selected .button-holder button,
  .trx-requests-small-screen-trx-button {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .trx-requests-small-screen-trx-button {
    padding: 0 20px;
  }
  .transaction-requests-page-draft-selected .button-holder .button-component-main .icon-submit-for-approval {
    font-size: 16px;
  }
  .trx-requests-small-screen-draft-button .button-holder,
  .transaction-requests-page-draft-selected .button-holder {
    margin-top: 0;
  }
  .transaction-requests-page-draft-selected-count {
    display: none;
  }
  .checkbox-label-text {
    max-width: unset !important;
  }
  .mobile-createdat-trx-date {
    display: block;
  }
  .table-data.date.timestamp .checkbox-group-new {
    display: none;
  }
  .table-data.settings.block-chain {
    flex-direction: column;
    gap: 0px !important;
  }
  .team-page.transaction-requests-page .cancel-button.trx-mobile-select-draft {
    padding: 8.5px 15px;
    text-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .team-page.transaction-requests-page .cancel-button.trx-mobile-select-draft.selected {
    background-color: var(--project-green);
  }
  .trx-requests-table-wrapper.drafts {
    height: calc(100vh - 438px);
    height: calc(100dvh - 438px);
  }
  .trx-table {
    padding-bottom: 0px !important;
  }
  .trx-requests-table-wrapper {
    height: calc(100vh - 370px);
    height: calc(100dvh - 370px);
  }
  .trx-requests-table-wrapper.trial-banner-visible {
    height: calc(100vh - 528px);
    height: calc(100dvh - 528px);
  }
  .trx-requests-table-wrapper.drafts.trial-banner-visible {
    height: calc(100vh - 596px);
    height: calc(100dvh - 596px);
  }
  .filters-button-mobile {
    display: block;
  }
  .cancel-button.trx-mobile-select-draft {
    display: flex;
  }
  .button-holder.trx-mobile-delete {
    display: flex;
  }
  .trx-history-table-wrapper {
    height: calc(100vh - 205px);
    height: calc(100dvh - 205px);
    width: 100%;
  }
  .trx-history-table-wrapper.trial-banner-visible {
    height: calc(100vh - 363px);
    height: calc(100dvh - 363px);
  }
  .trx-history-table-wrapper.filter-bar-visible {
    height: calc(100vh - 283px);
    height: calc(100dvh - 283px);
    width: 100%;
  }
  .trx-history-table-wrapper.filter-bar-visible.trial-banner-visible {
    height: calc(100vh - 441px);
    height: calc(100dvh - 441px);
  }
  .transaction-history-page .filters-button-mobile {
    width: 100%;
    margin-top: 0;
  }
  .transaction-history-page .button-filters-wrapper {
    padding: 0 !important;
  }
  .wallet-detail-table.team-page .asset-table-wrapper {
    display: none;
  }
  .wallet-detail-table.team-page .asset-title-accordion-details > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .wallet-detail-table.team-page .wallet-button-filters-wrapper {
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    padding: 0 20px;
  }
  .wallet-detail-table.team-page .wallet-button-filters-wrapper .search-bar {
    width: 100%;
    margin: 0;
  }
  .wallet-detail-table.team-page .wallet-button-filters-wrapper .search-bar .filter-main-wrapper-new {
    width: 100%;
  }
  .wallet-detail-table.team-page
    .wallet-button-filters-wrapper
    .search-bar
    .filter-main-wrapper-new
    .checkboxes-new {
    max-width: unset;
    width: 100%;
  }
  .wallet-detail-table.team-page .wallet-addresses-tablee-header {
    display: none;
  }
  .details-transaction-modal .transaction-info .type {
    flex-direction: column;
    width: 100%;
  }
  .trx-details-modal-recipients-row {
    gap: 10px;
    justify-content: flex-end;
  }
  .v-modal.details-transaction-modal .v-modal-content {
    padding: 20px;
    overflow-x: hidden;
  }
  .details-transaction-modal .from-to ~ .from-to {
    margin: 20px 0;
  }
  .details-transaction-modal .levels .level:first-child::after {
    display: none;
  }
  .details-transaction-modal .levels .level:first-child {
    border-bottom: 1px solid #efefef;
  }
  .details-transaction-modal .levels {
    flex-direction: column;
  }
  .v-modal .approves .level {
    flex-basis: 100% !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
  .details-transaction-modal .levels .level1::after {
    display: none !important;
    border-bottom: 1px solid black !important;
  }
  .v-modal .approves .level2 {
    border-left: 1px solid #efefef !important;
  }
  .details-transaction-modal .from-to {
    flex-direction: column;
    gap: 20px;
  }
  .details-transaction-modal .transaction-info .row-transaction-info,
  .details-transaction-modal .from-to-section {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  .details-transaction-modal .transaction-info {
    width: 100%;
    margin-top: 30px;
    flex-direction: column;
  }
  .details-transaction-modal .transaction-info .label {
    text-wrap: nowrap;
  }
  .details-transaction-modal .transaction-info .input {
    gap: 10px;
  }
  .details-transaction-modal .transaction-info .id {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  .details-transaction-modal .amount {
    border: none !important;
    margin: 20px 0;
    padding: 0;
  }
  .details-transaction-modal .wallet-info {
    margin-top: 0;
  }
  .transaction-requests-page-draft-selected .button-holder.submit-drafts {
    display: none;
  }
  .transaction-requests-page-draft-selected .button-holder.submit-drafts-mobile {
    display: block;
  }
  .transaction-requests-page-draft-selected.selected-drafts {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .wallet-cell-trx-address-to.trx-details {
    flex-basis: unset;
  }
  .trx-details-modal-recipients-row {
    min-height: 32px;
  }
  .tr-row-date {
    max-width: unset;
  }
  .transaction-history-page .table-data.wallet .wallet-info-table .name-wallet {
    max-width: 120px;
  }
  .transaction-requests-page .table-data.wallet .wallet-info-table .name-wallet {
    max-width: 120px;
  }
  .trx-history-filters-wrapper {
    display: none;
  }
  .mobile-trx-filters-bar {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 20px;
  }
}
