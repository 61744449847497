.team-page .tabs .tab ~ .tab {
  margin-left: 30px;
}
.team-page .button-holder .button {
  max-width: 235px;
  width: 100%;
}
.team-page .button-holder i {
  font-size: 26px;
}
.team-page .governance-layer-table .name-icon::before {
  display: none;
}
.team-page .icon-wrapper {
  width: 28px;
  height: 28px;
  border: 1px solid #e6e7e8;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}
.team-page .status-bar {
  display: flex;
}
.status-bar .status-info-text {
  margin-left: 10px;
}
.team-page .status-bar .status-name {
  margin-left: 10px;
}
.cancel-button i {
  font-size: 24px;
  color: #23262e;
}
.icon-add-a-new-wallet {
  display: flex;
}
.team-page.pending .settings {
  width: 50%;
}
.cancel-button {
  max-width: 164px;
  width: 100%;
  background: rgba(35, 38, 46, 0.05);
  display: flex;
  color: #23262e;
  height: 38px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px 10px;
  gap: 5px;
  border-radius: 10px;
}
.cancel-button:hover {
  background: rgba(35, 38, 46, 0.1);
  cursor: pointer;
}
.cancel-button i {
  font-size: 24px;
}
.action-menu-item.remove {
  color: #f74151;
}
.invite-modal .v-modal-content {
  min-width: 880px;
  padding: 0;
  width: 100%;
}
.invite-information {
  display: flex;
}
.invite-information .user-input,
.invite-information .info {
  width: 50%;
  padding: 30px;
}
.invite-information .user-input .checkbox-group {
  padding-left: 20px;
}
.invite-information .user-input .checkmark {
  left: 0;
}
.checkbox-group.checked {
  background-color: inherit;
}
.invite-information .info {
  border-left: 1px solid #f3f3f3;
}
.invite-information .button-holder .add-button {
  max-width: 100%;
  height: 60px;
}
.checkox-group-invite {
  margin-top: 30px;
}

.suceess-modal,
.remove-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.suceess-modal .modal-title {
  font-size: 18px;
  font-family: 'Gilroy-Medium';
  color: #666d7f;
  margin-top: 33px;
}
.suceess-modal .email-modal {
  font-size: 24px;
  font-family: 'Gilroy-Medium';
  color: #020d1c;
  margin-top: 20px;
}
.v-modal-content .close-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  gap: 10px;
  font-family: 'Gilroy-Bold';
  width: 440px;
  height: 60px;
  margin-top: 10px;
  background: rgba(35, 38, 46, 0.05);
  border-radius: 10px;
}
.v-modal-content .close-button:hover {
  background: rgba(35, 38, 46, 0.1);
  cursor: pointer;
}
.edit-mode .name {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
}
.edit-mode .email {
  font-family: 'Gilroy-Medium';
  font-size: 16px;
}
.remove-modal .icon-title.red {
  font-family: 'Gilroy-SemiBold';
  color: #f74151;
  text-align: center;
  font-size: 18px;
}
.remove-modal .button-holder {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 440px;
  height: 60px;

  border-radius: 10px;
}
.remove-modal .button-holder .button {
  max-width: 100%;
}
.remove-modal .modal-name {
  margin-top: 30px;
  font-family: 'Gilroy-SemiBold';
  font-size: 20px;
  color: #23262e;
  word-break: break-all;
}
.info .title {
  font-family: 'Gilroy-SemiBold';
  font-size: 20px;
}
.info .desc {
  font-family: 'Gilroy-Medium';
  font-size: 13px;
  line-height: 150%;
}
.remove-modal .email-modal {
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  line-height: 16px;
  color: #666d7f;
}
.button.button-red {
  background-color: #f74151;
  color: #fff;
}
.button.button-red:hover {
  background-color: #dc3241;
}
.governance-layer-table .no-active {
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f3f3f3;
}

.invite-modal .value-checkbox div,
.edit-mode .value-checkbox div {
  text-transform: capitalize;
}

.table-data.roles {
  text-transform: capitalize;
}

.team-page .table .governance-layer-table .table-content {
  padding-bottom: 40px;
}
.v-modal.mini-modal .v-modal-content {
  max-width: 520px;
}

.margin-right-50 {
  margin-right: 50px;
}

.team-table-data:not(:first-child) {
  padding-left: 10px;
}
.team-table-data.name {
  flex-basis: 30%;
}
.team-table-data.role {
  flex-basis: 10%;
}
.team-table-data.date {
  flex-basis: 20%;
}
.team-table-data.date,
.team-table-data.status {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 10px;
}
.team-table-data.status {
  flex-basis: 15%;
}
.team-table-data.settings {
  flex-basis: 5%;
  flex-shrink: 0;
}
.team-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.status-bar {
  display: flex;
  align-items: center;
}
.status-bar-icon {
  width: 28px;
  height: 28px;
}
.pending-table-data:not(:first-child) {
  padding-left: 10px;
}
.pending-table-data.name {
  flex-basis: 30%;
}
.pending-table-data.role {
  flex-basis: 15%;
}
.pending-table-data.date {
  flex-basis: 20%;
}
.pending-table-data.date,
.team-table-data.status {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 10px;
}
.pending-table-data.status {
  flex-basis: 20%;
}
.pending-table-data.settings {
  flex-basis: 15%;
}
.pending-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.name-team-page,
.creator-team-page {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
}
.creator-team-page {
  color: #555d67;
}
.team-cancel-button {
  margin-bottom: 10px;
  width: 100%;
}
.remove-member-key-info-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid #e6e7e8;
}

.remove-member-key-info-row:last-child {
  border: none;
}

.remove-member-vaults-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.remove-member-key-info-wrapper {
  border: 1px solid #e6e7e8;
  padding: 0 20px;
  border-radius: 10px;
  width: 450px;
  display: flex;
  flex-direction: column;
}

.remove-member-key-info-sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  word-break: break-all;
}

.remove-member-key-info-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}
.team-button-filters-wrapper {
  display: flex;
  padding: 0 50px 0 0;
}
.table.governance-layer-table.team-table {
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
}
.table.governance-layer-table.team-table.trial-banner-visible {
  height: calc(100vh - 485px);
}
.loading-full-height-team {
  height: calc(100% - 40px);
}
@media screen and (max-width: 950px) {
  .team-page.active .header-table,
  .team-page.pending .header-table {
    padding: 0 20px;
    margin: 10px 0 20px 0;
  }
  .team-button-filters-wrapper {
    width: 100%;
    padding: 0;
  }
  .team-button-filters-wrapper .button-holder {
    width: 100%;
    margin: 0 !important;
    padding: 0 20px;
  }
  .team-button-filters-wrapper .button-holder button {
    width: 100%;
  }
  .table.governance-layer-table.team-table .table-row.caption {
    display: none;
  }
  .table.governance-layer-table.team-table .table-row.data {
    padding: 10px 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d5d5d5;
    display: block;
  }
  .table.governance-layer-table.team-table .table-row.data .actions.icon-dots {
    width: 32px;
    height: 32px;
    font-size: 15px;
  }
  .table.governance-layer-table.team-table .table-row.data > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .name-team-page,
  .creator-team-page {
    width: unset;
    max-width: 180px;
    text-align: end;
  }
  .team-page.pending .name-right .name {
    width: unset;
    max-width: 180px;
    text-align: end;
  }
  .table.governance-layer-table.team-table {
    padding-bottom: 0;
    height: calc(100vh - 265px) !important;
    height: calc(100dvh - 265px) !important;
    margin-bottom: 0 !important;
  }
  .table.governance-layer-table.team-table.trial-banner-visible {
    height: calc(100vh - 445px) !important;
    height: calc(100dvh - 445px) !important;
  }
  .pending-table-data.settings > * {
    width: 100%;
  }
  .remove-member-key-info-wrapper {
    width: 100%;
  }
  .remove-modal-title-wrapper {
    margin: 20px 0 0 0;
  }
  .loading-full-height-team {
    height: 100%;
  }
}
