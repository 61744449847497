.governance-layer-table.wallets-table .settings {
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
}

.wallet-detail-table.team-page .link {
  display: flex;
  padding: 20px 0;
  justify-content: flex-end;
  margin-right: 35px;
}
.table.wallets-table {
  overflow: hidden;
  height: calc(100vh - 260px);
  margin-top: 20px;
  width: 100%;
  padding-bottom: 38px;
}
.table.wallets-table.trial-banner-visible {
  overflow: hidden;
  height: calc(100vh - 443px);
  margin-top: 20px;
  width: 100%;
  padding-bottom: 38px;
}
.wallets-table .settings .cancel-button {
  max-width: 103px;
  height: 38px;
}
.table.governance-layer-table.wallets-rules-table {
  width: 100%;
  height: calc(100vh - 279px) !important;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.table.governance-layer-table.wallets-rules-table.trial-banner-visible {
  width: 100%;
  height: calc(100vh - 364px) !important;
}
.governance-layer-table.wallets-table .wallet-icon {
  width: 28px;
  height: 28px;
  font-family: 'Gilroy-Bold';
  margin-right: 0;
  position: relative;
}
.titles-box.id-wallet .titles-box-sub {
  display: flex;
  align-items: center;
  gap: 10px;
}
.governance-layer-table.wallets-table .name-right {
  font-size: 16px;
  font-family: 'Gilroy-Bold';
  width: 100%;
}

.governance-layer-table.wallets-table .wallet-settings-wrapper.name {
  width: 70%;
}

.wallets .search-bar {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.wallets .search-bar input {
  height: 60px;
}

.new-wallet-modal .v-modal-title {
  display: flex;
}

.new-wallet-modal .color-box {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  color: #fff;
  border: 0;
  margin-left: 10px;
  display: flex;
  font-size: 18px;
  font-family: 'Gilroy-Bold';
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.v-input-group label {
  font-size: 16px;
  font-family: 'Gilroy-SemiBold';
}

.v-input-group input {
  margin-top: 10px;
  position: relative;
  font-size: 16px;
  font-family: 'Gilroy-Medium';
}
.v-input-group ~ .v-input-group {
  margin-top: 20px;
}
.wallet-create-textfield-wrapper .textfield-component-main {
  height: 60px;
}
.color-panel {
  position: absolute;
  top: 44px;
  right: 20px;
}

.color-changer {
  width: 65px;
  height: 40px;
  border: 1px solid #cecece;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.color-changer .color-select {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.color-changer .down-select {
  color: #020d1c;
  font-size: 10px;
  margin-left: 10px;
}

.color-menu {
  z-index: 9999999;
  display: flex;
  padding: 14px;
  flex-direction: row;
  position: absolute;
  gap: 10px;
  width: 224px;
  height: 88px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  flex-wrap: wrap;
}

.color-menu.shrinked {
  z-index: 9999999;
  display: flex;
  padding: 14px;
  flex-direction: row;
  position: absolute;
  gap: 10px;
  width: 122px;
  height: 155px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  flex-wrap: wrap;
}

.color-menu .item {
  width: 24px;
  height: 24px;
  display: flex;
  flex: none;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
}

.color-menu .item.selected:after,
.color-menu .item:hover:after {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  border: 1px solid #d3d3d3;
  left: calc(50% - 30px / 2);
  top: calc(50% - 30px / 2);
  border-radius: 7px;
}

.color-menu .item.selected::before {
  content: '\e810';
  color: #fff;
  font-family: 'fontello';
  font-size: 24px;
  width: 30px;
  height: 30px;
  position: absolute;
  display: flex;
  align-items: center;
}

.team-page .new-wallet-modal .button-holder .button {
  max-width: 100%;
}

.team-page .new-wallet-success .v-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-page .new-wallet-success .wallet-icon {
  width: 66px;
  height: 66px;
  font-size: 30px;
  text-transform: capitalize;
}

.team-page .new-wallet-success .button-holder .button {
  max-width: 100%;
}

.new-wallet-success .v-modal-content {
  max-width: 520px;
}

.new-desposit-address-modal .v-modal-content {
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-billing-history {
  display: flex;
}

.add-button.black {
  background-color: #23262e;
  color: #fff;
}

.add-button.green {
  background-color: #2ce28b;
  color: #000;
}

.add-button.black:hover {
  background-color: #363942;
}

.new-wallet-success .button-holder ~ .button-holder {
  margin: 0;
}

.new-wallet-success .title {
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  color: #666d7f;
  margin-top: 30px;
}

.new-wallet-success .name {
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  margin-top: 10px;
  color: #020d1c;
}

.new-wallet-success .v-modal-content .close-button {
  margin-top: 10px;
}

.wallets-table .table-row.data:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.new-desposit-address-modal .v-input-group ~ .v-input-group {
  margin-top: 20px;
}

.team-page .new-desposit-address-modal .button-holder .button {
  max-width: 100%;
}

.new-desposit-address-modal .sussess-deposit-address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.new-desposit-address-modal .sussess-deposit-address .congrats {
  margin: 32px 0;
  color: #020d1c;
  font-family: 'Gilroy-Medium';
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: 0em;
}

.new-desposit-address-modal .sussess-deposit-address-image {
  width: 160px;
  height: 160px;
}

.new-desposit-address-modal .sussess-deposit-address .name-address {
  color: #020d1c;
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  margin-top: 10px;
}

.new-desposit-address-modal .sussess-deposit-address .info-block {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 20px;
}

.new-desposit-address-modal .sussess-deposit-address .info-block .info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.new-desposit-address-modal .sussess-deposit-address .info-block .info .select-value {
  padding: 0;
  display: flex;
  align-items: center;
}

.new-desposit-address-modal .sussess-deposit-address .info-block .address-holder {
  display: flex;
  align-items: center;
}

.new-desposit-address-modal .sussess-deposit-address .info-block .address-holder .generated-address-string {
  font-family: 'Gilroy-Medium';
  color: #020d1c;
  font-size: 16px;
  margin-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 30px);
}

.new-desposit-address-modal .sussess-deposit-address .info-block .title-holder .address-name {
  font-family: 'Gilroy-Medium';
  color: #020d1c;
  text-wrap: wrap;
  word-break: break-all;
}

.sussess-deposit-address .info-block .title-holder .generate-address-blockchain-icon {
  width: 40px;
  height: 40px;
}

.copy-button {
  padding: 0px 10px;
  gap: 10px;
  width: 30px;
  position: relative;
  height: 30px;
  background: rgba(2, 13, 28, 0.05);
  border-radius: 34px;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copy-button:hover {
  background: rgba(2, 13, 28, 0.1);
}
.tooltip.copy-tooltip {
  display: block;
  position: absolute;
  top: -30px;
  color: #fff;
  background: #050d1c;
  opacity: 1;
  font-size: 11px;
  border-radius: 10px;
  z-index: 999999999999;
}
.tooltip.copy-tooltip.hide {
  display: none;
}
.new-desposit-address-modal .sussess-deposit-address .info-block .title-holder {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 20px;
  font-family: 'Gilroy-Medium';
  font-size: 16px;
  line-height: 100%;
  color: #666d7f;
  margin: 10px 0;
}
.new-desposit-address-modal .sussess-deposit-address .info-block .title-holder:last-of-type {
  border: none;
  padding-bottom: 0;
}

.new-desposit-address-modal .select-value {
  display: flex;
  align-items: flex-start;
}

.new-desposit-address-modal.v-modal .button-holder .close-button {
  margin-top: 0;
}

.capitalize {
  text-transform: capitalize;
}

.wallet-detail-table .button-holder.generate {
  margin-top: 40px;
  padding-right: 50px;
}

.wallet-detail-table .button-holder.generate .button {
  max-width: 281px;
}

.wallet-detail-table .time {
  color: #666d7f;
  font-size: 12px;
}

.governance-layer-table .wallet-settings-wrapper.address-data {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blockexplorer-button {
  padding: 12px 15px 12px 10px;
  gap: 5px;
  min-width: 105px;
  width: 100%;
  height: 38px;
  border: 1px solid #23262e;
  border-radius: 10px;
  flex: none;
  order: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  cursor: pointer;
}
.blockexplorer-button:hover {
  border: 1px solid #363942;
}
.blockexplorer-button span {
  font-family: 'Gilroy-Bold';
  font-size: 14px;
  color: #23262e;
}
.search-bar .select-custom.select-menu-holder.address {
  max-width: 264px;
  width: 100%;
  text-transform: capitalize;
  margin-left: 50px;
  font-family: 'Gilroy-Medium';
}
.search-bar .select-value {
  align-items: flex-start;
  display: flex;
}
.search-bar .select-value img {
  width: 20px;
  height: 20px;
  flex: none;
}
.search-bar .select-menu.address.open {
  overflow: auto;
}
.search-bar .select-custom.select-menu-holder.address .select-value {
  font-family: 'Gilroy-Medium';
  overflow: auto;
}
.search-bar .select-custom.select-menu-holder.address .select-value span {
  padding-left: 5px;
  font-size: 16px;
  font-family: 'Gilroy-SemiBold';
}
.search-bar .select-menu .select-value:hover {
  background: rgba(35, 38, 46, 0.05);
}
.select-value.all {
  padding-left: 19px;
}
.search-bar .select-menu-holder .select-menu {
  background-color: #fafafa;
  user-select: none;
}
.search-bar .select-custom {
  padding-left: 0;
}
.governance-layer-table.wallets-address-table .settings {
  width: 39%;
}
.wallet-settings .table-row.data.wallet {
  border-top: 1px solid #f3f3f3;
}
.wallet-window-list .table-row.data {
  height: 100%;
}
.wallet-settings .table-row.data.wallet .wallet-settings-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.wallet-settings .table-row.data.wallet .wallet-settings-wrapper > div {
  width: 50%;
  display: flex;
}

.wallet-settings .wallet-settings-wrapper .titles-box {
  flex-direction: column;
  font-family: 'Gilroy-SemiBold';
  color: #666d7f;
  gap: 10px;
}

.titles-box.id-wallet > .titles-box-title {
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  color: #23262e;
}

.wallet-settings .table-row.data.wallet .wallet-settings-wrapper div.button-holder {
  justify-content: end;
  text-align: end;
}

.wallet-settings .table-row.data.wallet .wallet-settings-wrapper div.button-holder .button {
  max-width: 128px;
  height: 38px;
  font-size: 14px;
}

.wallet-backup-modal .v-modal-sub {
  color: #f74151;
  font-family: 'Gilroy-Medium';
  font-size: 16px;
  margin-top: 30px;
}

.wallet-backup-modal .v-modal-sub-detail ~ .select-group {
  margin-top: 30px;
}

.wallet-backup-modal .v-modal-sub-detail {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  text-decoration: underline;
  color: #23262e;
  margin-top: 10px;
}

.wallet-backup-modal .v-input-group input {
  height: 64px;
}

.team-page .wallet-backup-modal .button-holder .add-button {
  width: 100%;
  max-width: 100%;
}

.wallet-backup-ready {
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-backup-ready .v-modal-content {
  justify-content: center;
  align-items: center;
  width: 638px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wallet-backup-modal .wallet-info {
  display: flex;
  align-items: center;
}

.wallet-backup-modal .wallet-info .step-title {
  color: #666d7f;
}

.wallet-backup-ready .step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 13px;
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;

  width: 90px;
  height: 42px;

  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}

.wallet-backup-ready .wallet-info {
  display: flex;
  font-family: 'Gilroy-SemiBold';
  font-size: 24px;
  align-items: center;
  margin-top: 10px;
}

.wallet-backup-ready .steps {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-backup-ready .step-title {
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  color: #666d7f;
  margin-top: 30px;
}

.wallet-backup-ready .file {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  margin-top: 30px;
  border-top: 1px solid #f3f3f3;
  padding: 30px 0;
}

.wallet-backup-ready .checkbox-group {
  padding-left: 0;
  justify-content: center;
}

.wallet-backup-ready .checkbox-group:hover {
  background-color: inherit;
}

.wallet-backup-ready .checkbox-group .value-checkbox {
  padding-left: 30px;
}

.wallet-backup-ready .checkbox-group .checkmark {
  left: 29%;
}

.wallet-backup-ready .file .name {
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  display: flex;
  overflow-wrap: anywhere;
  color: #666d7f;
}

.wallet-backup-ready .file .button-holder {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  width: 280px;
  display: flex;
  align-self: baseline;
}

.wallet-backup-ready .step2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.wallet-backup-ready .big-title {
  font-family: 'Gilroy-Bold';
  font-size: 32px;
  margin-top: 30px;
}

.wallet-backup-ready .pdf-info {
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  line-height: 150%;
  margin-top: 20px;
}

.wallet-backup-ready .pdf-info ~ .v-input-group {
  margin-top: 30px;
}

.wallet-backup-ready .pdf-info ~ .v-input-group input {
  height: 76px;
  text-align: center;
  font-family: 'Gilroy-Medium';
  font-size: 20px;
  letter-spacing: 5px;
}
.team-page .wallet-backup-ready .file .button-holder .button.close-button.download-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px 10px;
  gap: 5px;
  border-radius: 10px;
  width: 119px;
  height: 38px;
  font-size: 14px;
}
.wallet-th {
  margin-top: 40px;
}
.wallet-th .trx-history-table-wrapper {
  height: calc(100vh - 340px) !important;
  margin-top: 40px;
}
.wallet-th .trx-history-table-wrapper.trial-banner-visible {
  height: calc(100vh - 525px) !important;
}
.blockexplorer-button .tronscan {
  width: 70px;
}
.wallets-policy-table-data.name .name-rule {
  background: rgba(2, 13, 28, 0.05);
  border-radius: 10px;
  max-width: 140px;
  text-align: center;
  padding: 5px 15px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.no-approve {
  color: #f7931a;
}
.await-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.await-modal.wallet-info {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.await-modal.wallet-info .wallet-icon {
  width: 24px;
  height: 24px;
}
.waiting-approval {
  border: 1px solid #23262e;
  border-radius: 52px;
  margin: 10px 0;
  max-width: 227px;
  display: flex;
  padding: 10px 20px;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  align-items: center;
  height: 39px;
}
.vault-table-data .addresses-table-data-edit-icon {
  margin-left: 10px;
}
.await-modal.wallet-info .name {
  color: #020d1c;
  font-size: 24px;
  font-family: 'Gilroy-SemiBold';
}
.vault-table-data:not(:first-child) {
  padding-left: 10px;
}
.wallet-window-list .vault-table-data {
  padding: 0.5rem;
}
.wallet-table-header {
  padding: 0 70px 0 50px !important;
}
.vault-table-data.name {
  flex-basis: 30%;
  max-width: 30%;
}
.vault-table-data .name-data .react-tooltip {
  z-index: 9 !important;
}
.vault-table-data.sum {
  flex-basis: 12%;
}
.vault-table-data.settings {
  flex-basis: 22%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.vault-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.addresses-table-data:not(:first-child) {
  padding-left: 10px;
}
.addresses-table-data.name {
  flex-basis: 25%;
}
.addresses-table-data.blockchain {
  flex-basis: 20%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
}
.addresses-table-data.trx-address {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.addresses-table-data.trx-address .address-title-table-data-flex-gap {
  overflow: unset !important;
}
.addresses-table-data.created-at {
  flex-basis: 20%;
}
.addresses-table-data.settings {
  flex-basis: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.addresses-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.addresses-table-data-header:not(:first-child) {
  padding-left: 10px;
}
.addresses-table-data-header.name {
  flex-basis: 26.5%;
}
.addresses-table-data-header.blockchain {
  flex-basis: 19.5%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
}
.addresses-table-data-header.trx-address {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.addresses-table-data-header.created-at {
  flex-basis: 20%;
}
.addresses-table-data-header.settings {
  flex-basis: 14%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.addresses-table-data-header {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
-header .wallets-address-text {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.search-bar {
  margin-left: 50px;
}
.wallet-settings-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}
.wallets-policy-table-data:not(:first-child) {
  padding-left: 10px;
}
.wallets-policy-table-data.range {
  flex-basis: 35%;
}
.wallets-policy-table-data.name {
  flex-basis: 25%;
}
.wallets-policy-table-data.level {
  flex-basis: 20%;
}
.wallets-policy-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.wallets-backup-wallet-button-wrapper {
  margin: 20px 0 10px 0;
  width: 100%;
}
.utxo-trx-row-container {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  margin-top: 30px;
}
.send-receive-button {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
}
.send-receive-button button {
  min-width: 100px;
  min-height: 40px;
  padding: 0 15px 0 10px !important;
}
.send-receive-button i {
  font-size: 20px;
}
.wallet-button-filters-wrapper {
  display: flex;
  padding: 0 50px 0 0;
  margin-top: 40px;
}

.wallet-button-filters-wrapper .filters {
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
}
.assets-table-tab {
  margin-top: 40px;
}
.wallet-detail-table.team-page .accordions-parent {
  height: calc(100% - 22px);
}
.wallet-detail-table.team-page .accordions-parent > .accordion-component ~ .accordion-component {
  border-top: none;
}
.wallet-detail-table.team-page .accordions-parent > .accordion-component {
  border-bottom: 0.5px solid #e6e7e8;
}
.accordion-table-parent-wallets {
  height: calc(100vh - 344px);
  overflow: auto;
  width: 100%;
  margin-top: 40px;
}
.accordion-table-parent-wallets.trial-banner-visible {
  height: calc(100vh - 500px);
  overflow: auto;
  width: 100%;
  margin-top: 40px;
}
.wallet-detail-table.team-page .table-wrapper {
  padding-bottom: 10px !important;
}
.accordion-table-parent-wallets .table-content .addresses-tab-table-row.caption {
  display: flex;
  padding-bottom: 10px;
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 2;
  padding: 0 50px 10px 50px;
}
.accordion-table-parent-wallets .table-row.caption {
  padding-bottom: 10px;
}
.wallet-detail-table.team-page .assets-table-tab .assets-table {
  margin-top: 40px;
  height: calc(100vh - 244px);
  overflow-x: hidden;
  overflow-y: auto;
}
.wallet-detail-table.team-page .assets-table-tab .assets-table.trial-banner-visible {
  margin-top: 40px;
  height: calc(100vh - 434px);
}
.wallet-detail-table.team-page .table-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  padding: 0 50px 0 74px;
  top: 0;
  background: #fafafa;
  z-index: 2;
}
.wallet-detail-table.team-page .balance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 15%;
}
.wallet-addresses-tablee-header {
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 2;
  padding: 0 50px 10px 72px;
  display: flex;
  width: 100%;
}
.wallet-detail-table.team-page .table-row.caption {
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 2;
  padding: 0 50px 10px 50px;
}
.assets-table-tab {
  margin-top: 42px;
}
.empty-addresses-label {
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f3f3f3;
}
.table-content-wallet-addresses-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  height: 100%;
}
.table-content-wallet-addresses-table .accordion-component,
.table-content-wallet-addresses-table .accordion-component .accordion-title-wrapper,
.table-content-wallet-addresses-table .accordion-component .accordion-details-wrapper {
  height: unset;
}
.wallet-addresses-table-header {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px !important;
  color: #555d67;
}
.vault-listing-secondary-icon {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
}
.wallets-alert-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 30px 0 10px 0;
  gap: 15px;
}
.wallet-addresses-link-address-wrapper {
  display: flex;
  gap: 20px;
}
.link-address-info-wrapper {
  border: 1px solid #e6e7e8;
  padding: 0 20px;
  border-radius: 10px;
  width: 450px;
  margin: 0 0 40px 0;
  display: flex;
  flex-direction: column;
}
.link-address-info-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid #e6e7e8;
}
.link-address-info-row:last-child {
  border: none;
}
.link-address-info-sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
.link-address-info-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}
.link-address-info-warning-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #f74151;
}
.table.wallet-settings-table {
  height: calc(100vh - 205px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 0;
}
.wallets-specific-container-flex,
.wallets-specific-container-flex-gap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.wallets-specific-container-flex-gap {
  gap: 10px;
}
.wallet-detail-table.team-page .gas-tanker-table-content-wallet-addresses-table .table-row.caption {
  padding: 0 50px 10px 72px;
}
.team-page.wallets .table-row.no-active {
  justify-content: center;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  margin-top: 20px;
}
.wallet-backup-modal .v-modal-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wallet-addresses-loader {
  margin-top: 42px;
}
.wallet-window-list {
  padding: 0 !important;
}
.create-transaction-request-modal .v-modal-content {
  max-width: 1077px;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 200px 0px #2ce28b4d;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.create-transaction-request-modal .overlay {
  overflow: hidden;
  overflow-y: auto;
}
.create-transaction-request-modal-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
}
.create-transaction-request-modal-content {
  flex-basis: 63%;
  padding: 40px;
  overflow-x: clip;
}
.create-trx-request-modal-select-component-label {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}
.create-trx-request-modal-select-component-wrapper {
  display: flex;
  gap: 15px;
  flex-direction: column;
}
.create-transaction-request-modal-heading {
  font-family: 'Gilroy-SemiBold';
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.create-transaction-request-modal-side {
  flex-basis: 37%;
  background: linear-gradient(220.35deg, #353d49 0%, #232932 100%);
  color: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.create-trx-request-modal-button,
.create-trx-request-modal-vault-step-button-wrapper {
  padding: 20px 0 0px 0;
}
.create-trx-request-modal-vault-step-button-wrapper {
  display: flex;
  gap: 20px;
}
.create-transaction-request-modal-side-heading {
  font-family: 'Gilroy-SemiBold';
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.create-transaction-request-modal-side-sub-heading {
  font-family: 'Gilroy-SemiBold';
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 40px 0 20px 0;
}
.create-transaction-request-modal-side-sub-heading svg path {
  stroke: #000;
}
.create-transaction-request-modal-step-desc {
  font-family: 'Gilroy-Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.create-transaction-request-modal-heading-wrapper {
  display: flex;
  flex-direction: column;
}
.create-trx-request-assets-select-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: visible;
  min-height: 33.5px;
}
.create-trx-request-assets-select-row-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: visible;
  height: 35px;
}
.create-trx-request-assets-select-row-icon {
  width: 28px;
  height: 28px;
}
.create-trx-request-assets-select-row-label {
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}
.create-trx-request-assets-select-row-sub-label {
  font-family: 'Gilroy-Medium';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(35, 38, 46, 0.3);
}
.create-trx-request-assets-select-asset-data-label {
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(35, 38, 46, 0.3);
}
.create-trx-request-step-one-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 20px;
}
.create-trx-request-step-three-wrapper,
.create-trx-request-step-four-wrapper,
.create-trx-request-step-five-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 20px;
}
.create-trx-request-step-five-wallet {
  display: flex;
  align-items: center;
}
.create-trx-request-step-five-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.create-trx-request-step-five-text-sum {
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.create-trx-request-step-five-sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}
.create-trx-request-step-five-medium-text,
.create-trx-request-step-five-note,
.create-trx-request-note-row {
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
}
.create-trx-request-step-five-text-with-border {
  width: 51px;
  height: 24px;
  padding: 8px, 15px, 8px, 15px;
  border-radius: 24px;
  border: 1px solid #000000;
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-trx-request-step-five-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
}
.create-trx-request-note-step-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;
  text-align: left;
}
.create-trx-request-step-five-note {
  max-width: 320px;
  word-break: break-all;
  max-height: 24px;
  overflow-y: auto;
}
.create-trx-request-note-row {
  max-width: 320px;
  word-break: break-all;
  max-height: 80px;
  overflow-y: auto;
  text-align: left;
  line-height: normal;
}
.create-trx-request-step-five-wrapper .wallet-icon {
  color: #fff;
  width: 28px;
  height: 28px;
  align-items: center;
  display: flex;
  padding: 7px 5px 5px 5px;
  justify-content: center;
  font-family: 'Gilroy-Bold';
  border-radius: 5px;
  margin-right: 10px;
  user-select: none;
  text-transform: capitalize;
}
.create-trx-request-step-five-amount-wrapper,
.create-trx-request-step-five-amount-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-trx-request-step-five-amount-icon-wrapper {
  gap: 5px;
}
.create-trx-request-step-five-amount-wrapper {
  gap: 10px;
}
.create-trx-request-step-five-amount-img {
  width: 28px;
  height: 28px;
}
.create-trx-request-step-four-wrapper textarea {
  resize: none;
  height: 167px !important;
}
.create-trx-request-contact-or-one-time-address-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-trx-request-contact-or-one-time-address {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  padding-bottom: 10px;
}
.create-trx-request-contact-or-one-time-address-text {
  cursor: pointer;
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
  color: #000;
}
.create-trx-request-contact-or-one-time-address-text.selected {
  cursor: pointer;
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
  padding: 8px 15px 8px 15px;
  border-radius: 24px;
  background: #000;
  color: #fff;
}
.create-trx-modal-label {
  margin-bottom: 10px;
}
.create-trx-request-modal-step-two-sender-filter-label-top {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.create-trx-request-modal-step-two-sender-filter-label-middle {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #555d67;
}
.create-trx-request-modal-step-two-sender-filter-label-bottom {
  font-family: 'Gilroy-Medium';
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8b9097;
}
.create-trx-request-modal-step-two-sender-filter-label-wrapper {
  display: flex;
  flex-direction: column;
}
.create-trx-request-modal-step-two-sender-filter-label-wrapper-internal {
  display: flex;
  flex-direction: column;
  max-width: 94%;
  flex-basis: 94%;
}
.create-trx-modal-v-input-group {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  gap: 30px;
}
.create-trx-modal-v-input-group .create-trx-modal-amount-input-acc-based,
.create-trx-request-modal-utxo-amount-row .create-trx-modal-amount-input {
  position: relative;
}
.create-trx-request-modal-utxo-amount-row .create-trx-modal-amount-input {
  flex-basis: 30%;
}
.create-trx-modal-v-input-group .create-trx-modal-amount-input input,
.create-trx-modal-v-input-group .create-trx-modal-amount-input-acc-based input {
  padding: 17px 70px 17px 50px;
}
.create-trx-request-modal-utxo-amount-row .create-trx-modal-amount-input input {
  padding: 19px 10px 17px 50px;
}
.create-trx-modal-v-input-group .create-trx-modal-icon-netwrok,
.create-trx-request-modal-utxo-amount-row .create-trx-modal-icon-netwrok {
  position: absolute;
  top: 15px;
  left: 13px;
}
.create-trx-modal-v-input-group .create-trx-modal-icon-netwrok-2,
.create-trx-request-modal-utxo-amount-row .create-trx-modal-icon-netwrok-2 {
  position: absolute;
  top: 17px;
  left: 13px;
}
.create-trx-modal-fiat-currency {
  position: absolute;
  top: 23px;
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  right: 20px;
  color: #666d7f;
}
.create-trx-modal-min-max {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666d7f;
  font-family: 'Gilroy-Medium';
  margin-top: 10px;
  width: 100%;
}
.create-trx-modal-min-max div {
  width: 50%;
}
.create-trx-modal-min-max .create-trx-modal-max,
.create-trx-modal-min-max .create-trx-modal-min {
  cursor: pointer;
}
.create-trx-modal-min-max .create-trx-modal-max {
  justify-content: flex-end;
  display: flex;
  white-space: nowrap;
}
.create-trx-request-modal-filter-label-wrapper {
  display: flex;
  gap: 10px;
}
.create-trx-request-priority-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}
.create-trx-request-priority-icon {
  width: 32px;
  height: 20px;
}
.create-trx-request-priority-data-wrapper {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.create-trx-request-priority-data-label {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.create-trx-request-priority-data-desc-label {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
.create-trx-request-priority-data-fee-label {
  font-family: 'Gilroy-Medium';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}
.create-transaction-request-swiper-wrapper {
  width: 100%;
  overflow: unset;
  max-width: 598.5px;
}
.create-transaction-request-swiper-wrapper.dynamic {
  height: unset;
}
.create-transaction-request-swiper-wrapper.sliding {
  overflow: hidden;
}
.create-transaction-request-swiper-wrapper .swiper {
  overflow: visible;
}
.create-trx-request-modal-destination-receivers-utxo-container {
  max-height: 220px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
}
.create-trx-request-modal-destination-receivers-utxo,
.create-trx-request-modal-utxo-amount-row-recipient-wrapper {
  height: 60px;
  flex-shrink: 0;
  padding: 0px 14px 0px 20px;
  border-radius: 10px;
  border: 1.5px solid #e6e7e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-trx-request-modal-destination-receivers-utxo-blockchain-text-wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
  max-width: 90%;
}
.create-trx-request-modal-destination-receivers-utxo-blockchain-wrapper {
  overflow: hidden;
}
.create-trx-request-modal-destination-receivers-utxo-blockchain-wrapper,
.create-trx-request-modal-destination-receivers-utxo-address-wrapper,
.create-trx-request-modal-utxo-amount-row-recipient-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.create-trx-request-modal-destination-receivers-utxo-blockchain-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 17.15px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.create-trx-request-modal-destination-receivers-utxo-blockchain-sub-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #555d67;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.create-trx-request-modal-destination-receivers-utxo-address {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #555d67;
}
.create-trx-request-modal-utxo-delete-icon {
  width: 28px;
  height: 28px;
}
.create-trx-request-modal-icon {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  background: #e6e7e8;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.create-trx-request-modal-icon:hover {
  background: #dddddd;
}
.create-trx-request-modal-icon-svg {
  transform: scale(0.9);
}
.create-trx-request-modal-icon.amount {
  flex-basis: 10%;
}
.create-trx-request-modal-destination-utxo-address-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}
.create-trx-request-modal-utxo-amount-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
}
.create-trx-request-modal-utxo-amount-row-recipient-wrapper {
  flex-basis: 60%;
  max-width: 60%;
  justify-content: start;
}
.create-trx-request-modal-contact-icon {
  width: 28px;
  height: 28px;
  background: #8b9097;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.create-trx-request-modal-contact-icon path {
  fill: #fff;
}
.create-trx-request-modal-internal-icon {
  width: 28px;
  height: 28px;
  background: #8b9097;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 28px;
}
.create-trx-request-modal-internal-icon path {
  fill: #fff;
}
.create-trx-request-modal-amount-labels-wrapper {
  display: flex;
  justify-content: space-between;
}
.create-trx-request-modal-utxo-amounts-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.create-trx-request-modal-balance-label {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #555d67;
  margin-right: 50px;
}
.create-trx-request-modal-balance-label-wrapper {
  display: flex;
  flex-direction: column;
}
.create-trx-request-modal-balance-label-error {
  color: red;
}
.create-trx-request-summary-asset-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #353d49;
}
.create-trx-modal-recepient-amount-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-basis: 30%;
  max-width: 30%;
}
.create-trx-modal-recepient-amount-wrapper span {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}
.create-trx-request-modal-utxo-amount-row-summary {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 15px;
  padding: 0 15px;
}
.create-trx-request-modal-utxo-amount-row-icon-summary {
  display: flex;
  gap: 10px;
  flex-basis: 70%;
  max-width: 70%;
}
.asset-accordion-details-trx-request-modal-summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 154px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  margin-top: 20px;
  border: 1px solid #e6e7e8;
  border-radius: 10px;
  padding: 20px 0;
  overflow: hidden;
}
.create-trx-request-step-five-row-col,
.create-trx-request-summary-address-info-wrapper {
  display: flex;
  flex-direction: column;
}
.create-trx-request-summary-address-info-wrapper {
  gap: 6px;
  text-align: right;
}
.create-trx-request-summary-address-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}
.create-trx-request-summary-address-name {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}
.create-trx-request-summary-address-address {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #555d67;
}
.create-trx-request-modal-select-component-wrapper .select-custom-new.open ~ .checkboxes-new {
  max-height: 230px;
}
.create-trx-request-modal-internal-address-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.create-trx-request-modal-filter-labels-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.approx-fee-alert-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.mobile-stepper {
  display: none;
}
.wallet-row-view-details {
  display: none;
}
@media screen and (max-width: 1460px) {
  .vault-table-data.name {
    flex-basis: 30%;
    max-width: 30%;
  }
  .vault-table-data.sum {
    flex-basis: 15%;
  }
  .vault-table-data.settings {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  .send-receive-button {
    flex-direction: column;
  }
}
@media screen and (max-width: 1400px) {
  .addresses-table-data {
    flex-shrink: 2;
  }
}
@media screen and (max-width: 1265px) {
  .governance-layer-table.wallets-table .name-right {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 230px;
  }
}
@media screen and (max-width: 950px) {
  .table.wallet-settings-table.trial-banner-visible {
    height: calc(100vh - 363px);
    height: calc(100dvh - 363px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 0;
  }
  .table.governance-layer-table.wallets-rules-table.trial-banner-visible {
    width: 100%;
    height: calc(100vh - 426px) !important;
    height: calc(100dvh - 426px) !important;
  }
  .accordion-table-parent-wallets {
    height: calc(100vh - 355px);
    height: calc(100dvh - 355px);
    margin-top: 20px !important;
  }
  .accordion-table-parent-wallets.trial-banner-visible {
    height: calc(100vh - 534px);
    height: calc(100dvh - 534px);
  }
  .wallet-th .trx-history-table-wrapper.trial-banner-visible {
    height: calc(100vh - 383px) !important;
    height: calc(100dvh - 383px) !important;
  }
  .table.wallets-table {
    overflow: auto;
    height: calc(100vh - 243px);
    height: calc(100dvh - 243px);
    width: 100%;
    padding-bottom: 0;
  }
  .table.wallets-table.trial-banner-visible {
    overflow: auto;
    height: calc(100vh - 401px);
    height: calc(100dvh - 401px);
    width: 100%;
  }
  .team-page.wallets .button-holder .button-component-main {
    width: 100%;
  }
  .team-page.wallets .header-table {
    padding: 0 20px;
  }
  .team-page.wallets .table-row.caption {
    display: none;
  }
  .team-page.wallets .table-row.data {
    flex-direction: column;
    padding: 20px;
  }
  .team-page.wallets .table-row.data > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
  }
  .team-page.wallets .table-row.data .vault-table-data.name {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .team-page.wallets .table-row.data .vault-table-data {
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    min-height: 40px;
  }
  .team-page.wallets .table-row.data .vault-table-data.settings {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  .team-page.wallets .table-row.data .vault-table-data.settings .send-receive-button > * {
    width: 100%;
  }
  .governance-layer-table {
    margin-top: 10px !important;
  }
  .wallet-detail-table.team-page .assets-table-tab .assets-table {
    margin-top: 20px !important;
    height: calc(100vh - 225px);
    height: calc(100dvh - 225px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .wallet-detail-table.team-page .assets-table-tab .assets-table.trial-banner-visible {
    height: calc(100vh - 383px);
    height: calc(100dvh - 383px);
  }
  .wallet-detail-table.team-page .asset-accordion-details > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .wallet-detail-table.team-page .button-filters-wrapper {
    flex-direction: column;
  }
  .wallet-detail-table.team-page .button-filters-wrapper .button-holder {
    padding: 0 20px;
  }
  .wallet-detail-table.team-page .button-filters-wrapper .button-holder button {
    width: 100%;
  }
  .wallet-detail-table.team-page .wallet-settings .table-row.data {
    padding: 20px;
  }
  .wallet-detail-table.team-page .wallet-settings .wallet-settings-wrapper {
    gap: 10px;
  }
  .wallet-detail-table.team-page .link {
    margin-right: 0;
    padding: 20px;
    justify-content: center;
    text-wrap: nowrap;
  }
  .wallet-detail-table.team-page .wallets-rules-table .table-row.caption {
    display: none;
  }
  .wallet-detail-table.team-page .wallets-rules-table .table-row.data,
  .wallet-detail-table.team-page .automation-table .table-row.data {
    padding: 20px 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d5d5d5;
    gap: 10px;
  }
  .wallet-detail-table.team-page .wallets-rules-table .table-row.data > *,
  .wallet-detail-table.team-page .automation-table .table-row.data > * {
    width: 100% !important;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 5px 0;
  }
  .edit-wallet {
    padding: 0 20px;
    margin-left: 0 !important;
  }
  .wallet-detail-table.team-page .gas-tanker-wallet-button-filters-wrapper {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 0 20px;
  }
  .wallet-detail-table.team-page .gas-tanker-wallet-button-filters-wrapper button {
    width: 100%;
  }
  .wallet-detail-table.team-page .gas-tanker-accordion-table-parent-wallets {
    margin-top: 20px;
    height: calc(100vh - 295px);
    height: calc(100dvh - 295px);
  }
  .wallet-detail-table.team-page .gas-tanker-accordion-table-parent-wallets.trial-banner-visible {
    margin-top: 20px;
    height: calc(100vh - 448px);
    height: calc(100dvh - 448px);
  }
  .wallet-detail-table.team-page .gas-tanker-accordion-table-parent-wallets .table-row.caption {
    display: none;
  }
  .wallet-detail-table.team-page .gas-tanker-accordion-table-parent-wallets .accordion-title-wrapper {
    padding: 20px;
  }
  .wallet-detail-table.team-page .automation-rules-header-table {
    flex-direction: column;
    gap: 20px;
    margin: 30px 0 0 0;
    padding: 0 20px;
  }
  .wallet-detail-table.team-page .automation-rules-header-table button {
    width: 100%;
  }
  .wallet-detail-table.team-page .automation-table-wrapper {
    display: none;
  }
  .wallet-detail-table.team-page .automation-table {
    margin-top: 20px;
  }
  .assets-table-tab {
    margin-top: 20px;
  }
  .wallet-th .trx-history-table-wrapper {
    height: calc(100vh - 225px) !important;
    height: calc(100dvh - 225px) !important;
    margin-top: 0px;
    overflow: hidden;
    padding-bottom: 20px;
  }
  .new-desposit-address-modal .v-modal-content {
    padding: 15px 20px;
    width: 100vw;
  }
  .wallet-backup-ready .v-modal-content {
    justify-content: flex-start;
    width: 100vw;
    padding: 20px;
  }
  .color-menu.shrinked {
    right: 0%;
  }
  .table.governance-layer-table.wallets-rules-table {
    width: 100%;
    height: calc(100vh - 269px) !important;
    height: calc(100dvh - 269px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 0px !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .color-changer {
    height: 33px;
  }
  .wallet-window-list .table-row.data {
    height: unset;
  }
  .create-transaction-request-modal-side {
    display: none;
  }
  .create-transaction-request-modal-content {
    flex-basis: 100%;
    padding: 20px;
  }
  .create-transaction-request-modal .overlay {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .create-transaction-request-modal .v-modal-content {
    padding: 0px;
    top: 0;
    transform: unset;
    left: unset;
  }
  .create-transaction-request-modal-wrapper {
    overflow: hidden;
  }
  .create-trx-request-contact-or-one-time-address-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .create-trx-request-contact-or-one-time-address-text,
  .create-trx-request-contact-or-one-time-address-text.selected {
    text-wrap: nowrap;
    font-size: 12px;
  }
  .create-trx-request-contact-or-one-time-address {
    gap: 5px;
  }
  .mobile-stepper {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 8px 10px;
    border-radius: 30px;
  }
  .create-transaction-request-modal-side-sub-heading {
    margin: 20px 0;
  }
  .create-transaction-request-swiper-wrapper {
    max-width: calc(100vw - 40px);
  }
  .create-trx-request-modal-utxo-amount-row-recipient-wrapper {
    flex-basis: 45%;
    max-width: 45%;
    padding: 0px 14px 0px 10px;
  }
  .create-trx-request-modal-destination-receivers-utxo-blockchain-text-wrapper {
    flex-basis: 80%;
    max-width: 80%;
  }
  .create-trx-modal-amount-input,
  .create-trx-modal-recepient-amount-wrapper {
    flex-basis: 55% !important;
    max-width: 55% !important;
  }
  .create-trx-request-modal-utxo-amount-row-icon-summary {
    flex-basis: 55%;
    max-width: 55%;
  }
  .create-trx-request-assets-select-wrapper {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
  .create-trx-request-summary-address-address {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
  }
  .create-trx-request-step-five-row,
  .create-trx-request-note-step-row {
    gap: 30px;
  }
  .wallet-row-view-details {
    display: flex;
    max-width: 100%;
    flex-basis: 100% !important;
    width: 100%;
  }
  .send-receive-button {
    flex-direction: row;
  }
}
