.landscape-warning-overlay {
  display: none;
  position: fixed;
  background: #000000f0;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 9;
  overflow-y: auto;
}
@media only screen and (max-height: 500px) and (orientation: landscape) {
  .landscape-warning-overlay {
    display: flex;
    justify-content: center;
  }
}
