.plan-details-box-wrapper {
  width: 100%;
  padding: 12.5px 20px;
  border-radius: 10px;
  gap: 15px;
  display: flex;
  align-items: center;
  border: 1.5px solid #b1b4b9;
}
.plan-details-box-icon {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  gap: 10px;
  background: #e6e7e8;
}
.plan-details-box-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.plan-details-box-title {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #555d67;
}
.plan-details-box-info {
  font-family: 'Gilroy-Bold';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #000;
}
