.accordion-component,
.accordion-component .accordion-title-wrapper,
.accordion-component .accordion-details-wrapper {
  width: 100%;
  height: 100%;
}
.accordion-component {
  padding: 0 !important;
  box-sizing: border-box;
}
.accordion-component .accordion-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 32px 50px;
}
.accordion-component .accordion-title-wrapper.open {
  box-shadow: 0px 6.55px 10px 0px #00000008;
}
.accordion-component .accordion-title-wrapper .accordion-carousel {
  transition: 0.5s ease;
  transform: rotate(180deg);
}
.accordion-component .accordion-title-wrapper .accordion-carousel.open {
  transform: rotate(0deg);
}
.accordion-component .accordion-details-wrapper {
  background: #020d1c05;
  transition: 0.3s ease;
  padding: 0;
}
.accordion-component .accordion-details-wrapper.false {
  overflow: hidden;
  max-height: 0px;
  opacity: 0;
}

.accordion-component .accordion-details-wrapper.open {
  opacity: 1;
  padding: 30px 0;
}
@media screen and (max-width: 950px) {
  .accordion-component .accordion-title-wrapper {
    padding: 32px 20px;
    gap: 20px;
  }
}
