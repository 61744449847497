.btn-sign {
  font-size: 18px;
  background: var(--project-green);
  padding: 20px 0;
  border-radius: 10px;
  font-family: 'Gilroy-Bold';
}
.btn-sign:hover {
  background: #1ac673;
}
.btn-sign:disabled {
  background: var(--project-dark-disabled);
  color: var(--bs-white);
  opacity: 1;
}
.btn-link {
  color: var(--project-dark-color);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
  color: var(--project-dark-color);
}
.contact-support {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.contact-support a,
.contact-support {
  font-size: 16px;
  font-weight: 600;
}

.invalid-input .form-control {
  border-color: var(--project-red);
  box-shadow: 0 0 0 0.25rem rgb(247 65 81 / 25%);

  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.guest-layout .form-control {
  height: 46px;
}
.guest-layout .row {
  padding-bottom: 10px;
}
.guest-layout .susess-text-align {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.guest-layout .icon-title {
  margin-bottom: 16px;
}
.guest-layout .alert {
  position: absolute;
  top: 10px;
  background: rgba(247, 65, 80, 0.9);
  border: 0;
  padding: 3px 5px;
  color: #fff;
  width: 350px;
}
.guest-layout .text-title {
  margin-top: 16px;
  font-size: 14px;
  line-height: 150%;
  font-family: 'Gilroy-Medium';
}
.guest-layout .btn.btn-dark.btn-sm.email-resend.rounded-4 {
  position: relative;
  bottom: 15px;
}
.guest-layout .susess-text-align .check-background {
  margin-bottom: 16px;
  padding: 20px;
  gap: 10px;

  width: 80px;
  height: 80px;

  background: #2ce28b;
  border-radius: 100px;
}
.guest-layout .susess-text-align .check-background .icon-check {
  font-size: 34px;
  color: #fff;
  width: 33.4px;
  height: 25px;
}
.valid-input .form-control {
  border-color: var(--project-green);
  box-shadow: 0 0 0 0.25rem rgb(44 226 139 / 25%);
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-feedback {
  display: block;
  color: var(--project-red);
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  bottom: -20px;
  white-space: nowrap;
}
.v-textarea-group {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}
.v-textarea-group label {
  margin-bottom: 5px;
}
.v-textarea-group textarea {
  height: 260px;
  resize: none;
  font-size: 11px;
}
.v-textarea-group .v-input-group {
  margin-top: 0;
}
.v-textarea-group .v-input-group ~ .v-input-group {
  margin-top: 0;
}
.v-input-group {
  position: relative;
}

.invalid-feedback.error-admin {
  position: absolute;
  bottom: -20px;
}

.form-check-input {
  padding: 10px;
}

.form-check-input:checked {
  background-color: var(--project-green);
  border-color: var(--project-green);
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(44 226 139 / 25%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.guest-layout [type='number'] {
  font-size: 20px !important;
  -moz-appearance: textfield;
}

.login-attempt {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 190px);
}

.login-attempt .loading {
  margin-top: 30px;
}

.login-attempt .title {
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  margin-top: 30px;
}

.login-attempt .info-block {
  margin-top: 20px;
  font-family: 'Gilroy-Medium';
  font-size: 18px;
}

.login-attempt .info-block .info {
  line-height: 150%;
}

.login-attempt img {
  margin-bottom: 30px;
}

.login-attempt .info.red {
  color: #f74151;
  text-align: center;
}

.register-mobile {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.register-mobile .icon img {
  width: 120px;
  height: 120px;
}
.register-mobile .title {
  font-size: 20px;
  margin-top: 40px;
  font-family: 'Gilroy-Bold';
}
.register-mobile .info {
  font-family: 'Gilroy-Medium';
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}
.register-mobile .qr-code {
  margin-top: 10px;
  width: 150px;
  height: 150px;
}

.register-mobile .waiting-scan {
  padding: 10px 20px;
  gap: 10px;
  width: 195px;
  height: 39px;
  border: 1px solid #23262e;
  border-radius: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
}
.not-support {
  background-color: #23262e;
  color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-support .content {
  background: #fff;
  color: black;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  text-align: center;
  width: 100%;
}
.not-support .content .logo {
  margin-bottom: 30px;
}
.layout-guest {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.layout-guest .fw-bolder {
  font-family: 'Gilroy-Bold';
  font-size: 43px;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 49.52px;
  text-align: left;
}
.layout-guest-loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.layout-guest-sidebanner {
  height: 100vh;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 200px;
  background-repeat: no-repeat;
  background-size: cover;
}
.layout-guest-sidebanner div {
  z-index: 2;
  color: #fff;
}
.guest-layout {
  height: 100vh;
  text-align: left;
  width: 700px;
  min-width: 700px;
  padding: 50px;
  overflow-y: auto;
}
.guest-layout .logo img {
  height: 30px;
  margin-bottom: 40px;
}
.layout-guest-sidebanner-title {
  font-family: 'Gilroy-Bold';
  font-size: 66.67px;
  font-weight: 400;
  line-height: 74.07px;
  text-align: left;
}
.layout-guest-sidebanner-subtitle {
  font-family: 'Gilroy-Medium';
  font-size: 23.7px;
  font-weight: 400;
  line-height: 35.56px;
  text-align: left;
}
.guest-textfield-label {
  font-family: 'Gilroy-SemiBold';
  font-size: 20px;
  font-weight: 400;
  line-height: 23.7px;
  text-align: left;
  margin-bottom: 10px;
}
.guest-layout .first-name-last-name-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 1rem;
}
.guest-layout-footer {
  margin-top: 10px;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
}
.guest-layout-footer span {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
.signin-form-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100vh - 267.5px);
}
.guest-flow-form-main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: calc(100vh - 290px);
}
.guest-flow-form-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: calc(100vh - 190px);
}
.guest-flow-form-wrapper .fw-bolder {
  margin: 0 0 25px 0 !important;
}
.guest-flow-subtitle {
  font-family: 'Gilroy-Medium';
  font-size: 25px;
  font-weight: 400;
  line-height: 37.76px;
  text-align: left;
  margin-bottom: 50px;
}
.forgot-pass-sent-title {
  font-family: 'Gilroy-Bold';
  font-size: 43.16px;
  font-weight: 400;
  line-height: 53.43px;
  text-align: center;
  margin-top: 50px;
}
.forgot-pass-sent-subtitle {
  font-family: 'Gilroy-Medium';
  font-size: 25.18px;
  font-weight: 400;
  line-height: 37.76px;
  text-align: center;
}
.forgot-pass-icon {
  width: 132px;
  height: 87px;
}
.guest-flow-textfield-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 50%;
  flex-basis: 50%;
}
.guest-flow-textfield-wrapper input {
  width: 100%;
}
.verify-email-button-icon path {
  fill: #000;
}
.verify-mobile-page-img {
  width: 120px;
  height: 120px;
}
.login-attempt-text {
  width: 100%;
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}
.login-attempt-sub-text {
  width: 100%;
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  color: #f74151;
  text-align: left;
  margin-top: 40px;
}
.recaptcha-text {
  display: none;
}
.phone-wrapper {
  position: relative;
}
.phone-wrapper .PhoneInput {
  border: 1px solid #ced4da;
  color: #212529;
  background-color: #fff;
  padding: 0.375rem 0.75rem;
  height: 60px;
  border-radius: 0.375rem;
}
.phone-wrapper .PhoneInputInput,
.phone-wrapper .PhoneInput.PhoneInput--focus input {
  border: none !important;
  outline: none;
}
.phone-wrapper .PhoneInput:focus-within {
  border-color: var(--project-dark-color);
  box-shadow: 0 0 0 0.25rem rgb(35 38 46 / 15%);
}
.guest-layout .return-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.guest-layout .return {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  padding-right: 15px;
  margin: 10px 0 15px 0;
}

.guest-layout .return span {
  text-decoration: none;
  color: #000;
}

.guest-layout .return i {
  transform: rotateY(180deg);
  width: 40px;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1800px) {
  .layout-guest-sidebanner {
    padding: 0 150px;
  }
  .layout-guest-sidebanner-title {
    font-size: 55px;
    line-height: 60px;
  }
  .layout-guest-sidebanner-subtitle {
    font-size: 20px;
    line-height: 35.56px;
  }
  .fw-bolder {
    font-size: 28px !important;
  }
  .guest-flow-subtitle {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 30px;
  }
  .forgot-pass-sent-title {
    font-size: 28px;
    margin-top: 20px;
  }
  .forgot-pass-sent-subtitle {
    font-size: 18px;
    line-height: 20px;
  }
  .guest-flow-form-wrapper .fw-bolder {
    margin: 0 !important;
  }
}
@media screen and (max-width: 1300px) {
  .guest-layout {
    min-width: unset;
  }
  .layout-guest-sidebanner {
    padding: 0 100px;
  }
}
@media screen and (max-height: 800px) {
  .guest-layout {
    padding: 40px 40px;
  }
}
@media screen and (max-width: 950px) {
  .guest-layout .logo {
    display: block;
  }
  .guest-layout {
    height: 100%;
    padding: 40px;
  }
  .layout-guest-sidebanner {
    display: none;
  }
  .layout-guest {
    padding: 0;
  }
  .guest-layout-footer {
    margin-bottom: 40px;
  }
  .grecaptcha-badge {
    visibility: hidden !important;
  }
  .recaptcha-text {
    display: block;
    font-family: 'Gilroy-Bold';
    font-size: 12px;
    color: #a2a2a2;
    margin-bottom: 20px;
  }
  .recaptcha-text a {
    color: rgb(66, 161, 255) !important;
  }
  .guest-flow-textfield-wrapper {
    flex-basis: 100%;
    max-width: 100%;
  }
  .guest-layout .first-name-last-name-wrapper {
    flex-wrap: wrap;
  }
  .guest-flow-form-wrapper {
    height: unset;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .guest-flow-form-main {
    height: unset !important;
    margin-bottom: 30px;
  }
  .verify-mobile-page-img {
    width: 90px;
    height: 90px;
  }
  .app-qr-codes-wrapper {
    gap: 10px;
  }
  .v-textarea-group {
    flex-direction: column;
  }
  .v-textarea-group textarea {
    font-size: 12px;
  }
  .login-attempt {
    height: calc(100vh - 190px);
    height: calc(100dvh - 190px);
  }
  .signin-form-wrapper {
    height: calc(100vh - 267.5px);
    height: calc(100dvh - 267.5px);
  }
  .guest-flow-form-main {
    height: calc(100vh - 290px);
    height: calc(100dvh - 290px);
  }
  .guest-flow-form-wrapper {
    height: calc(100vh - 150px);
    height: calc(100dvh - 150px);
  }
  .layout-guest {
    height: 100vh;
    height: 100dvh;
  }
  .not-support {
    height: 100vh;
    height: 100dvh;
  }
  .layout-guest-loading {
    height: 100vh;
    height: 100dvh;
  }
  .layout-guest-sidebanner {
    height: 100vh;
    height: 100dvh;
  }
  .guest-layout {
    height: 100vh;
    height: 100dvh;
  }
  .link-account-bold-text {
    font-size: 22px !important;
    line-height: 26px !important;
    padding-bottom: 10px;
  }
  .guest-flow-subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
