.textfield-component-wrapper {
  position: relative;
}
.textfield-component-wrapper.fullWidth {
  width: 100%;
}
.textfield-component-main {
  width: 200px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.textfield-component-wrapper input.form-control:disabled {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.textfield-component-main.sm {
  height: 46px;
}
.textfield-component-main.lg {
  height: 60px;
}
.textfield-component-main.adornment {
  padding-left: 46px;
}
.textfield-component-main.valid {
  border-color: var(--project-green);
  box-shadow: 0 0 0 0.25rem rgb(44 226 139 / 25%);
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.textfield-component-main.error {
  border-color: var(--project-red);
  box-shadow: 0 0 0 0.25rem rgb(247 65 81 / 25%);
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.textfield-component-main:focus {
  border-color: var(--project-dark-color);
  box-shadow: 0 0 0 0.25rem rgb(35 38 46 / 15%);
}
.textfield-component-main.no-focus:focus {
  border: 1px solid #ced4da;
  border-color: none;
  box-shadow: none;
}
.textfield-component-main::placeholder {
  color: #b1b4b9;
}
.textfield-component-main.fullWidth {
  width: 100%;
}
.textfield-component-error {
  display: block;
  color: var(--project-red);
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
}
.textfield-component-wrapper .textfield-icon {
  z-index: 1;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 16px;
  transform: translateY(-55%);
}
.textfield-input-wrapper {
  position: relative;
}
@media screen and (max-width: 950px) {
  .textfield-component-main.sm {
    height: 52px;
  }
  .textfield-component-main.lg {
    height: 52px;
  }
}
