.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.mobile-header .mobile-header-logo img {
  height: 60px;
}
.mobile-header-hamburger,
.mobile-header-testmode,
.mobile-header .mobile-header-logo {
  flex-basis: 33%;
  display: flex;
  justify-content: center;
}
.mobile-header-hamburger {
  justify-content: flex-start;
}
.mobile-header-testmode {
  justify-content: flex-end;
}
.mobile-header .testdata {
  bottom: -25px;
  color: #fff;
  background-color: #ff3d1d;
  border-radius: 0px 0px 5px 5px;
  padding: 4px 10px;
  gap: 10px;
  font-family: 'Gilroy-SemiBold';
  font-size: 13px;
  position: absolute;
  min-width: 78px;
  height: 22px;
  left: calc(50% - 78px / 2);
  display: flex;
  top: 60px;
  align-items: center;
  justify-content: center;
}
.mobile-header.test {
  border-bottom: 1px solid #ff3d1d;
}
.mobile-header .label.checked {
  color: #ff3d1d;
}
.mobile-header .label {
  text-wrap: nowrap;
}
.mobile-header .switch-button .label {
  font-family: 'Gilroy-SemiBold';
  font-size: 13px;
  margin-left: 10px;
}
.mobile-menu {
  border-right: 1px solid #f3f3f3;
  width: 100%;
  display: flex;
  padding-top: 20px;
  font-family: 'Gilroy-Bold';
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 70px);
  min-height: calc(100dvh - 70px);
}
.mobile-menu .social-media {
  margin-top: 20px;
  display: flex;
  padding: 0;
  padding-left: 20px;
  align-items: flex-end;
  gap: 10px;
}
.mobile-menu .social-media div {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.mobile-menu .social-media a {
  width: unset;
}
.mobile-menu .social-media .x-media {
  background-image: url('../../../assets/icons/social/x.svg');
}
.mobile-menu .social-media .x-media:hover {
  background-image: url('../../../assets/icons/social/x-hover.svg');
}
.mobile-menu .social-media .linkedin {
  background-image: url('../../../assets/icons/social/linkedin.svg');
}
.mobile-menu .social-media .linkedin:hover {
  background-image: url('../../../assets/icons/social/linkedin-h.svg');
}
.mobile-menu .social-media .github {
  background-image: url('../../../assets/icons/social/github.svg');
}
.mobile-menu .social-media .github:hover {
  background-image: url('../../../assets/icons/social/github-h.svg');
}
.mobile-menu .icon {
  display: flex;
  align-items: center;
}
.mobile-menu ul {
  margin: 0;
  padding: 0;
}
.mobile-menu ul a {
  width: 101%;
  color: inherit;
  text-decoration: none;
  font-size: 14px;
  align-items: center;
  display: flex;
  font-family: 'Gilroy-Bold';
}
.mobile-menu ul a li {
  list-style: none;
  padding: 24px 0px 24px 33px;
  font-size: 14px;
  cursor: pointer;
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
}
.mobile-menu ul li .icon::before {
  font-size: 18px;
}
.mobile-menu ul li .text {
  margin-left: 10px;
  margin-top: 0;
  font-family: 'Gilroy-Bold';
  font-size: 14px;
}
.mobile-menu ul div ~ div {
  display: flex;
}
.mobile-menu ul li.active {
  color: #fff;
  background: #23262e;
}
.mobile-menu ul li .icon path,
.mobile-menu ul li .icon path {
  fill: #000;
}
.mobile-menu ul li .icon {
  height: 20px;
  width: 20px;
}
.mobile-menu ul li .icon path {
  height: 20px;
  width: 20px;
}
.mobile-menu ul li.active .icon path {
  fill: #2ce28b;
}
.mobile-menu-user,
.mobile-menu-submenu-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}
.mobile-menu-user {
  padding: 10px 20px;
  border-bottom: 1px solid #d0d0d0;
  font-weight: bold;
  font-family: 'Gilroy-Bold';
}
.mobile-menu-accordion-title-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.hamburger-menu {
  cursor: pointer;
  width: 20px;
}
.mobile-menu .accordion-title-wrapper {
  padding: 13.5px 10px;
}
.mobile-menu .accordion-component {
  display: flex;
  flex-direction: column;
}
.mobile-menu .accordion-component .accordion-details-wrapper {
  padding: 0;
  margin: 0;
}
.mobile-menu .no-menu-active-effects .icon path {
  fill: #000 !important;
}
.mobile-menu .no-menu-active-effects {
  display: block;
}
.mobile-menu .submenu-active-effects.active .icon path {
  fill: #2ce28b !important;
}
.mobile-menu .submenu-active-effects {
  padding-left: 50px;
}
.mobile-header .email {
  color: #969696;
  font-weight: 200;
  font-family: 'Gilroy-Medium';
  text-wrap: nowrap;
  max-width: calc(100vw - 100vw / 3);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mobile-header .drawer {
  width: calc(100vw - 100vw / 3);
  max-width: 350px;
}
/* .mobile-menu .mobile-menu-submenu-wrapper ul {
  margin: 0;
  padding: 0;
}
.mobile-menu .mobile-menu-submenu-wrapper ul li .icon::before {
  font-size: 18px;
}
.mobile-menu .mobile-menu-submenu-wrapper ul li .text {
  margin-left: 10px;
  margin-top: 0;
  font-family: 'Gilroy-Bold';
  font-size: 14px;
}
.mobile-menu .mobile-menu-submenu-wrapper ul div ~ div {
  margin-top: 10px;
  display: flex;
}
.mobile-menu .mobile-menu-submenu-wrapper ul li.active,
.mobile-menu .mobile-menu-submenu-wrapper ul li:hover {
  color: #fff;
  background: #23262e;
}
.mobile-menu .mobile-menu-submenu-wrapper ul li .icon path,
.mobile-menu .mobile-menu-submenu-wrapper ul li .icon path {
  fill: #000;
}
.mobile-menu .mobile-menu-submenu-wrapper ul li.active .icon path,
.mobile-menu .mobile-menu-submenu-wrapper ul li:hover .icon path {
  fill: #2ce28b;
} */
@media screen and (min-width: 950px) {
  .mobile-header {
    display: none;
  }
}
@media screen and (max-height: 690px) {
  .mobile-menu {
    min-height: unset;
  }
}
