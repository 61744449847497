.switch-button {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  content: ' ';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bdbec0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  height: 16px;
  content: '';
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff3d1d;
}
input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
  /* font-family: 'fontello';
    content: "\e810"; */
  display: flex;
  align-items: center;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.header .switch-button .label {
  font-family: 'Gilroy-SemiBold';
  font-size: 13px;
  margin-left: 10px;
}
.header .label.checked {
  color: #ff3d1d;
}
