:root {
  --bs-body-bg: #fafafa;
  --bs-font-sans-serif: 'Gilroy-Medium';

  --project-dark-color: #23262e;
  --project-dark-disabled: rgba(35, 38, 46, 0.2);
  --project-green: #2ce28b;
  --project-red: #f74151;
}

.bg-white {
  background-color: var(--bs-white);
}

.form-control-lg {
  border: 1px solid #cecece;
  color: var(--project-dark-color);
  font-size: 18px;
}

.form-control:focus {
  border-color: var(--project-dark-color);
  box-shadow: 0 0 0 0.25rem rgb(35 38 46 / 15%);
}

.form-control::placeholder {
  color: #666d7f;
}

.pointer-events-none {
  pointer-events: none;
}

.axios-error-details-toast {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.loading-full-height {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
