.dashbaord {
  display: flex;
  overflow: overlay;
}
.modal-limits .v-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.modal-limits .cancel-button {
  max-width: 100%;
  height: 60px;
}
.modal-limits .title {
  color: #020d1c;
  text-align: center;
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.modal-limits .link {
  color: #23262e;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}
.modal-limits img {
  display: flex;
  width: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modal-active {
  overflow-y: hidden;
}
.main,
#root {
  width: 100%;
}
.logo img {
  height: 20px;
  cursor: pointer;
}
.header {
  height: 70px;
  border-bottom: 1px solid #f3f3f3;
  align-items: center;
  display: flex;
  position: relative;
}
.header.test {
  border-color: #ff3d1d;
}
.toolbar .title {
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  font-weight: bold;
  margin-top: 32px;
}
.toolbar {
  background: #23262e;
  width: 500px;
  display: flex;
  height: auto;
  color: #fff;
  color: #ffffff;
  padding: 0 20px;
}
.menu {
  border-right: 1px solid #f3f3f3;
  width: 250px;
  display: flex;
  padding-top: 20px;
  font-family: 'Gilroy-Bold';
  flex-direction: column;
}
html {
  overflow: hidden;
}
.governance-layer-table {
  overflow: auto;
  height: 100vh;
}
.table.governance-layer-table.api-table {
  height: calc(100vh - 350px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
}
.table.governance-layer-table.api-table.trial-banner-visible {
  height: calc(100vh - 535px);
}
.table.governance-layer-table.webhooks-table {
  height: calc(100vh - 70px);
  overflow-x: hidden;
  overflow-y: auto;
}
.table.governance-layer-table.webhooks-table.trial-banner-visible {
  height: calc(100vh - 537px);
  padding-bottom: 20px !important;
}
.table.governance-layer-table.wallets-address-table.profile {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}
.table.governance-layer-table.wallets-address-table.profile.trial-banner-visible {
  height: calc(100vh - 325px);
}
.table.governance-layer-table.wallets-table.trial-banner-visible {
  padding-bottom: 465px;
}
.menu .social-media {
  display: flex;
  padding: 0;
  padding-left: 20px;
  height: 100vh;
  align-items: flex-end;
  gap: 10px;
}
.menu .social-media div {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.menu .social-media .x-media {
  background-image: url('../../assets/icons/social/x.svg');
}
.menu .social-media .x-media:hover {
  background-image: url('../../assets/icons/social/x-hover.svg');
}
.menu .social-media .linkedin {
  background-image: url('../../assets/icons/social/linkedin.svg');
}
.menu .social-media .linkedin:hover {
  background-image: url('../../assets/icons/social/linkedin-h.svg');
}
.menu .social-media .github {
  background-image: url('../../assets/icons/social/github.svg');
}
.menu .social-media .github:hover {
  background-image: url('../../assets/icons/social/github-h.svg');
}
.vaultody-copy {
  color: #666d7f;
  font-family: Gilroy-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px;
  padding-left: 20px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 16px;
}
.menu .icon {
  display: flex;
  align-items: center;
}
.menu .icon.dapps path {
  stroke: black;
  stroke-width: 2px;
}
.menu ul li.active .icon.dapps path,
.menu ul li:hover .icon.dapps path {
  stroke: #2ce28b;
}
.menu ul {
  margin: 0;
  padding: 0;
  padding: 0 20px;
}
.menu ul a {
  color: inherit;
  text-decoration: none;
  font-size: 14px;
  align-items: center;
  display: flex;
  font-family: 'Gilroy-Bold';
}
.menu ul a li {
  list-style: none;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  height: 42px;
  width: 210px;
  display: flex;
  align-items: center;
}
.menu ul li .icon::before {
  font-size: 18px;
}
.menu ul li .text {
  margin-left: 10px;
  margin-top: 0;
  font-family: 'Gilroy-Bold';
  font-size: 14px;
}
.menu ul div ~ div {
  margin-top: 10px;
  display: flex;
}
.menu ul li.active,
.menu ul li:hover {
  color: #fff;
  background: #23262e;
}
.menu ul li .icon path,
.menu ul li .icon path {
  fill: #000;
}
.menu ul li.active .icon path,
.menu ul li:hover .icon path {
  fill: #2ce28b;
}
.content-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content-page .title-box {
  min-height: 70px;
  position: relative;
  color: #020d1c;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  width: 100%;
  align-items: center;
}

.content-page .title-box:not(.edit-mode) .title {
  display: flex;
  width: 100%;
}
.content-page .title-box .title {
  font-size: 24px;
  font-family: 'Gilroy-Semibold';
  margin-left: 50px;
}

.content-page .content {
  margin-top: 40px;
  height: calc(100vh - 180px);
}
.main-dashboard {
  display: flex;
  height: calc(100vh - 70px);
}
.header .logo {
  text-align: center;
  min-width: 250px;
}
.header .header-menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 0px 15px 0px 10px;
}
.testmode {
  flex: none;
  display: flex;
}
.header .logout {
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;
  align-items: center;
}
.header .logout.help {
  margin-right: 15px;
}
.header .logout span {
  cursor: pointer;
}
.header .testdata {
  bottom: -25px;
  color: #fff;
  background-color: #ff3d1d;
  border-radius: 0px 0px 5px 5px;
  padding: 4px 10px;
  gap: 10px;
  font-family: 'Gilroy-SemiBold';
  font-size: 13px;
  position: absolute;
  min-width: 78px;
  height: 22px;
  left: calc(50% - 78px / 2);
  display: flex;
  top: 70px;
  align-items: center;
  justify-content: center;
}
.header .developers {
  display: flex;
  color: #23262e;
  font-family: 'Gilroy-SemiBold';
  align-items: center;
  padding: 5px 13px;
  cursor: pointer;
}
.header .developers.active,
.header .developers:hover {
  background: #23262e;
  color: #fff;
  border-radius: 10px;
}
.header .developers.active i,
.header .developers:hover i {
  color: #2ce28b;
}
.header .developers i {
  font-size: 1.3em;
  margin-top: 2px;
}
.header .developers span {
  margin-left: 5px;
  font-size: 14px;
}
.clear-link {
  text-decoration: none;
}

.account-limit {
  display: flex;
  align-items: center;
  min-width: 100px;
  margin-right: 50px;
  gap: 10px;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  color: #666d7f;
  flex: none;
  overflow: hidden;
}

.account-limit .limit {
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  color: #020d1c;
}

.circle {
  width: 30px;
  height: 30px;
}

.action-modal .v-modal-content {
  max-width: 530px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.action-modal-button {
  width: 100%;
  margin-top: 30px;
}
.exp-modal .v-modal-content {
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.exp-modal ~ .header,
.exp-modal ~ .header ~ .main-dashboard {
  filter: blur(4px);
}
.exp-modal .v-modal-content {
  width: 560px;
  display: flex;
  align-items: center;
}
.exp-modal .v-modal-title {
  margin-top: 30px;
  font-size: 24px;
  font-family: 'Gilroy-Bold';
}
.exp-modal .v-modal-subtitle {
  font-family: 'Gilroy-Medium';
  color: #666d7f;
  margin-top: 5px;
  font-size: 18px;
}
.exp-modal .icon-close {
  display: none;
}
.exp-modal button {
  width: 100%;
  margin-top: 30px;
}
.exp-modal .add-button {
  width: 100%;
  margin-top: 30px;
}
.trial-banner-wrapper {
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0 50px;
  width: 100%;
}
.action-modal-heading {
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}
.action-modal-icon-title {
  margin-bottom: 30px;
}
.action-modal-content {
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #666d7f;
}
.maintanance-bar-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  padding: 7px 20px 7px 0;
  background: var(--project-green);
  border-radius: 5px;
  height: 35px;
  border: 1px solid #e6e7e8;
  color: #23262e;
  font-family: 'Gilroy-Bold';
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;
  margin: 0 10px;
  gap: 10px;
}
.maintanance-bar {
  background: var(--project-green);
  height: 100%;
  color: #23262e;
  font-family: 'Gilroy-Bold';
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  flex-basis: 93%;
  max-width: 93%;
}
.maintanance-bar span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
  text-transform: uppercase;
}
.maintanance-bar-wrapper .icon-close {
  font-size: 25px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.maintanance-bar-wrapper.hide {
  opacity: 0;
  pointer-events: none;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-menu-item-tooltip {
  display: none !important;
}
.header .logo-small {
  display: none;
}
@media screen and (max-width: 1460px) {
  .header .developers {
    padding: 5px 13px;
  }
  .header .header-menu {
    padding: 0px 15px 0px 0px;
  }
  .menu {
    min-width: 65px;
    max-width: 65px;
    width: 65px;
  }
  .menu ul {
    padding: 0;
  }
  .menu ul li,
  .menu ul div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu ul a li {
    width: 50px;
  }
  .menu ul li .text {
    display: none;
  }
  .menu .vaultody-copy {
    display: none;
  }
  .menu .social-media {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0 25px 0;
  }
  .dashboard-menu-item-tooltip {
    display: block !important;
  }
  .header .logo {
    display: none;
  }
  .header .logo-small {
    display: block;
    min-width: 95px;
    margin-left: 5px;
  }
  .header .logo-small img {
    height: 60px;
    cursor: pointer;
  }
  .menu ul li .icon {
    width: 18.5px;
    height: 18.5px;
  }
}
@media screen and (max-width: 950px) {
  .header {
    display: none;
  }
  .menu {
    display: none;
  }
  .account-limit {
    margin-right: 20px;
    justify-content: flex-end;
  }
  .account-limit .circle {
    width: 25px;
    height: 25px;
  }
  .exp-modal .v-modal-content {
    width: 100vw;
    padding: 20px;
  }
  .trial-banner-wrapper {
    padding: 0px 20px;
    margin-bottom: 0;
  }
  .table.governance-layer-table.api-table {
    height: calc(100vh - 440px) !important;
    height: calc(100dvh - 440px) !important;
  }
  .table.governance-layer-table.api-table.trial-banner-visible {
    height: calc(100vh - 645px) !important;
    height: calc(100dvh - 645px) !important;
  }
  .maintanance-bar-wrapper {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
