.v-dropdown-menu {
  display: flex;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #e6e7e8;
  padding: 10px;
}
.help .v-dropdown-data:hover {
  background: #ffffff;
}
.v-dropdown-data {
  max-width: 192px;
  gap: 25px;
}
.v-dropdown-data.profile {
  top: 43px;
  gap: 0px;
}
.v-dropdown-main {
  font-size: 13px;
  display: flex;
  align-items: center;
  font-family: 'Gilroy-SemiBold';
  gap: 10px;
}
.end-menus {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.v-dropdown-main .main-icon {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23262e;
}

.v-dropdown-main i.icon-down-open-big::before,
.icon-logout::before {
  color: #666d7f;
  font-size: 14px;
}
i.icon-down-open-big {
  transition: 0.15s ease-in;
}
i.icon-down-open-big.rotated {
  transform: rotate(180deg);
  margin-bottom: 8px;
}
.icon-logout::before {
  padding-left: 3px;
}

.v-dropdown-main i::before {
  font-size: 20px;
  color: #23262e;
  margin-top: -3px;
}

.v-dropdown-data {
  position: absolute;
  min-width: 200px;
  top: 40px;
  z-index: 9999;
  right: 0;
  height: max-content;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.v-dropdown-data.profile {
  padding: 0px;
}
.v-dropdown-data a {
  text-decoration: none;
}
.v-dropdown-data.help > *:not(:last-child) {
  margin-bottom: 30px;
}
.help .drop-down-block .title {
  margin: 5px 0;
  color: #666d7f;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  line-height: 100%;
}
.title {
  display: flex;
  flex-wrap: nowrap;
}
.menu-icon {
  margin-right: 4px;
}
.help .v-dropdown-data .data-dropdown {
  font-family: 'Gilroy-Medium';
}
.help-menu-link {
  text-decoration: none;
  color: #0e1f36;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
  line-height: 100%;
}
.data-dropdown i {
  font-size: 20px;
  min-width: 20px;
}
.data-dropdown {
  display: flex;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.help .v-dropdown-data .data-dropdown {
  margin-top: 10px;
}
.help .v-dropdown-data .data-dropdown.first {
  margin-top: 15px !important;
}
.help-menu-link:hover {
  text-decoration: underline;
}
.help .v-dropdown-data {
  bottom: -318px;
  padding: 20px 20px;
}
.v-dropdown-data .data-dropdown {
  display: flex;
  gap: 10px;
  padding: 10px 0px;
  align-items: center;
}
.data-dropdown.profile {
  justify-content: flex-start;
  gap: 5px;
  padding: 15px;
}
.data-dropdown.help {
  gap: 0px;
}
.help .v-dropdown-data .data-dropdown {
  padding: 0;
}

.help .v-dropdown-data .data-dropdown:hover {
  background-color: #fff;
}
.v-dropdown-data .data-dropdown:hover {
  background: #f0f0f0;
}
.dropdown-carousel-icon {
  transform: rotate3d(0, 0, 1, 180deg);
  transition: 0.5s all;
}
.dropdown-carousel-icon.open {
  transform: rotate3d(0, 0, 1, 0deg);
}
.user-dropdown-name {
  text-wrap: nowrap;
}
