.assets-table {
  width: 100%;
  height: calc(100vh - 245px);
  overflow-x: hidden;
  overflow-y: auto;
}
.assets-table .asset-table-wrapper {
  display: flex;
  padding: 0 50px 0 50px;
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 2;
}
.assets-table .settings {
  flex-basis: 19.7%;
  display: flex;
  justify-content: flex-end;
}
.assets-table .asset-table-wrapper .balance {
  flex-basis: 14.7%;
}
.assets-table .asset-table-wrapper .first-column {
  flex-basis: 21.5%;
}
.assets-table-no-assets {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
}
.assets-table .accordion-component,
.assets-table .accordion-component .accordion-title-wrapper,
.assets-table .accordion-component .accordion-details-wrapper {
  height: unset;
}
