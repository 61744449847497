.error-page-table-wrapper {
  height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-bottom: 200px;
}

.error-page-table-wrapper.trial-banner-visible {
  height: calc(100vh - 365px);
}

.error-page-label {
  text-align: center;
  font-family: 'Gilroy-Bold';
  font-size: 64px;
  font-weight: 400;
  line-height: 64px;
  margin-bottom: 80px;
}

.error-page-label-sub {
  text-align: center;
  color: #020d1c;
  font-size: 32px;
  font-family: 'Gilroy-SemiBold';
}
@media screen and (max-width: 950px) {
  .error-page-label {
    font-size: 38px;
  }
  .error-page-table-wrapper div {
    width: calc(100vw - 40px) !important;
    overflow: unset !important;
    height: unset !important;
  }
  .error-page-table-wrapper {
    height: calc(100vh - 170px);
    height: calc(100dvh - 170px);
    padding-bottom: 0 !important;
    justify-content: flex-start;
  }
  .error-page-table-wrapper.trial-banner-visible {
    height: calc(100vh - 355px);
    height: calc(100dvh - 355px);
  }
}