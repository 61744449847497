.copy-button {
  padding: 0px 10px;
  gap: 10px;
  width: 30px;
  position: relative;
  height: 30px;
  background: rgba(2, 13, 28, 0.05);
  border-radius: 34px;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copy-button:hover {
  background: rgba(2, 13, 28, 0.1);
}
.tooltip.copy-tooltip {
  display: block;
  position: absolute;
  top: -30px;
  color: #fff;
  background: #050d1c;
  opacity: 1;
  font-size: 11px;
  border-radius: 10px;
  z-index: 999999999999;
}
.tooltip.copy-tooltip.hide {
  display: none;
}
