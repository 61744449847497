.action-menu.active {
  display: flex;
}
.action-menu {
  position: absolute;
  top: 50px;
  display: none;
  flex-direction: column;
  background: #ffffff;
  right: 20px;
  z-index: 9;
  width: 168px;
  justify-content: center;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  color: #020d1c;
  user-select: none;
  text-wrap: nowrap;
}
.action-menu .action-menu-item i {
  margin-right: 10px;
}
.action-menu .action-menu-item i::before {
  font-size: 18px;
}
.action-menu .action-menu-item.remove {
  padding-left: 12px;
}
.action-menu .action-menu-item.remove i::before {
  font-size: 22px;
  margin: 0;
}
.action-menu .action-menu-item.inactive {
  color: rgba(35, 38, 46, 0.2);
  pointer-events: none;
}
.action-menu .action-menu-item {
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
}

.action-menu .action-menu-item:hover {
  background-color: rgba(35, 38, 46, 0.05);
}
.actions {
  width: 38px;
  height: 38px;
  background: rgba(2, 13, 28, 0.05);
  display: flex;
  font-size: 14px;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  font-size: 18px;
  justify-content: center;
}
.actions:hover {
  background: rgba(35, 38, 46, 0.1);
}
