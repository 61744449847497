.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.drawer-overlay-open {
  opacity: 1;
  z-index: 99999;
  visibility: visible;
}

.drawer-overlay-hidden {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.drawer {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  z-index: 99999;
  background-color: #ffffff;
  width: calc(100vw - 100vw / 3);
  flex-shrink: 0;
  transition:
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.drawer-hidden {
  visibility: hidden;
  width: calc(100vw - 100vw / 3);
  transform: translateX(-240px);
  flex-shrink: 0;
  transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.drawer-animate {
  visibility: visible;
  transform: none;
  box-shadow:
    0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.drawer-open {
}

.drawer.left {
  left: 0;
  right: auto;
}

.drawer.right {
  right: 0;
  left: auto;
}
.drawer.bottom.drawer-hidden {
  visibility: hidden;
  height: calc(100vh - 100vh / 2);
  transform: translateY(-240px);
  flex-shrink: 0;
  transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.drawer.bottom {
  bottom: 0 !important;
  left: 0;
  flex: 1 0 auto;
  height: calc(100vh - 100vh / 2);
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  z-index: 99999;
  background-color: #ffffff;
  width: 100%;
  flex-shrink: 0;
  transition:
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
@media screen and (max-width: 950px) {
  .drawer.bottom.drawer-hidden {
    height: calc(100dvh - 100vh / 2);
    height: calc(100dvh - 100dvh / 2);
  }
  .drawer.bottom {
    height: calc(100dvh - 100vh / 2);
    height: calc(100dvh - 100dvh / 2);
  }
}
