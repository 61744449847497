.contacts-page {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.contacts-page-bar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  padding: 0 50px;
}
.contacts-page-search-wrapper {
  display: flex;
  flex-basis: 40%;
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}
.contacts-page-blockchain-filter {
  display: flex;
  flex-basis: 30%;
  width: 100%;
}
.contacts-page-button-holder {
  display: flex;
  flex-basis: 25%;
  justify-content: flex-end;
}
.contacts-table-wrapper {
  height: calc(100vh - 245px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.contacts-table-wrapper.trial-banner-visible {
  height: calc(100vh - 430px);
}
.contacts-table {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow: auto;
}
.contact-row-address-name-header {
  flex-basis: 20%;
}
.contact-row-address-header {
  flex-basis: 40%;
  color: #555d67;
}
.contact-row-status-header {
  flex-basis: 15%;
}
.contact-row-blockchain-header {
  flex-basis: 20%;
}
.contact-row-settings-header {
  flex-basis: 5%;
  display: flex;
  justify-content: flex-end;
}
.contact-row-address-name-header,
.contact-row-address-header,
.contact-row-status-header,
.contact-row-blockchain-header {
  color: #555d67;
}
.contact-page-header {
  display: flex;
  padding: 25px 50px;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 2;
}
.contacts-page-no-contacts {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
}
.contacts-page-contact-row-wrapper {
  width: 100%;
  padding: 0px 50px;
}
.contacts-page-contacts-icon {
  margin-right: 5px;
}
.contacts-page-contacts-icon path {
  fill: #000;
}
.contacts-page-contacts-icon.inactive path {
  fill: #fff;
}
.contacts-alert-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 30px 0 30px 0;
  gap: 15px;
}
.loading-full-height-contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 72px);
}
@media screen and (max-width: 950px) {
  .contacts-page-bar {
    width: 100%;
    padding: 0 20px;
  }
  .contacts-page-bar > * {
    width: 100%;
    flex-basis: 100%;
  }
  .contacts-page-bar .filter-main-wrapper-new {
    max-width: unset;
    min-width: 250px;
    width: 100%;
  }
  .contacts-page-bar .filter-main-wrapper-new .checkboxes-new {
    max-width: unset;
    width: 100%;
  }
  .contacts-page-button-holder > * {
    width: 100%;
  }
  .contacts-page-button-holder {
    order: 2;
  }
  .contacts-page-blockchain-filter {
    order: 3;
  }
  .contact-page-header {
    display: none;
  }
  .contacts-page-contact-row-wrapper {
    padding: 0 20px;
  }
  .contacts-table-wrapper {
    height: calc(100vh - 370px);
    height: calc(100dvh - 370px);
  }
  .contacts-table-wrapper.trial-banner-visible {
    height: calc(100vh - 528px);
    height: calc(100dvh - 528px);
  }
  .contacts-table {
    padding-bottom: 0px;
  }
  .contacts-page-search-wrapper {
    margin-bottom: 0;
  }
  .contact-row-address span {
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
  }
  .contact-row-blockchain div,
  .contact-row-address div {
    justify-content: flex-end;
  }
  .loading-full-height-contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
