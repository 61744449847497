.info-container {
  display: flex;
  flex-direction: column;
}
.info-container .title {
  display: flex;
  color: #666d7f;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  min-width: 150px;
  display: flex;
  align-items: center;
}
.info-container .title .question {
  position: relative;
  cursor: pointer;
}
.info-container {
  width: 100%;
}
.info-container .title .question .icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #23262e0a;
  border-radius: 18px;
  font-family: 'Gilroy-Semibold';
  font-size: 14px;
  margin-left: 8px;
  color: #23262e;
}
.info-container .count {
  font-size: 18px;
  font-family: 'Gillroy-Bold';
  font-weight: 400;
  color: #020d1c;
  line-height: 24px;
  margin-top: 8px;
}
.info-container .count-bold {
  font-size: 18px;
  font-family: 'Gillroy-Bold';
  font-weight: 600;
  color: #020d1c;
  line-height: 24px;
  margin-top: 8px;
}
.info-container-question-mark {
  font-size: 10px;
}
