.automation-table {
  width: 100%;
  height: calc(100vh - 325px);
  overflow-x: hidden;
  overflow-y: auto;
}
.automation-table.trial-banner-visible {
  width: 100%;
  height: calc(100vh - 510px);
}
.automation-table .automation-table-wrapper {
  display: flex;
  padding: 0 50px 0 50px;
  color: #666d7f;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  font-family: 'Gilroy-SemiBold';
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 2;
}
.automation-table .name {
  flex-basis: 23.5%;
}
.automation-table .asset {
  flex-basis: 17%;
  /* 45% */
}
.automation-table .fee-priority {
  text-transform: capitalize;
  flex-basis: 20%;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
}
.automation-table .status {
  flex-basis: 17.5%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.automation-table .settings {
  flex-basis: 5%;
  display: flex;
  justify-content: flex-end;
}
.automation-table-no-assets {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
}
.automation-rules-header-table {
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin: 30px 0;
}
@media screen and (max-width: 950px) {
  .automation-table {
    height: calc(100vh - 380px);
    height: calc(100dvh - 380px);
  }
  .automation-table.trial-banner-visible {
    height: calc(100vh - 533px);
    height: calc(100dvh - 533px);
  }
}
