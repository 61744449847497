.select-custom-new {
  height: 60px;
  border: 1px solid #b1b4b9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
  padding: 0px 45px 0 15px;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
}
.select-custom-new.disabled {
  height: 60px;
  border: 1px solid #d3d3d3;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
  padding: 0px 45px 0 15px;
  cursor: default;
  overflow: hidden;
  user-select: none;
}
.filter-main-wrapper-new {
  position: relative;
  width: 260px;
  min-width: 250px;
}
.filter-main-wrapper-new.fullWidth {
  max-width: unset;
  min-width: 250px;
  width: 100%;
}
.checkboxes-new {
  border: 1px solid #b1b4b9;
  border-radius: 10px;
  border-top: none;
  width: 100%;
  max-width: 260px;
  position: absolute;
  /* top: 53px; */
  border-radius: 10px;
  background-color: #fafafa;
}
.checkboxes-new.fullWidth {
  max-width: unset;
}

.select-custom-new.open {
  border: none;
  border-radius: 0;
  z-index: 1000;
  box-shadow: 0 6.5px 10px 0px rgba(0, 0, 0, 0.05);
}
.select-custom-new.open::after {
  transform: rotate3d(0, 0, 1, 180deg);
}
.select-custom-new ~ .checkboxes-new {
  display: none;
}
.select-custom-new.open ~ .checkboxes-new {
  border-radius: 0px 0px 10px 10px;
  display: flex;
  z-index: 999;
  flex-direction: column;
  max-height: 255px;
  overflow-x: hidden;
}

/* Check box */
.wallet-icon-new {
  color: #fff;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: 'Gilroy-Bold';
  border-radius: 5px;
  margin-right: 10px;
  user-select: none;
  text-transform: capitalize;
  position: relative;
}
.wallet-icon-new.round {
  border-radius: 50%;
}
.value-checkbox-new {
  display: flex;
  align-items: center;
  font-family: 'Gilroy-Medium';
  flex-basis: 84%;
  max-width: 84%;
}
.value-checkbox-new.only-label {
  display: flex;
  align-items: center;
  font-family: 'Gilroy-Medium';
  flex-basis: 100%;
  max-width: 100%;
}
.value-checkbox-new.reversed {
  order: 2;
  margin-left: 10px;
}
.checkbox-checkmarm-wrapper.reversed {
  flex-basis: unset !important;
  max-width: unset !important;
}
/* .checkbox-group-new:first-child {
  margin-top: 7px;
} */
.checkbox-group-new,
.checkbox-group-new label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkbox-group-new {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}
.checkbox-group-new:hover {
  background-color: rgba(35, 38, 46, 0.05);
  z-index: 1 !important;
}
.checkbox-group-new.disabledHover:hover {
  background-color: transparent;
}

/* Hide the browser's default checkbox */
.checkbox-group-new input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-new {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border: 1px solid #23262e;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.checkbox-checkmarm-wrapper {
  display: flex;
  flex-basis: 16%;
  max-width: 16%;
  justify-content: flex-end;
}

.checkmark-new.disabled {
  border-color: rgba(35, 38, 46, 0.4);
  opacity: 0.3;
  pointer-events: none;
}

/* When the checkbox is checked, add a blue background */
.checkbox-group-new.checked .checkmark-new {
  background-color: #555d67;
}

.checkbox-checkmark-icon {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.checkbox-label-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 15px;
  width: 100%;
}

.checkbox-label-text.disabled {
  color: rgba(35, 38, 46, 0.2) !important;
}

.filter-selected-item {
  color: #b1b4b9;
  display: inline;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

.filter-carousel-icon {
  transform: rotate3d(0, 0, 1, 180deg);
  position: absolute;
  right: 20px;
  transition: 0.5s all;
}

.filter-carousel-icon.open {
  transform: rotate3d(0, 0, 1, 0deg);
}

.text-field-wrapper {
  width: 100%;
}

.select-custom-new-border-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #b1b4b9;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
}

.indicator {
  color: #b1b4b9;
  display: inline;
  min-width: 24px;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  padding-left: 3px;
}
.vault-modal-alert-consent-button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.wallet-icon-new .react-tooltip {
  font-size: 12px;
}
