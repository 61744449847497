.assets-page-table-wrapper {
  height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.assets-page-table-wrapper.trial-banner-visible {
  height: calc(100vh - 365px);
}
.asset-page-assets-listing-wrapper {
  display: flex;
  flex-direction: column;
}
.asset-page-header {
  display: flex;
  padding: 25px 71px;
  border-bottom: 1px solid #f3f3f3;
}
.asset-page-balance {
  display: flex;
  padding: 0 50px;
  margin-bottom: 10px;
}
.asset-page-header .first-column {
  flex-basis: 21%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  padding-right: 5px;
  white-space: nowrap;
  font-family: 'Gilroy-Semibold';
}
.asset-page-header .balance {
  flex-basis: 16%;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  flex-direction: column;
  align-items: flex-start;
}
.loading-full-height-assets {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 343px);
}
.assets-balance-skeleton-wrapper {
  height: 50px;
  width: 100%;
}
.assets-page-table-wrapper .accordion-component,
.assets-page-table-wrapper .accordion-component .accordion-title-wrapper,
.assets-page-table-wrapper .accordion-component .accordion-details-wrapper {
  height: unset;
}
@media screen and (max-width: 950px) {
  .asset-page-header {
    display: none;
  }
  .asset-page-balance {
    display: flex;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  .assets-page-table-wrapper {
    height: calc(100vh - 180px);
    height: calc(100dvh - 180px);
  }
  .assets-page-table-wrapper.trial-banner-visible {
    height: calc(100vh - 330px);
    height: calc(100dvh - 330px);
  }
  .loading-full-height-assets {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 323px);
  }
}
