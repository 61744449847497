.alert-component {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 15px 20px;
  border-radius: 10px;
}
.alert-text {
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  word-break: break-all;
}
.alert-component-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 28px;
  min-height: 28px;
}
.alert-component.warning {
  border: 1.5px solid #f19100;
  background: #f191001b;
}
.alert-component.info {
  border: 1.5px solid #f19100;
  background: rgba(41, 181, 246, 0.08);
}
.alert-component.error {
  border: 1.5px solid rgb(244, 67, 54);
  background: rgba(244, 67, 54, 0.09);
}
.alert-component.info {
  border: 1.5px solid rgb(41, 182, 246);
}
.alert-component.error {
  border: 1.5px solid rgb(244, 67, 54);
}
.alert-component.warning .alert-component-icon-wrapper {
  border: 1.5px solid #f19100;
}
.alert-component.warning .alert-component-icon-wrapper path {
  fill: #f19100;
}
.alert-component.info .alert-component-icon-wrapper {
  border: 1.5px solid rgb(41, 182, 246);
}
.alert-component.info .alert-component-icon-wrapper path {
  fill: rgb(41, 182, 246);
}
.alert-component.error .alert-component-icon-wrapper {
  border: 1.5px solid rgb(244, 67, 54);
}
.alert-component.error .alert-component-icon-wrapper path {
  fill: rgb(244, 67, 54);
}
