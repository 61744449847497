.addresses-table-data:not(:first-child) {
  padding-left: 10px;
}
.addresses-table-data.name-link {
  flex-basis: 15%;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  word-break: break-all;
  max-width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}
.addresses-table-data.blockchain-link {
  flex-basis: 15%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Gilroy-Semibold';
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.trx-address-link {
  flex-basis: 15%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.created-at-link {
  flex-basis: 16%;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.link-status-link {
  flex-basis: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.settings-link {
  flex-basis: 24%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.link-status {
  flex-basis: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.name {
  flex-basis: 25%;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  word-break: break-all;
  max-width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.addresses-table-data.address-name-edit {
  overflow: visible;
}
.addresses-table-data.address-name-edit .react-tooltip {
  width: unset !important;
  z-index: 9 !important;
}
.addresses-table-data.blockchain {
  flex-basis: 20%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Gilroy-Semibold';
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.trx-address {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.created-at {
  flex-basis: 20%;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data.settings {
  flex-basis: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  font-family: 'Gilroy-Semibold';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 1;
  font-family: 'Gilroy-Semibold';
  font-size: 14px !important;
  font-weight: 400;
  line-height: 15px;
}
.addresses-table-data-edit-icon {
  background: #e6e7e8;
  padding: 7px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.addresses-table-data-edit-icon.disabled {
  background: #ededed;
}
.addresses-table-data-check-icon {
  background: #2ce28b;
  padding: 7px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  cursor: pointer;
}
.addresses-table-data-check-icon.disabled {
  background: #cccccc;
  pointer-events: none;
}
.addresses-table-data-check-icon path {
  stroke: #000;
}
.addresses-table-data-close-icon {
  background: #e6e7e8;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.wallets-address-text-addresses {
  width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.address-title-table-data-flex,
.address-title-table-data-flex-gap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 10px;
}
.address-title-table-data-flex-gap {
  gap: 10px;
}
@media screen and (max-width: 1450px) {
  .addresses-table-data.settings-link {
    flex-wrap: wrap;
  }
  .addresses-table-data.settings-link .button-component-main {
    width: 100%;
  }
}
@media screen and (max-width: 1350px) {
  .addresses-table-data.name {
    flex-basis: 22%;
    max-width: 22%;
  }
  .gas-tanker-addresses-table-data.name {
    flex-basis: 22%;
    max-width: 22%;
  }
  .addresses-table-data.name span {
    max-width: 120px;
  }
  .addresses-table-data.name-link span {
    max-width: 120px;
  }
  .addresses-table-data.settings {
    flex-basis: 23%;
    max-width: 23%;
  }
  .addresses-table-data.blockchain-link {
    flex-basis: 15%;
    max-width: 15%;
  }
  .addresses-table-data.name-link {
    flex-basis: 19%;
    max-width: 19%;
  }
  .addresses-table-data.created-at {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-basis: 15%;
    max-width: 15%;
  }
  .addresses-table-data.created-at-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (max-width: 950px) {
    .address-accordion-main-wrapper {
      flex-direction: column;
    }
    .address-accordion-main-wrapper > * {
      width: 100% !important;
      flex-basis: 100% !important;
      max-width: 100% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      flex-wrap: nowrap;
      flex-direction: row;
      padding: 10px 0 !important;
    }
    .addresses-table-data.name span {
      max-width: 150px !important;
    }
    .addresses-table-data.settings button {
      width: 100%;
    }
    .accordion-title-wrapper {
      border-bottom: 1px solid #d5d5d5;
    }
  }
}
