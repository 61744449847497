@font-face {
  font-family: 'Gilroy';
  src:
    url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Medium.woff') format('woff'),
    url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy-Medium';
  src:
    url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Medium.woff') format('woff'),
    url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy-Semibold';
  src: url('./assets/fonts/Gilroy-Semibold.woff') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Gilroy-Bold.woff') format('woff');
}
@font-face {
  font-family: 'fontello';
  src: url('./assets/fonts/fontello.ttf?26410430') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg?26410430#fontello') format('svg');
    }
  }
  */
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-active-members:before {
  content: '\e800';
} /* 'î €' */
.icon-add:before {
  content: '\e801';
} /* 'î ' */
.icon-add-contact:before {
  content: '\e802';
} /* 'î ‚' */
.icon-add-a-new-wallet:before {
  content: '\e803';
} /* 'î ƒ' */
.icon-adjust:before {
  content: '\e804';
} /* 'î „' */
.icon-apis:before {
  content: '\e805';
} /* 'î …' */
.icon-additional-information:before {
  content: '\e806';
} /* 'î †' */
.icon-arrow-down:before {
  content: '\e807';
} /* 'î ‡' */
.icon-arrow-right:before {
  content: '\e808';
} /* 'î ˆ' */
.icon-assets:before {
  content: '\e809';
} /* 'î ‰' */
.icon-billing-history:before {
  content: '\e80a';
} /* 'î Š' */
.icon-billing-info:before {
  content: '\e80b';
} /* 'î ‹' */
.icon-calendar:before {
  content: '\e80c';
} /* 'î Œ' */
.icon-cancel-invite:before {
  content: '\e80d';
} /* 'î ' */
.icon-change-email:before {
  content: '\e80e';
} /* 'î Ž' */
.icon-change-password:before {
  content: '\e80f';
} /* 'î ' */
.icon-check:before {
  content: '\e810';
} /* 'î ' */
.icon-close:before {
  content: '\e811';
} /* 'î ‘' */
.icon-company-info:before {
  content: '\e812';
} /* 'î ’' */
.icon-cog:before {
  content: '\e813';
} /* 'î “' */
.icon-contacts:before {
  content: '\e814';
} /* 'î ”' */
.icon-contact:before {
  content: '\e815';
} /* 'î •' */
.icon-copy:before {
  content: '\e816';
} /* 'î –' */
.icon-dapps:before {
  content: '\e817';
} /* 'î —' */
.icon-developers:before {
  content: '\e818';
} /* 'î ˜' */
.icon-download:before {
  content: '\e819';
} /* 'î ™' */
.icon-edit:before {
  content: '\e81a';
} /* 'î š' */
.icon-freeze:before {
  content: '\e81b';
} /* 'î ›' */
.icon-global:before {
  content: '\e81c';
} /* 'î œ' */
.icon-governance-layer:before {
  content: '\e81d';
} /* 'î ' */
.icon-hamburger:before {
  content: '\e81e';
} /* 'î ž' */
.icon-key:before {
  content: '\e81f';
} /* 'î Ÿ' */
.icon-log:before {
  content: '\e820';
} /* 'î  ' */
.icon-payment-methods:before {
  content: '\e821';
} /* 'î ¡' */
.icon-new-rule:before {
  content: '\e822';
} /* 'î ¢' */
.icon-pending:before {
  content: '\e823';
} /* 'î £' */
.icon-plan:before {
  content: '\e824';
} /* 'î ¤' */
.icon-remove:before {
  content: '\e825';
} /* 'î ¥' */
.icon-receive:before {
  content: '\e826';
} /* 'î ¦' */
.icon-resend-code:before {
  content: '\e827';
} /* 'î §' */
.icon-resend:before {
  content: '\e828';
} /* 'î ¨' */
.icon-search:before {
  content: '\e829';
} /* 'î ©' */
.icon-send:before {
  content: '\e82a';
} /* 'î ª' */
.icon-staking:before {
  content: '\e82b';
} /* 'î «' */
.icon-submit-for-approval:before {
  content: '\e82c';
} /* 'î ¬' */
.icon-swap:before {
  content: '\e82d';
} /* 'î ­' */
.icon-system-actions:before {
  content: '\e82e';
} /* 'î ®' */
.icon-transaction-history:before {
  content: '\e82f';
} /* 'î ¯' */
.icon-transaction:before {
  content: '\e830';
} /* 'î °' */
.icon-wallets:before {
  content: '\e831';
} /* 'î ±' */
.icon-working-hours:before {
  content: '\e832';
} /* 'î ²' */
.icon-down-open-big:before {
  content: '\e833';
} /* 'î ³' */
.icon-up-open-big:before {
  content: '\e834';
} /* 'î ´' */
.icon-dots:before {
  content: '\e835';
} /* 'î ´' */
.icon-info:before {
  content: '\e836';
} /* 'î ¶' */
.icon-edit:before {
  content: '\e839';
} /* '' */
.icon-broadcast:before {
  content: '\e83a';
} /* 'î º' */
.icon-logout:before {
  content: '\e838';
}
.icon-warning:before {
  content: '\e876';
} /* '' */
/* '' */

.table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.table-row {
  display: flex;
  width: 100%;
}
.data {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  width: 100%;
}
.data.level {
  justify-content: center;
}

b {
  font-family: 'Gilroy-Bold';
}

a {
  color: #23262e !important;
}

.table-row.data.inactive-row {
  color: rgba(35, 38, 46, 0.2);
}
.table-row.data.inactive-row button {
  color: rgba(35, 38, 46, 0.2);
}
.table-row.data.inactive-row:active {
  pointer-events: none;
}
.table-row.data.inactive-row .wallet-icon {
  background: rgba(35, 38, 46, 0.2) !important;
}

.table-row.data.inactive-row .cancel-button {
  background: rgba(35, 38, 46, 0.05);
  color: rgba(35, 38, 46, 0.2);
  pointer-events: none;
}
.table-row.data.inactive-row .cancel-button i {
  color: rgba(35, 38, 46, 0.2);
}
.checkboxes-new::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.checkboxes-new::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.checkboxes-new::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.checkboxes-new::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.action-dropdown-icon {
  width: 18px;
  height: 18px;
}
.action-menu-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.global-tooltip-style {
  border-radius: 50%;
}