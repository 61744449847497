.filter-checkbox-wrapper .checkbox-group-new {
  padding: 20px 20px;
}
.filter-checkbox-wrapper .radio-group-new {
  padding: 20px 20px;
}
.filter-no-option {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}
@media screen and (max-width: 950px) {
  .filter-main-wrapper-new .select-custom-new {
    height: 52px;
  }
}
