.profile-page-wrapper {
  height: calc(100vh - 180px) !important;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.profile-page-wrapper.trial-banner-visible {
  height: calc(100vh - 365px) !important;
}
.profile-page-wrapper .table-data .titles-box-title {
  font-size: 16px;
  font-family: 'Gilroy-SemiBold';
}
.profile-page-wrapper .table-data {
  flex-direction: row;
  align-items: center;
}
.profile-page-wrapper .titles-box .titles-box-sub {
  font-size: 18px;
  margin-top: 10px;
  font-family: 'Gilroy-Bold';
}
.change-password .v-modal-content {
  max-width: 500px;
}
.change-password .invalid-input {
  position: relative;
}
.change-password .invalid-feedback {
  position: absolute;
}
.v-modal.change-email .button-holder {
  margin-top: 30px;
}
.profile-page-wrapper .titles-box .titles-box-sub.device {
  display: flex;
  gap: 10px;
  align-items: center;
}
.profile-page-wrapper .titles-box .titles-box-sub.device .status {
  color: #3ebe01;
  display: flex;
  font-size: 16px;
}
.profile-page-wrapper .titles-box {
  width: 100%;
}
.profile-page-wrapper .titles-box .titles-box-sub.device .date {
  color: #666d7f;
  font-size: 12px;
  font-family: 'Gilroy-SemiBold';
}
.add-button.gray {
  background: rgba(2, 13, 28, 0.05);
  padding: 0;
  width: auto;
  height: 38px;
  padding: 12px 20px;
  font-size: 14px;
  font-family: 'Gilroy-SemiBold';
}
.my-profile-table-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.api-keys-table-data:not(:first-child) {
  padding-left: 10px;
}
.api-keys-table-data.name {
  max-width: 20%;
  flex-basis: 20%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.api-keys-table-data.key {
  flex-basis: 20%;
  max-width: 20%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.api-keys-table-data.wallets {
  flex-basis: 20%;
  display: flex;
  align-items: center;
}
.api-keys-table-data.created-at {
  flex-basis: 12.5%;
}
.api-keys-table-data.status {
  flex-basis: 10%;
}
.api-keys-table-data.settings {
  flex-basis: 5%;
}
.api-keys-table-data {
  width: unset !important;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.api-key-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 150px;
}
.status-icon {
  width: 28px;
  height: 28px;
}
.icon-key {
  display: flex;
}
.change-password-textfield-wrapper {
  margin-bottom: 1.5rem;
}
.remove-device-label-success {
  margin: 20px 0;
  font-size: 18px;
  text-align: center;
  color: #666d7f;
}
.remove-device-modal-buttons {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.status-remove-device {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media screen and (max-width: 1100px) {
  .api-key-text {
    width: 80px;
  }
}
@media screen and (max-width: 950px) {
  .table-row.data.profile {
    padding: 20px 20px;
  }
  .profile-page-wrapper {
    height: calc(100vh - 170px) !important;
    height: calc(100dvh - 170px) !important;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .profile-page-wrapper.trial-banner-visible {
    height: calc(100vh - 330px) !important;
    height: calc(100dvh - 330px) !important;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .my-profile-table-data {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .my-profile-table-data button,
  .my-profile-table-data .button-holder {
    width: 100%;
  }
  .my-profile-table-data .button-holder {
    display: flex;
    justify-content: flex-start;
  }
}
