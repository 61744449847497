.balances {
  display: flex;
  font-family: 'Gilroy-Semibold';
  gap: 50px;
  font-size: 14px;
  min-width: 730px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f3f3f3;
}
.balance {
  display: flex;
  flex-direction: column;
}
.balances .title {
  display: flex;
  color: #666d7f;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  min-width: 150px;
  display: flex;
  align-items: center;
}
.balance .title .question {
  position: relative;
  cursor: pointer;
}
.balances .balance {
  width: 100%;
}
.balance .title .question .icon:hover + .tooltip-dashboard {
  opacity: 1;
}
.tooltip-dashboard i {
  position: absolute;
  top: 100%;
  left: 52%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}
.tooltip-dashboard {
  min-width: 232px;
  font-weight: bold;
  max-width: 500px;
  top: -10px;
  display: flex;
  justify-content: center;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 6px 10px 4px;
  color: #ffffff;
  background-color: #020d1c;
  font-family: 'Gilroy-Semibold';
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  opacity: 1;
  box-sizing: border-box;
}
.tooltip-dashboard i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #020d1c;
}
.balance .title .question .icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #23262e0a;
  border-radius: 18px;
  margin-left: 8px;
  color: #23262e;
}
.balance .balance-question-mark {
  font-size: 10px;
}
.balances .balance .count {
  font-size: 18px;
  font-family: 'Gilroy-SemiBold';
  font-weight: 400;
  color: #020d1c;
  line-height: 24px;
  margin-top: 8px;
}
.balances .balance .count-bold {
  font-size: 22px;
  font-family: 'Gilroy-SemiBold';
  font-weight: 600;
  color: #020d1c;
  line-height: 24px;
  margin-top: 8px;
}
.balances .balance .count-bold {
  font-size: 22px;
  font-family: 'Gillroy-SemiBold';
  font-weight: 600;
  color: #020d1c;
  line-height: 24px;
  margin-top: 8px;
}
@media screen and (max-width: 950px) {
  .balances {
    flex-wrap: wrap;
    gap: 10px;
    min-width: unset;
    max-width: 100vw;
    overflow: hidden;
    justify-content: space-between;
  }
  .balance {
    max-width: 50%;
    /* flex-basis: 50%; */
    width: unset !important;
  }
}