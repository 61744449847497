.maintanance-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #262727;
}
.maintanance-page-container {
  padding: 50px;
  height: 600px;
  width: 1160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 200px 0px #33bf644d;
}
.maintanance-page-info {
  max-width: 430px;
}
.maintanance-page-image {
  position: absolute;
  bottom: -2px;
  right: 3%;
  width: 660px;
  height: 380px;
  z-index: 2;
}
.maintanance-page-image-secondary {
  width: 585px;
  height: 560px;
  position: absolute;
  bottom: 0;
  right: 8.7%;
  z-index: 1;
}
.maintanance-page-header {
  margin-top: 100px;
  font-family: 'Gilroy-Bold';
  font-size: 45px;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: left;
}
.maintanance-page-subtext1 {
  margin-top: 50px;
  font-family: 'Gilroy-Bold';
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}
.maintanance-page-subtext2 {
  margin-top: 20px;
  font-family: 'Gilroy-SemiBold';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
.maintanance-page-container .social-media {
  display: flex;
  padding: 0;
  gap: 10px;
}
.maintanance-page-container .social-media div {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.maintanance-page-container .social-media .x-media {
  background-image: url('../../assets/icons/social/x.svg');
}
.maintanance-page-container .social-media .x-media:hover {
  background-image: url('../../assets/icons/social/x-hover.svg');
}
.maintanance-page-container .social-media .linkedin {
  background-image: url('../../assets/icons/social/linkedin.svg');
}
.maintanance-page-container .social-media .linkedin:hover {
  background-image: url('../../assets/icons/social/linkedin-h.svg');
}
.maintanance-page-container .social-media .github {
  background-image: url('../../assets/icons/social/github.svg');
}
.maintanance-page-container .social-media .github:hover {
  background-image: url('../../assets/icons/social/github-h.svg');
}
.maintanance-page-container .vaultody-copy {
  padding: 0;
}
