.blockchain-symbol-wrapper {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}

.blockchain-secondary-symbol-icon {
  position: absolute;
  top: 0;
  border-radius: 50%;
  transform: translate(20%, -20%);
  border: 1px solid #000;
  right: 0;
  width: 14px;
  height: 14px;
  background: #e7e7e7;
}
.blockchain-image {
  width: 28px;
  height: 28px;
}
.blockchain-image-wrapper {
  position: relative;
}